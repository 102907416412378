type IOptions = {
    wrapperId: string;
    animationDuration: number;
    timeout: number;
    zIndex: number;
    top: number;
};

const options: IOptions = {
    wrapperId: 'notification-wrapper',
    animationDuration: 300,
    timeout: 5000,
    zIndex: 2000,
    top: 0,
};

export default options;
