import { parseLocationQuery } from 'core/utils';
import { IFilters } from 'ListType';
import { useLocation } from 'react-router';

/**
 * Получить фильтры из урла
 * @example '/courses?order=DATE&productId=123' => { order: 'DATE', productId: '123' }
 * @example const { order, productId } = useFiltersFromUrl();
 */
export function useFiltersFromUrl<T = IFilters>(): T {
    const location = useLocation();

    return parseLocationQuery(location);
}
