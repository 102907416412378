import { createStyles } from '@material-ui/core/styles';
import injectSheet from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useState, useCallback } from 'react';
import AuthFormContent from './AuthFormContent';
import AuthFormNav from './AuthFormNav';
import AuthFormSocials from './AuthFormSocials';
import { IResponseError } from 'core/api/core';
import { useAuth } from '../AuthProvider';

type IAuthFormProps = {
    showRememberMe: boolean;
    showSocialLogin: boolean;
} & WithStyles<typeof styles>;

const AuthForm: React.FC<IAuthFormProps> = ({ classes, showRememberMe, showSocialLogin }) => {
    const [loginErrors, setLoginErrors] = useState<IResponseError[]>([]);
    const [signupErrors, setSignupErrors] = useState<IResponseError[]>([]);
    const [restoreErrors, setRestoreErrors] = useState<IResponseError[]>([]);
    const [restoreMessage, setRestoreMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [token, setToken] = useState('');
    const [tokenError, setTokenError] = useState('');

    const { setState } = useAuth();

    const clean = useCallback(() => {
        setRestoreMessage('');
        setLoginErrors([]);
        setSignupErrors([]);
        setRestoreErrors([]);
    }, [setRestoreMessage, setLoginErrors, setSignupErrors, setRestoreErrors]);

    const goToSignUp = useCallback(() => {
        clean();
        setState('signup');
        setPassword('');
        setPasswordError('');
    }, [clean, setState, setPassword, setPasswordError]);

    const goToLogin = useCallback(() => {
        clean();
        setState('login');
        setToken('');
        setTokenError('');
    }, [clean, setState, setToken, setTokenError]);

    return (
        <div className={classes.wrapper}>
            <AuthFormNav goToLogin={goToLogin} goToSignUp={goToSignUp} />
            <AuthFormContent
                goToLogin={goToLogin}
                goToSignUp={goToSignUp}
                showRememberMe={showRememberMe}
                loginErrors={loginErrors}
                setLoginErrors={setLoginErrors}
                password={password}
                passwordError={passwordError}
                restoreErrors={restoreErrors}
                restoreMessage={restoreMessage}
                setPassword={setPassword}
                setPasswordError={setPasswordError}
                setRestoreErrors={setRestoreErrors}
                setRestoreMessage={setRestoreMessage}
                setSignupErrors={setSignupErrors}
                setToken={setToken}
                setTokenError={setTokenError}
                signupErrors={signupErrors}
                token={token}
                tokenError={tokenError}
            />
            <AuthFormSocials showSocialLogin={showSocialLogin} setLoginErrors={setLoginErrors} />
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            marginTop: 2 + 'rem',
            flexDirection: 'column',
            backgroundColor: theme.colors.lightBg,
            color: theme.colors.contrastBgText,

            [theme.breakpoints.up('sm')]: {
                width: `calc(302px + 3.5rem)`,
            },

            [theme.breakpoints.down('xs')]: {
                width: 100 + 'vw',
                marginLeft: 0,
                marginRight: 0,
            },

            height: 'fit-content',
        },
    });

export default injectSheet(styles, 'AuthForm')(AuthForm);
