import { button, ieFix } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import getScrollbarSize from 'core/utils/getScrollbarSize';
import React from 'react';
import ReactModal, { defaultStyles } from 'react-modal';

interface IModalProps {
    open: boolean;
    onClose: () => void;
    label?: string;
    zIndex?: number;
}

const styles = (theme: ITheme) =>
    createStyles({
        overlay: {
            ...defaultStyles.overlay,
            overflowY: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            ...defaultStyles.content,
            padding: 0,
            top: 'auto',
            bottom: 'auto',
            right: 'auto',
            left: 'auto',

            [theme.breakpoints.down('xs')]: {
                height: 100 + '%',
                border: 'none',
            },
            ...ieFix({ position: 'relative' }),
        },
        close: {
            ...button(theme),
            padding: 0,
            color: theme.colors.contrastBg,
            position: 'absolute',
            top: '0.3rem',
            right: '0.3rem',
            fontSize: '1.3rem',
            width: '1.3rem',
            height: '1.3rem',
            zIndex: 2,

            [theme.breakpoints.down('xs')]: {
                fontSize: 2.3 + 'rem',
                width: 2.3 + 'rem',
                height: 2.3 + 'rem',
            },
        },
        bodyOpenClassName: {
            overflow: 'hidden',
            paddingRight: getScrollbarSize(),
        },
    });

class Modal extends React.PureComponent<IModalProps & WithStyles<typeof styles>> {
    render() {
        const { classes, open, onClose, children, label, zIndex = 100 } = this.props;

        return (
            <ReactModal
                aria={{
                    labelledby: label,
                    describedby: label,
                }}
                isOpen={open}
                onRequestClose={onClose}
                bodyOpenClassName={classes.bodyOpenClassName}
                overlayClassName={classes.overlay}
                className={classes.content}
                ariaHideApp={false}
                style={{ overlay: { zIndex } }}
            >
                <span aria-hidden="true" className={classes.close} onClick={onClose}>
                    &#x2715;
                </span>
                {children}
            </ReactModal>
        );
    }
}

export default injectSheet(styles, 'Modal')(Modal);
