import React from 'react';
import { WithStyles, ITheme } from 'core/style/interfaces';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { useForm, useField } from 'react-final-form-hooks';
import { fieldInput, field, button, buttonBlack, fieldInputInvalid } from 'core/style';
import store from './pinStore';
import { Link } from 'react-router-dom';
import { useLinks } from 'core/components/linksContext';
import c from 'classnames';

type IPinFormProps = {
    onNext: () => void;
    onAttachClick: () => void;
} & WithStyles<typeof styles>;

const PinForm: React.FC<IPinFormProps> = ({ classes, onNext, onAttachClick }) => {
    const links = useLinks();
    const { form, handleSubmit, submitting, pristine } = useForm<{ pin?: string }>({
        onSubmit: values => {
            store.setPin(values.pin);
            onNext();
        },
        validate: values => {
            const errors = {} as any;

            if (!values.pin) {
                errors.pin = 'Обязательное поле';
            }

            return errors;
        },
    });

    const pin = useField('pin', form);

    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            <label className={classes.field}>
                Введите пинкод
                <input
                    {...pin.input}
                    type="password"
                    className={c(classes.input, { [classes.invalid]: pin.meta.touched && pin.meta.invalid })}
                    maxLength={5}
                    minLength={5}
                    placeholder="пинкод"
                />
            </label>
            <p className={classes.note}>
                Для вашего удобства мы сохраним пинкод в памяти браузера на {store.timeout} минут
            </p>
            <button type="submit" disabled={submitting || pristine} className={classes.submit}>
                Подтвердить
            </button>
            <p className={classes.note}>
                Если вы забыли пинкод, можно привязать{' '}
                <Link onClick={onAttachClick} to={links.ofzConnectTinkoff.url(links.ofz.url())}>
                    счёт заново
                </Link>
            </p>
        </form>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: 1 + 'rem',
            paddingTop: 2 + 'rem',
            maxWidth: '250px',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)',
            },
        },
        field: {
            ...field,
            marginBottom: 1 + 'rem',
        },
        input: fieldInput,
        invalid: fieldInputInvalid,
        submit: {
            ...button(theme),
            ...buttonBlack(theme),
            marginBottom: '1rem',
        },
        note: {
            fontSize: '0.8rem',
            lineHeight: 1.5,
            marginBottom: '1rem',

            '&:last-child': {
                marginBottom: '0',
            },
        },
    });

export default injectSheet(styles)(PinForm);
