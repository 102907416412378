import c from 'classnames';
import { useLinks } from 'core/components/linksContext';
import useCommittedRef from 'core/hooks/useCommittedRef';
import useDropdown from 'core/hooks/useDropdown';
import { container, ieFix } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import SearchIcon from '../../svg/search.svg';
import { mobileHeaderHeight } from './const';

type IHeaderSearchProps = {} & WithStyles<typeof styles>;

const HeaderSearch: React.FC<IHeaderSearchProps> = ({ classes }) => {
    const [containerRef, isOpen, toggle, , close] = useDropdown();
    const history = useHistory();
    const links = useLinks();

    // Набор/отправка поиска
    const [search, setSearch] = useState('');
    const handleSetSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
        [],
    );
    const searchRef = useCommittedRef(search);
    const handleSearchButtonClick = useCallback(() => {
        history.push(`${links.search.url()}?search=${searchRef.current}`);
        close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close, setSearch]);
    const handleSubmitSearch = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSearchButtonClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Показ иконки лупы на десктопе
    const [showIcon, setShowIcon] = useState(true);
    const handleFocus = useCallback(() => setShowIcon(false), [setShowIcon]);
    const handleBlur = useCallback(() => setShowIcon(true), [setShowIcon]);

    return (
        <div className={classes.search} ref={containerRef}>
            {/* desktop */}
            <form action="." className={c(classes.hideIfMobile, classes.searchForm)} onSubmit={handleSubmitSearch}>
                <input
                    type="search"
                    name="q"
                    onChange={handleSetSearch}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={search}
                    placeholder="Поиск"
                    className={classes.searchInput}
                />
            </form>
            {showIcon && <SearchIcon className={c(classes.hideIfMobile, classes.searchDekstopIcon)} />}

            {/* mobile */}
            <SearchIcon className={c(classes.hideIfDekstop, classes.searchMobileIcon)} onClick={toggle} />
            <div className={c(classes.hideIfDekstop, classes.mobileSearch)} hidden={!isOpen}>
                <div className={classes.mobileContainer}>
                    <form action="." className={classes.searchForm} onSubmit={handleSubmitSearch}>
                        <input
                            type="search"
                            name="q"
                            onChange={handleSetSearch}
                            value={search}
                            className={classes.mobileSearchInput}
                        />
                    </form>
                    <button className={classes.mobileSearchButton} onClick={handleSearchButtonClick}>
                        Поиск
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        search: {
            position: 'relative',
            flexGrow: 1,
            display: 'flex',
            height: 100 + '%',
            alignItems: 'center',
            marginRight: 25,
            order: 2,

            [theme.breakpoints.down('xs')]: {
                order: 1,
                marginRight: 0,
                flexGrow: 0,
                position: 'static',
            },
        },
        searchForm: {
            display: 'flex',
            width: 100 + '%',
        },
        searchInput: {
            fontSize: 14,
            border: '1px solid rgb(0, 0, 0, 0.1)',
            height: 40,
            width: 100 + '%',
            boxSizing: 'border-box',
            outline: 'none',
            padding: '0px 38px 0 12px',

            '&:focus': {
                border: '1px solid rgb(0, 0, 0, 0.5)',
            },
        },
        searchDekstopIcon: {
            position: 'absolute',
            right: 12,
            width: 20,
            height: 20,
            alignSelf: 'center',
            fill: theme.colors.accent,

            ...ieFix({ top: 'calc(50% - 10px)' }),
        },
        searchMobileIcon: {
            width: 24,
            height: 24,
            fill: theme.colors.accent,
        },
        mobileSearch: {
            position: 'absolute',
            top: mobileHeaderHeight,
            width: 100 + '%',
            left: 0,
            fontSize: 13,
        },
        mobileContainer: {
            ...container(theme),
            display: 'flex',
        },
        mobileSearchInput: {
            border: '1px solid rgb(0, 0, 0, 0.1)',
            height: 35,
            boxSizing: 'border-box',
            outline: 'none',
            padding: '0px 15px',
            width: 100 + '%',

            '&:focus': {
                border: '1px solid rgb(0, 0, 0, 0.5)',
            },
        },
        mobileSearchButton: {
            padding: '0px 20px',
            border: 'none',
            background: theme.colors.accent,
            color: '#fff',
        },
        hideIfMobile: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        hideIfDekstop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    });

export default injectSheet(styles)(HeaderSearch);
