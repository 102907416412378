import c from 'classnames';
import { BadRequestError } from 'core/api/core';
import { graphqlApiSite } from 'core/api/graphql';
import CenterLoader from 'core/components/common/CenterLoader';
import Select from 'core/components/Select';
import { showSnackbar } from 'core/components/snackbar';
import { button, buttonAccent } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatNumberToDecimal } from 'core/utils';
import React, { useCallback, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import {
    Mutation,
    Query,
    TinkoffCurrencyPositionType,
    TinkoffMarketFormDataType,
    TinkoffOrderStatusEnum,
    TinkoffPlacedMarketOrderType,
} from 'site/graphql';
import ErrorIcon from '../../../../core/svg/error.svg';
import SuccessIcon from '../../../svg/success.svg';
import store from './pinStore';
import { useTinkoff } from './tinkoffContext';

type IPlaceMarketFormProps = {
    onNext: () => void;
    ticker: string;
    amount: number;
} & WithStyles<typeof styles>;

const calcBalance = (balance: TinkoffCurrencyPositionType[], currency: string, account: string) => {
    if (!balance.length || !account) return 0;
    const item = balance.find(_ => _.currency === currency && _.brokerAccountId === account);
    if (!item) return 0;
    return item.balance - item.blocked;
};

const PlaceMarketForm: React.FC<IPlaceMarketFormProps> = ({ classes, ticker, amount }) => {
    const {
        setIsPlaceMarketModalOpen,
        setTicker,
        setAmount,
        setIsPinModalOpen,
        setNext,
        portfolioNominal,
    } = useTinkoff();
    const [formStep, setFormStep] = React.useState<'FORM' | 'SUCCESS' | 'FAIL'>('FORM');
    const [formData, setFormData] = React.useState<TinkoffMarketFormDataType>({
        instrument: {
            name: '----',
            figi: '',
            currency: 'RUB',
        } as any,
        bestAsk: 0,
        accounts: [],
        balance: [],
    });
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string>(null);
    const loadData = useCallback(() => {
        setLoading(true);
        setError(null);
        graphqlApiSite<Pick<Query, 'tinkoffFormData'>>(
            `
            {
                tinkoffFormData(ticker: "${ticker}", pin: "${store.pincode}") {
                    accounts {
                        brokerAccountId
                        brokerAccountType
                    }
                    instrument {
                        figi
                        isin
                        name
                        currency
                        lot
                        minPriceIncrement
                    }
                    bestAsk
                    balance {
                        brokerAccountId
                        balance
                        blocked
                        currency
                    }
                }
            }`,
            null,
            'TinkoffFormData',
        ).then(
            ({ data }) => {
                setFormData(data.tinkoffFormData);
                setLoading(false);
            },
            error => {
                setLoading(false);
                if (error instanceof BadRequestError && error.json && error.json.errors) {
                    setError(error.json.errors[0].message);
                    if (error.json.errors.find(_ => _.message === 'Неверный пинкод')) {
                        showSnackbar({ snackbarErrors: error.json.errors });
                        store.resetPin();
                        setIsPlaceMarketModalOpen(false);
                        setTicker('');
                        setAmount(0);

                        setIsPinModalOpen(true);
                        setNext(() => () => {
                            setIsPlaceMarketModalOpen(true);
                            setTicker(ticker);
                            setAmount(amount);
                        });
                    }
                }
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticker, setError]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const firstAccount = formData.accounts[0] || {
        brokerAccountId: '---',
        brokerAccountType: '---',
    };

    const [orderResult, setOrderResult] = React.useState<Partial<TinkoffPlacedMarketOrderType>>({});

    const isManyAccounts = formData.accounts.length > 1;

    const { form, pristine, submitting, handleSubmit } = useForm<{ selectedAccount: string }>({
        onSubmit: values => {
            if (!store.pincode) {
                showSnackbar({
                    snackbarErrors: [
                        {
                            message:
                                'Ваш пинкод был удален из памяти браузера, пожалуйста нажмите кнопку Купить еще раз',
                        },
                    ],
                });
                return Promise.reject();
            }
            return graphqlApiSite<Pick<Mutation, 'placeMarketOrder'>>(
                `
                    mutation {
                        placeMarketOrder(pin: "${store.pincode}", figi: "${
                    formData.instrument.figi
                }", brokerAccountId: "${
                    isManyAccounts ? values.selectedAccount : firstAccount.brokerAccountId
                }", lots: ${amount}) {
                            message
                            rejectReason
                            status
                        }
                    }
                `,
                null,
                'PlaceMarketOrder',
            ).then(
                ({ data }) => {
                    store.resetPin();
                    setOrderResult(data.placeMarketOrder);
                    if (data.placeMarketOrder.status !== TinkoffOrderStatusEnum.Rejected) {
                        setFormStep('SUCCESS');
                    } else {
                        setFormStep('FAIL');
                    }
                },
                error => {
                    setLoading(false);
                    if (error instanceof BadRequestError && error.json && error.json.errors) {
                        showSnackbar({ snackbarErrors: error.json.errors });
                    }
                },
            );
        },
        validate: values => {
            const errors = {} as any;
            if (isManyAccounts && !values.selectedAccount) {
                errors.selectedAccount = 'Обязательное поле';
            }
            return errors;
        },
    });

    const selectedAccount = useField('selectedAccount', form);

    const balance = calcBalance(
        formData.balance,
        formData.instrument.currency,
        isManyAccounts ? selectedAccount.input.value : firstAccount.brokerAccountId,
    );

    return (
        <div className={classes.root}>
            {(loading || submitting) && <CenterLoader absolute />}
            {formStep === 'FORM' && (
                <>
                    <h2 className={classes.title}>
                        Вы хотите купить {formData.instrument.name} <pre>ISIN - {formData.instrument.isin}</pre>
                    </h2>
                    <div className={classes.data}>
                        <div className={classes.stat}>
                            <span className={classes.stat__label}>В количестве:</span>
                            <span className={classes.stat__value}>{amount} шт.</span>
                        </div>
                        <div className={classes.stat}>
                            <span className={classes.stat__label}>Текущая цена одной облигации:</span>
                            <span className={classes.stat__value}>{formatNumberToDecimal(formData.bestAsk)} ₽</span>
                        </div>
                        <div className={classes.stat}>
                            <span className={classes.stat__label}>Расчетный объем сделки (без учета НКД):</span>
                            <span className={classes.stat__value}>
                                {formatNumberToDecimal(formData.bestAsk * amount)} <span className="ruble-icon">₽</span>
                            </span>{' '}
                            <span className={classes.stat__note}>
                                Расчетный объем может отличаться от фактического после совершения сделки
                            </span>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.row}>
                            {isManyAccounts ? (
                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                <label className={classes.row__field}>
                                    <span className={classes.row__label}>Выбрать счёт</span>
                                    <Select
                                        options={formData.accounts.map(_ => ({
                                            value: _.brokerAccountId,
                                            label: `${_.brokerAccountId} - ${_.brokerAccountType}`,
                                        }))}
                                        placeholder="выбрать"
                                        onChange={selectedAccount.input.onChange}
                                        value={selectedAccount.input.value}
                                        styles={{
                                            container: { width: '100%' },
                                        }}
                                    />
                                </label>
                            ) : (
                                <div className={classes.stat}>
                                    <span className={classes.stat__label}>Счёт:</span>
                                    <span className={classes.stat__value}>
                                        {firstAccount.brokerAccountId} - {firstAccount.brokerAccountType}
                                    </span>
                                </div>
                            )}

                            <div className={classes.stat}>
                                <span className={classes.stat__label}>Ваш баланс по счёту:</span>
                                <span className={classes.stat__value}>
                                    {formatNumberToDecimal(balance)} <span className="ruble-icon">₽</span>
                                </span>
                                {balance < formData.bestAsk * amount && (
                                    <span className={classes.stat__noteAccent}>
                                        Внимание! Недостаточно средств для полного исполнения данной заявки
                                    </span>
                                )}
                                {balance >= formData.bestAsk * amount && balance < portfolioNominal && (
                                    <span className={classes.stat__noteAccent}>
                                        Внимание! Недостаточно средств для покупки полного портфеля. Измените параметры
                                        для перерасчета стоимости портфеля.
                                    </span>
                                )}
                            </div>

                            <div className={c(classes.row__field, classes.row__buy)}>
                                <button
                                    className={classes.buy}
                                    disabled={(isManyAccounts && (pristine || submitting)) || !formData.instrument.figi}
                                >
                                    Оформить заявку
                                </button>
                            </div>

                            {error && <div className={classes.formDataError}>{error}</div>}
                        </div>
                    </form>
                </>
            )}
            {formStep === 'SUCCESS' && (
                <>
                    <SuccessIcon className={classes.successIcon} />
                    <h2 className={c(classes.title, classes.titleCenter)}>
                        Ваша заявка по {formData.instrument.name} успешно принята.
                    </h2>
                    <p style={{ marginBottom: '0' }}>
                        Со статусом заявки можно ознакомиться в{' '}
                        <a href="https://www.tinkoff.ru/invest-terminal/" target="_blank" rel="noopener noreferrer">
                            терминале Тинькофф Инвестиций
                        </a>
                    </p>
                </>
            )}
            {formStep === 'FAIL' && (
                <>
                    <ErrorIcon className={classes.errorIcon} />
                    <h2 className={c(classes.title, classes.titleCenter)}>
                        Ваша заявка по {formData.instrument.name} отклонена.
                    </h2>
                    <p style={{ marginBottom: '0' }}>Причина: {orderResult.rejectReason}</p>
                </>
            )}
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        root: {
            padding: '1rem',

            [theme.breakpoints.up('sm')]: {
                width: '400px',
            },
        },
        title: {
            marginTop: 0,
            marginBottom: '1rem',
            fontSize: '1.3rem',

            '& pre': {
                fontSize: '1rem',
                color: theme.colors.textLight,
                marginBottom: '0',
            },
        },
        titleCenter: {
            textAlign: 'center',
        },
        data: {
            marginLeft: '-1rem',
            marginRight: '-1rem',
            marginBottom: '1rem',
            flexWrap: 'wrap',
        },
        stat: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            boxSizing: 'border-box',
            lineHeight: 1,
            marginBottom: '1rem',
        },
        stat__label: {
            fontSize: '0.8rem',
        },
        stat__value: {
            marginLeft: 'auto',
        },
        stat__note: {
            color: theme.colors.textLight,
            fontSize: '0.8rem',
        },
        stat__noteAccent: {
            color: theme.colors.accent,
            textAlign: 'center',
            fontSize: 0.8 + 'rem',
            marginTop: 1 + 'rem',
        },
        row: {
            marginLeft: '-1rem',
            marginRight: '-1rem',
            marginBottom: '1rem',

            '&:last-child': {
                marginBottom: '0',
            },
        },
        row__field: {
            display: 'block',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            boxSizing: 'border-box',
            marginBottom: '1rem',

            '&:last-child': {
                marginBottom: '0',
            },
        },
        row__label: {
            display: 'block',
        },
        row__buy: {
            textAlign: 'center',
        },
        row__total: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                alignItems: 'center',
            },
        },
        buy: {
            ...button(theme),
            ...buttonAccent(theme),
            padding: '10px 16px',
        },
        successIcon: {
            width: '100%',
            height: '150px',
            fill: theme.colors.success,
        },
        errorIcon: {
            width: '100%',
            height: '150px',
            fill: theme.colors.error,
        },
        formDataError: {
            fontSize: 0.8 + 'rem',
            textAlign: 'center',
            color: theme.colors.accent,
            padding: '0 1rem',
            lineHeight: 1,
        },
    });

export default injectSheet(styles)(PlaceMarketForm);
