import { graphqlApiSite } from 'core/api/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { GetPayProductFormQuery, PaymentPublicMethodEnum, ProductFormPaymentStatusEnum, PromoCode } from 'site/graphql';
import { useAuth } from '../auth/AuthProvider';
import useCreateOrder from './hooks/useCreateOrder';
import useCreatePayment from './hooks/useCreatePayment';
import PaymentFormPresent from './PaymentFormPresent';
import { trackAdd } from '../../../core/tracking';

export type IExpandedType = 'CARD' | 'WALLET' | 'INSTALLMENTS' | 'CASH' | 'POINTS';

type IPaymentFormContainerProps = {
    productForm: GetPayProductFormQuery['productForm'];
};

const PaymentFormContainer: React.FC<IPaymentFormContainerProps> = ({ productForm }) => {
    // State
    const [expanded, setExpanded] = useState<IExpandedType | boolean>('CARD');
    const [method, setMethod] = useState(PaymentPublicMethodEnum.BankCard);
    const [promoCodeValue, setPromoCodeValue] = useState('');
    const [promoCode, setPromoCode] = useState<PromoCode>(null);
    const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState<string>(null);
    const [loading, setLoading] = useState(true);

    const { myAccount, setPayProductFormId } = useAuth();
    const [createOrder, createOrderErrors, inviteLink] = useCreateOrder(productForm.id, setLoading);
    const [createPayment, isFree, paymentLoading, createPaymentErrors] = useCreatePayment(
        productForm,
        method,
        promoCode,
    );

    // Создание заказа на маунте компонента
    useEffect(() => {
        if (
            productForm.paymentStatus === ProductFormPaymentStatusEnum.Never ||
            productForm.paymentStatus === ProductFormPaymentStatusEnum.Expired
        ) {
            createOrder();

            // Трекаем только присоединение к платным формам
            if (productForm.price !== 0) {
                trackAdd({
                    id: productForm.product.id,
                    name: productForm.product.name,
                    price: productForm.price,
                    variant: productForm.type,
                    quantity: 1,
                    category: productForm.product.tagsString,
                    brand: productForm.product.contentProvider.name,
                });
            }
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Закрытие модального окна
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeModal = useCallback(() => setPayProductFormId(null), []);

    // Раскрытие выпадающего меню
    const handleExpandChange = useCallback(
        (panel: IExpandedType) => (event: React.MouseEvent<HTMLElement, MouseEvent>, expanded: boolean) => {
            setExpanded(expanded ? panel : false);
        },
        [],
    );

    // Выбор метода оплаты
    const handleMethodChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMethod(event.target.name as PaymentPublicMethodEnum);
    }, []);

    // Ввод промокода
    const handlePromoCodeValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCodeValue(event.target.value);
    }, []);

    // Проверка промокода
    const handlePromoCodeSubmit = useCallback(() => {
        const query = `{
            checkPromoCode(name: ${JSON.stringify(promoCodeValue)}, orderId: ${productForm.order.id}) {
                name
                type
                amount
            }
        }`;

        graphqlApiSite<{ checkPromoCode: PromoCode }>(query, null)
            .then(resp => {
                setPromoCodeErrorMessage(null);
                setPromoCode(resp.data.checkPromoCode);
            })
            .catch(errors => {
                setPromoCodeErrorMessage(errors.errors[0].message);
                setPromoCode(null);
            });
    }, [promoCodeValue, productForm]);

    return (
        <PaymentFormPresent
            loading={loading || paymentLoading}
            createOrderErrors={createOrderErrors}
			inviteLink={inviteLink}
            createPayment={createPayment}
            createPaymentErrors={createPaymentErrors}
            expanded={expanded}
            method={method}
            productForm={productForm}
            promoCode={promoCode}
            isFree={isFree}
            promoCodeErrorMessage={promoCodeErrorMessage}
            promoCodeValue={promoCodeValue}
            refPoints={myAccount.refPoints}
            closeModal={closeModal}
            onExpandChange={handleExpandChange}
            onMethodChange={handleMethodChange}
            onPromoCodeValueChange={handlePromoCodeValueChange}
            onPromoCodeSubmit={handlePromoCodeSubmit}
        />
    );
};

export default PaymentFormContainer;
