import { ILinksContext } from 'core/components/linksContext';
import { stringify as stringifyQueryString } from 'query-string';
import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { IPaymentType } from 'site/components/payments/PaymentSuccess';

const routingPrefix = LOCAL_SETTINGS.GLOBAL_ROUTE_PREFIX || '';

type IRoutingComponents = {
    ProductDetail: React.ComponentType;
    ProductDetailStart: React.ComponentType;
    CourseList: React.ComponentType;
    Main: React.ComponentType;
    NotFound: React.ComponentType;
    AccountMaster: React.ComponentType;
    BrokerList: React.ComponentType;
    ContactsPage: React.ComponentType;
    ReviewsPage: React.ComponentType;
    PrivateProductDetail: React.ComponentType;
    LecturersList: React.ComponentType;
    LecturerDetail: React.ComponentType;
    ResetPassword: React.ComponentType;
    Page: React.ComponentType;
    Articles: React.ComponentType;
    ArticleDetail: React.ComponentType;
    BondsPortfolio: React.ComponentType;
    ConnectTinkoff: React.ComponentType;
    OAuthLoginForm: React.ComponentType;
    HighLow: React.ComponentType;
    Evolution: React.ComponentType;
    DividendTable: React.ComponentType;
    DividendDetail: React.ComponentType;
    ServicePage: React.ComponentType;
    AttachTinkoffDisclaimer: React.ComponentType;
    ComRegion: React.ComponentType;
    Search: React.ComponentType;
    Heatmap: React.ComponentType;
    Expcalendar: React.ComponentType;
    FinancialCalculators: React.ComponentType;
    Service52WeeksPage: React.ComponentType;
    ServiceMarketMapPage: React.ComponentType;
    Roboadviser: React.ComponentType;
    PaymentSuccess: React.ComponentType;
};

export default function makeRouting({
    ProductDetail,
    ProductDetailStart,
    CourseList,
    Main,
    NotFound,
    AccountMaster,
    BrokerList,
    ContactsPage,
    ReviewsPage,
    PrivateProductDetail,
    LecturersList,
    LecturerDetail,
    ResetPassword,
    Page,
    Articles,
    ArticleDetail,
    BondsPortfolio,
    ConnectTinkoff,
    OAuthLoginForm,
    HighLow,
    Evolution,
    DividendTable,
    DividendDetail,
    ServicePage,
    AttachTinkoffDisclaimer,
    ComRegion,
    Search,
    Heatmap,
    Expcalendar,
    FinancialCalculators,
    Service52WeeksPage,
    ServiceMarketMapPage,
    Roboadviser,
    PaymentSuccess,
}: IRoutingComponents) {
    return (
        <Switch>
            <Route path={linksContextValue.pageDetail.url('heatmap')}>
                <Redirect to={linksContextValue.heatmap.url()} />
            </Route>
            <Route path={linksContextValue.pageDetail.url('expcalendar')}>
                <Redirect to={linksContextValue.expcalendar.url()} />
            </Route>

            <Route path={linksContextValue.home.url()} exact component={Main} />
            <Route path={linksContextValue.courseList.url()} exact component={CourseList} />
            <Route path={linksContextValue.productDetail.url(11732)} strict component={ProductDetailStart} /> {/*Инвестиция.Старт */}
            <Route path={linksContextValue.productDetail.url(':productId')} exact component={ProductDetail} />
            <Route
                path={linksContextValue.accountProductDetail.url(':productId')}
                exact
                component={PrivateProductDetail}
            />
            <Route path={linksContextValue.account.url()} component={AccountMaster} />
            <Route path={linksContextValue.brokers.url()} component={BrokerList} />
            <Route path={linksContextValue.contacts.url()} component={ContactsPage} />
            <Route path={linksContextValue.ofz.url()} component={BondsPortfolio} />
            <Route path={linksContextValue.ofzConnectTinkoff.url()} component={ConnectTinkoff} />
            <Route path={linksContextValue.highLow.url()} component={HighLow} />
            <Route path={linksContextValue.dividendDetail.url(':symbol')} component={DividendDetail} />
            <Route path={linksContextValue.dividendTable.url()} component={DividendTable} />
            <Route path={linksContextValue.otzyvy.url()} component={ReviewsPage} />
            <Route path={linksContextValue.teachers.url()} exact component={LecturersList} />
            <Route path={linksContextValue.teacherDetail.url(':teacherId')} exact component={LecturerDetail} />
            <Route path={linksContextValue.resetPassword.url()} exact component={ResetPassword} />
            <Route path={linksContextValue.pageDetail.url(':slug')} exact component={Page} />
            <Route path={linksContextValue.articles.url()} exact component={Articles} />
            <Route path={linksContextValue.articleDetail.url(':slug')} exact component={ArticleDetail} />
            <Route path={linksContextValue.oauth.url()} exact component={OAuthLoginForm} />
            <Route path={linksContextValue.evolution.url()} exact component={Evolution} />
            <Route path={linksContextValue.servicePage.url()} component={ServicePage} />
            <Route path={linksContextValue.search.url()} component={Search} />
            <Route path={linksContextValue.comRegion.url()} component={ComRegion} />
            <Route path={linksContextValue.attachTinkoffDisclaimer.url()} component={AttachTinkoffDisclaimer} />
            <Route path={linksContextValue.heatmap.url()} component={Heatmap} />
            <Route path={linksContextValue.expcalendar.url()} component={Expcalendar} />
            <Route path={linksContextValue.financialCalculators.url()} component={FinancialCalculators} />
            <Route path={linksContextValue.service52weeks.url()} component={Service52WeeksPage} />
            <Route path={linksContextValue.serviceMarketMap.url()} component={ServiceMarketMapPage} />
            <Route path={linksContextValue.roboadviser.url()} component={Roboadviser} />
            <Route
                path={linksContextValue.paymentSuccess.url(':paymentType' as IPaymentType)}
                exact
                component={PaymentSuccess}
            />
            <Route path={`${routingPrefix}/*`} component={NotFound} />
        </Switch>
    );
}

export const linksContextValue: ILinksContext = {
    home: {
        url: () => `${routingPrefix}/`,
        Link,
    },
    courseList: {
        url: () => `${routingPrefix}/courses`,
        Link,
    },
    productDetail: {
        url: id => `${routingPrefix}/courses/${id}`,
        Link,
    },
    accountProductDetail: {
        url: id => `${routingPrefix}/account/courses/${id}`,
        Link,
    },
    accountCourses: {
        url: () => `${routingPrefix}/account/courses`,
        Link,
    },
    accountInvestment: {
        url: () => `${routingPrefix}/account/investment`,
        Link,
    },
    account: {
        url: () => `${routingPrefix}/account/`,
        Link,
    },
    brokers: {
        url: () => `${routingPrefix}/brokers`,
        Link,
    },
    contacts: {
        url: () => `${routingPrefix}/contacts`,
        Link,
    },
    articles: {
        url: () => `${routingPrefix}/articles`,
        Link,
    },
    articleDetail: {
        url: slug => `${routingPrefix}/articles/${slug}`,
        Link,
    },
    otzyvy: {
        url: () => `${routingPrefix}/page/otzyvy`,
        Link,
    },
    faq: {
        url: () => `${routingPrefix}/page/chasto-zadavaemie-voprosi`,
        Link,
    },
    ofzFaq: {
        url: () => `${routingPrefix}/page/ofz-faq`,
        Link,
    },
    teachers: {
        url: () => `${routingPrefix}/teachers`,
        Link,
    },
    teacherDetail: {
        url: id => `${routingPrefix}/teachers/${id}`,
        Link,
    },
    resetPassword: {
        url: () => `${routingPrefix}/resetpassword`,
        Link,
    },
    userAgreement: {
        url: () => `${routingPrefix}/offer.docx`,
    },
    personalAgreement: {
        url: () => `${routingPrefix}/personal.docx`,
    },
    pageDetail: {
        url: slug => `${routingPrefix}/page/${slug}`,
    },
    about: {
        url: () => `${routingPrefix}/page/red-circule`,
        Link,
    },
    ofz: {
        url: () => `${routingPrefix}/podbor-obligaciy`,
        Link,
    },
    ofzDisclaimer: {
        url: () => `${routingPrefix}/page/podbor-obligaciy-disclaimer`,
        Link,
    },
    ofzConnectTinkoff: {
        url: (to?: string) =>
            `${routingPrefix}/privyazka-scheta-tinkoff${to ? '?' + stringifyQueryString({ to }) : ''}`,
        Link,
    },
    attachTinkoffDisclaimer: {
        url: () => `${routingPrefix}/denal-of-responsibility`,
        Link,
    },
    heatmap: {
        url: () => `${routingPrefix}/heatmap`,
        Link,
    },
    expcalendar: {
        url: () => `${routingPrefix}/expcalendar`,
        Link,
    },
    oauth: {
        url: () => `${routingPrefix}/oauth/login`,
    },
    highLow: {
        url: () => `${routingPrefix}/min-max-akcii`,
        Link,
    },
    evolution: {
        url: () => `${routingPrefix}/evolution-bonds`,
        Link,
    },
    dividendTable: {
        url: () => `${routingPrefix}/dividend-shares`,
        Link,
    },
    dividendDetail: {
        url: (symbol: string) => `${routingPrefix}/dividend-shares/${symbol}`,
        Link,
    },
    servicePage: {
        url: () => `${routingPrefix}/services-for-traders-and-investors`,
        Link,
    },
    search: {
        url: () => `${routingPrefix}/searching`,
        Link,
    },
    comRegion: {
        url: () => `${routingPrefix}/evolution-bonds-consent`,
        Link,
    },
    financialCalculators: {
        url: () => `${routingPrefix}/finance-calculator`,
        Link,
    },
    service52weeks: {
        url: () => `${routingPrefix}/52-weeks`,
        Link,
    },
    serviceMarketMap: {
        url: () => `${routingPrefix}/market-moex-heat-map`,
        Link,
    },
    roboadviser: {
        url: () => `${routingPrefix}/roboadviser`,
        Link,
    },
    paymentSuccess: {
        url: paymentType => `${routingPrefix}/success/${paymentType}`,
        Link,
    },
};
