import { ApolloError } from 'apollo-boost';
import { trackClickPayButton } from 'core/tracking';
import { getErrors, IApolloError } from 'core/utils/apollo';
import { GraphQLError } from 'graphql';
import { useLinks } from 'core/components/linksContext';
import gql from 'graphql-tag';
import { useState } from 'react';
import {
    GetPayProductFormQuery,
    PaymentPublicMethodEnum,
    PromoCode,
    useCreatePaymentMutation,
    useGetCourseListQuery,
} from 'site/graphql';
import useCourseListFilters from 'site/components/courses/hooks/useCourseListFilters';

gql`
    mutation CreatePayment($arguments: PaymentPublicInsertInput!) {
        createPayment(arguments: $arguments) {
            id
            confirmationUrl
            price
        }
    }
`;

const useCreatePayment = (
    productForm: GetPayProductFormQuery['productForm'],
    method: PaymentPublicMethodEnum,
    promoCode: PromoCode,
) => {
    const links = useLinks();
    const [isFree, setIsFree] = useState<boolean>(false);
    const [createPaymentErrors, setCreatePaymentErrors] = useState<IApolloError[]>([]);
    const filter = useCourseListFilters();
    const { refetch } = useGetCourseListQuery({
        fetchPolicy: 'cache-and-network',
        variables: { filter },
        notifyOnNetworkStatusChange: true,
    });
    const [createPayment, { loading }] = useCreatePaymentMutation({
        variables: {
            arguments: {
                orderId: (productForm && productForm.order && productForm.order.id) || 0,
                method,
                promoCode: promoCode ? promoCode.name : undefined,
                returnUrl: window.location.origin + links.paymentSuccess.url('course'),
            },
        },
        onError: (error: ApolloError) => setCreatePaymentErrors(getErrors(error)),
        onCompleted: data => {
            trackClickPayButton(method);
            const confirmationUrl = data.createPayment.confirmationUrl;
            const price = data.createPayment.price;
            if (price !== 0) {
                if (confirmationUrl) {
                    window.location.href = confirmationUrl;
                } else {
                    setCreatePaymentErrors([{ message: 'Ошибка получения адреса для оплаты' } as GraphQLError]);
                }
            } else {
                refetch();
                setIsFree(true);
            }
        },
    });

    return [createPayment, isFree, loading, createPaymentErrors] as const;
};

export default useCreatePayment;
