import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import AuthMenuDropDown from './AuthMenuDropDown';
import { loginButtonStyles, signupButtonStyles } from './const';
import useHeaderAuth from './hooks/useHeaderAuth';
import { buttonShadow } from 'core/style';

type IHeaderAuthProps = {} & WithStyles<typeof styles>;

const HeaderAuth: React.FC<IHeaderAuthProps> = ({ classes }) => {
    const [isLoggedIn, login, logout, signup] = useHeaderAuth();

    return (
        <>
            <div className={classes.hideIfDekstop}>
                <AuthMenuDropDown onLogout={logout} />
            </div>
            <div className={classes.hideIfMobile}>
                {isLoggedIn ? (
                    <AuthMenuDropDown onLogout={logout} />
                ) : (
                    <>
                        <button className={classes.loginButton} onClick={login}>
                            Войти
                        </button>
                        <button className={classes.signupButton} onClick={signup}>
                            Зарегистрироваться
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        loginButton: {
            ...loginButtonStyles(theme),
        },
        signupButton: {
            ...signupButtonStyles(theme),
            ...buttonShadow,
        },
        hideIfMobile: {
            display: 'flex',

            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        hideIfDekstop: {
            display: 'flex',

            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    });

export default injectSheet(styles)(HeaderAuth);
