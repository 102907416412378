import { flow } from './fp';
import { IMPOSSIBLE_DATE } from '../const';
import { numEnding } from './string';

const defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timezone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
};

export const formatStringDate = (value: string, options?: object) => {
    return new Date(String(value)).toLocaleString('ru', options || defaultOptions);
};

/**
 * Получить текстовое представление месяца от date.getMonth()
 * @param monthNumber Номер месяца от getMonth()
 * @param genitive Представление в родительском падеже
 * @param firstCharUpper Первый символ в верхнем регистре
 */
const getTextMonth = (monthNumber: number, genitive = false, firstCharUpper = false) => {
    let month: string;

    switch (monthNumber) {
        case 0:
            month = genitive ? 'Января' : 'Январь';
            break;
        case 1:
            month = genitive ? 'Февраля' : 'Февраль';
            break;
        case 2:
            month = genitive ? 'Марта' : 'Март';
            break;
        case 3:
            month = genitive ? 'Апреля' : 'Апрель';
            break;
        case 4:
            month = genitive ? 'Мая' : 'Май';
            break;
        case 5:
            month = genitive ? 'Июня' : 'Июнь';
            break;
        case 6:
            month = genitive ? 'Июля' : 'Июль';
            break;
        case 7:
            month = genitive ? 'Августа' : 'Август';
            break;
        case 8:
            month = genitive ? 'Сентября' : 'Сентябрь';
            break;
        case 9:
            month = genitive ? 'Октября' : 'Октябрь';
            break;
        case 10:
            month = genitive ? 'Ноября' : 'Ноябрь';
            break;
        case 11:
            month = genitive ? 'Декабря' : 'Декабрь';
            break;
        default:
            return null;
    }

    return firstCharUpper ? month : month.toLowerCase();
};

const formatTimeNumber = (n: number): string => (n < 10 ? `0${n}` : n.toString());

export type StrOrDate = string | Date;
export const checkAndConvertToDate = (date: StrOrDate, strict = true) => {
    if (!date) {
        if (!strict) return null;
        throw new Error('Неверная дата');
    }

    if (typeof date === 'string') {
        date = new Date(date);
    }

    if (!isFinite(date.getTime())) {
        if (!strict) return null;
        throw new Error('Неверная дата');
    }

    return date;
};

const convertToMoscowDate = (date: Date) => {
    const moscowDate = new Date(date.getTime());
    moscowDate.setUTCHours(date.getUTCHours() + 3);
    return moscowDate;
};

export const convertFromMskToUtc = (date: Date) => {
    const moscowDate = new Date(date.getTime());
    moscowDate.setUTCHours(date.getUTCHours() - 3);
    return moscowDate;
};

export const formatDayMonth = (date: Date) => `${date.getDate()} ${getTextMonth(date.getMonth(), true, false)}`;
export const formatUTCDayMonth = (date: Date) =>
    `${date.getUTCDate()} ${getTextMonth(date.getUTCMonth(), true, false)}`;
export const formatTime = (date: Date) => `${date.getHours()}:${formatTimeNumber(date.getMinutes())}`;
export const formatTimeSeconds = (date: Date) =>
    `${date.getHours()}:${formatTimeNumber(date.getMinutes())}:${formatTimeNumber(date.getSeconds())}`;
export const formatUTCTime = (date: Date) => `${date.getUTCHours()}:${formatTimeNumber(date.getUTCMinutes())}`;
export const formatDayMonthYear = (date: Date) =>
    `${date.getDate()} ${getTextMonth(date.getMonth(), true, false)} ${date.getFullYear()}`;
export const formatDayMonthYearDot = (date: Date) =>
    `${formatTimeNumber(date.getDate())}.${formatTimeNumber(date.getMonth() + 1)}.${date.getFullYear()}`;
export const formatMonthYear = (date: Date) => `${getTextMonth(date.getMonth(), false, true)} ${date.getFullYear()}`;
export const isMoscowTz = () => {
    return new Date().getTimezoneOffset() === -180;
};
export const formatToLocalTimeSeconds = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatTimeSeconds); // 19:00:00
export const formatToLocalTime = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatTime); // 19:00
export const formatToMoscowTime = flow<StrOrDate, Date, Date, string>(
    checkAndConvertToDate,
    convertToMoscowDate,
    formatUTCTime,
); // 19:00 (UTC+3)
export const formatToLocalDayMonth = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatDayMonth); // 5 июля
export const formatToMoscowDayMonth = flow<StrOrDate, Date, Date, string>(
    checkAndConvertToDate,
    convertToMoscowDate,
    formatUTCDayMonth,
); // 5 июля (UTC+3)
export const formatToLocalDayMonthYear = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatDayMonthYear); // 5 июля 2018
export const formatToLocalDayMonthYearDot = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatDayMonthYearDot); // 05.07.2018
export const formatToMonthYear = flow<StrOrDate, Date, string>(checkAndConvertToDate, formatMonthYear); // Июль 2018

/**
 * Форматирует значение TimeSpan
 * @example 21412 -> "5 ч. 56 мин. 52 сек."
 */
export const formatTimeSpan = (timeSpan: number): string => {
    const hours = Math.floor(timeSpan / 3600);
    const minutes = Math.floor((timeSpan - hours * 3600) / 60);
    const seconds = timeSpan - hours * 3600 - minutes * 60;

    const hoursString = hours ? `${hours} ч.` : '';
    const minutesString = minutes ? `${minutes} мин.` : '';
    const secondsString = seconds ? `${seconds} сек.` : '';

    return [hoursString, minutesString, secondsString].join(' ');
};

/**
 * Форматирует значение TimeSpan
 * @example 21412 -> "05:56"
 */
export const formatTimeSpanHHMM = (timeSpan: number): string => {
    const hours = Math.floor(timeSpan / 3600);
    const minutes = Math.floor((timeSpan - hours * 3600) / 60);

    return `${formatTimeNumber(hours)}:${formatTimeNumber(minutes)}`;
};

/**
 * Переводит строковое представление TimeSpan в кол-ов секунд
 * @example "05:56" -> 21412
 */
export const convertStringTimeSpanToSeconds = (timeSpan: string): number => {
    const [hoursString, minutesString] = timeSpan.split(':');
    const hours = Number(hoursString) || 0;
    const minutes = Number(minutesString) || 0;

    return hours * 3600 + minutes * 60;
};

/**
 * Добавить значение TimeSpan к дате
 * @param date Date or string date
 * @param timeSpan Кол-во секунд
 */
export const addTimeSpanToDate = (date: StrOrDate, timeSpan: number): Date => {
    const convertedDate = checkAndConvertToDate(date);
    convertedDate.setSeconds(convertedDate.getSeconds() + timeSpan);
    return convertedDate;
};

/**
 * Добавление дней к дате
 */
export const addDaysToDate = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
};

export const isImpossibleDate = (date: string | Date) => {
    if (!date) return false;

    if (typeof date === 'string') {
        return Date.parse(date) === Date.parse(IMPOSSIBLE_DATE);
    }

    return date.getTime && date.getTime() === Date.parse(IMPOSSIBLE_DATE);
};

/**
 * Difference in Months between two dates
 */
export const monthDiff = (d1: Date, d2: Date) => {
    let months: number;

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();

    return months <= 0 ? 0 : months;
};

/**
 * Возвращает строку относительного времени
 * @example 1 год 6 месяцев
 */
export const fromNow = (fromDate: Date, toDate = new Date()): string => {
    const monthDiffTotal: number = monthDiff(fromDate, toDate);
    const yearAmount: number = Math.floor(monthDiffTotal / 12);
    const monthAmount: number = monthDiffTotal % 12;
    const yearStr: string = yearAmount === 0 ? '' : `${yearAmount} ${numEnding(yearAmount, ['год', 'года', 'лет'])} `;
    const monthStr: string =
        monthAmount === 0 ? '' : `${monthAmount} ${numEnding(monthAmount, ['месяц', 'месяца', 'месяцев'])}`;
    if ((yearStr + monthStr).trim() === '') return 'недавно';
    return `${yearStr}${monthStr}`;
};

/**
 * Возвращает первый день месяца переданной даты
 * @example 11.02.2019 -> 01.02.2019
 */
export const getFirstMonthDay = (date: Date): Date => {
    const firstDate = new Date();
    firstDate.setFullYear(date.getFullYear(), date.getMonth(), 1);

    return firstDate;
};

/**
 * Возвращает последний день месяца переданной даты
 * @example 11.02.2019 -> 28.02.2019
 */
export const getLastMonthDay = (date: Date): Date => {
    const lastDate = new Date();
    lastDate.setFullYear(date.getFullYear(), date.getMonth() + 1, 0);

    return lastDate;
};
