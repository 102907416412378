import { IMPOSSIBLE_DATE } from 'core/const';
import { EventRecord, ProductForm, Seminar, Webinar } from 'site/graphql';
import { isImpossibleDate } from '../date';
import { orderBy } from '../fp';

export const orderByPrice = <T extends Pick<ProductForm, 'price'>>(productForms: T[]) =>
    orderBy((pf: T) => pf.price, 'desc')(productForms);

export const orderSeminars = <T extends Pick<Seminar, 'date'>>(seminars: T[]) =>
    orderBy((s: T) => s.date, 'asc')(seminars);

export const orderWebinars = <T extends Pick<Webinar, 'date'>>(webinars: T[]) =>
    orderBy((w: T) => w.date, 'asc')(webinars);

export const orderEventRecords = <T extends Pick<EventRecord, 'date'>>(eventRecords: T[]) =>
    orderBy((e: T) => (e.date === null ? IMPOSSIBLE_DATE : e.date), 'asc')(eventRecords);

export const orderEventRecordsDesc = <T extends Pick<EventRecord, 'date'>>(eventRecords: T[]) =>
    orderBy(
        (e: T) => (e.date === null || isImpossibleDate(e.date) ? '2000-01-01T03:00:00Z' : e.date),
        'desc',
    )(eventRecords);

export type IOrderBySeminarStartDayProductForm = { seminars: Pick<Seminar, 'date'>[] };
export const orderBySeminarStartDay = <T extends IOrderBySeminarStartDayProductForm>(productForms: T[]) =>
    orderBy((pf: T) => getFirstSeminar(pf.seminars).date, 'asc')(productForms);

export type IOrderByWebinarStartDayProductForm = { webinars: Pick<Webinar, 'date'>[] };
export const orderByWebinarStartDay = <T extends IOrderByWebinarStartDayProductForm>(productForms: T[]) =>
    orderBy((pf: T) => getFirstWebinar(pf.webinars).date, 'asc')(productForms);

export const orderDates = orderBy((d: string) => d, 'asc');

export const getFirstSeminar = <T extends Pick<Seminar, 'date'>>(seminars: T[]) => {
    const sorted = orderSeminars(seminars);
    return sorted[0];
};

export const getLastSeminar = <T extends Pick<Seminar, 'date'>>(seminars: T[]) => {
    const sorted = orderSeminars(seminars);
    return sorted[sorted.length - 1];
};

export const getFirstWebinar = <T extends Pick<Webinar, 'date'>>(webinars: T[]) => {
    const sorted = orderWebinars(webinars);
    return sorted[0];
};

export const getLastWebinar = <T extends Pick<Webinar, 'date'>>(webinars: T[]) => {
    const sorted = orderWebinars(webinars);
    return sorted[sorted.length - 1];
};
