import Modal from 'core/components/Modal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback } from 'react';
import PlaceMarketForm from './PlaceMarketForm';
import { useTinkoff } from './tinkoffContext';

type IPlaceMarketModalProps = {} & WithStyles<typeof styles>;

const PlaceMarketModal: React.FC<IPlaceMarketModalProps> = ({ classes }) => {
    const { isPlaceMarketModalOpen, ticker, amount, setIsPlaceMarketModalOpen, setTicker, setAmount } = useTinkoff();

    const close = useCallback(() => {
        setIsPlaceMarketModalOpen(false);
        setTicker('');
        setAmount(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            label="Форма выставления маркетной заявки"
            open={isPlaceMarketModalOpen}
            onClose={close}
            classes={classes}
        >
            <PlaceMarketForm
                amount={amount}
                ticker={ticker}
                onNext={() => {
                    close();
                }}
            />
        </Modal>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        content: {
            overflow: 'initial',
            width: '100vw',
            boxSizing: 'border-box',

            [theme.breakpoints.up('sm')]: {
                width: 'auto',
            },
        },
    });

export default injectSheet(styles)(PlaceMarketModal);
