import { button } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { CSSProperties, ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { useAuth } from '../AuthProvider';

type IAuthFormNavProps = {
    goToLogin(): void;
    goToSignUp(): void;
} & WithStyles<typeof styles>;

const AuthFormNav: React.FC<IAuthFormNavProps> = ({ classes, goToLogin, goToSignUp }) => {
    const { state } = useAuth();

    return (
        state !== 'success' && (
            <div className={classes.nav}>
                <a
                    className={state === 'login' ? classes.activeNavLink : classes.notActiveNavLink}
                    style={{ maxWidth: 50 + '%', flexGrow: 10 }}
                    onMouseDown={goToLogin}
                >
                    Вход
                </a>
                <a
                    className={state === 'signup' ? classes.activeNavLink : classes.notActiveNavLink}
                    onMouseDown={goToSignUp}
                >
                    Регистрация
                </a>
            </div>
        )
    );
};

const navLink = (theme: ITheme): CSSProperties => ({
    ...button(theme),
    flexGrow: 1,
    fontSize: 1.2 + 'rem',
    fontWeight: 700,
    padding: '6px 0',
    margin: '0 5px',
    backgroundColor: theme.colors.lightBg,
    transition: 'border-color 0.25s',
    borderBottom: '4px solid',

    [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        fontSize: '1rem',
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: 16,
    },
});

const styles = (theme: ITheme) =>
    createStyles({
        nav: {
            display: 'flex',
            padding: '0 1.5rem',
            margin: '0 1rem',
            [theme.breakpoints.down('sm')]: {
                height: 35,
            },
        },
        activeNavLink: {
            ...navLink(theme),
            borderBottomColor: theme.colors.accentMenu,
            color: theme.colors.accentMenu,
            '&:hover, &:active, &:focus': {
                color: theme.colors.accent,
                borderBottomColor: theme.colors.accent,
                textDecoration: 'none',
            },
        },
        notActiveNavLink: {
            ...navLink(theme),
            color: theme.colors.contrastBg,
            borderBottomColor: theme.colors.lightBg,
            '&:hover, &:active, &:focus': {
                borderBottomColor: theme.colors.contrastBg,
                borderBottom: '4px solid',
                textDecoration: 'none',
            },
        },
    });

export default injectSheet(styles, 'AuthFormNav')(AuthFormNav);
