import { LinksConsumer } from 'core/components/linksContext';
import { column, container, remCalc, row } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from 'site/components/social-icons/SocialIcons';

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            ...container(theme),
        },
        footer: {
            background: theme.colors.footerBg,
            padding: '46px 0',
            fontSize: 18,
        },
        footerWrapper: {
            ...row(),
        },
        footerColumn: {
            ...column(),
            textAlign: 'center',
            margin: '1rem 0',

            '&:first-child': {
                [theme.breakpoints.only('xs')]: {
                    order: 4,
                },
            },

            [theme.breakpoints.up('sm')]: {
                ...column({ cols: 3 }),
                textAlign: 'left',
            },

            color: theme.colors.footerText,
            fontSize: remCalc(14),
            lineHeight: 2,
        },
        footerHead: {
            fontWeight: 'normal',
            fontSize: remCalc(18),
            margin: 0,
            marginBottom: 10,
            color: theme.colors.footerText,
            lineHeight: 1.5,
        },
        footerLink: {
            display: 'block',
            fontSize: 14,
            color: theme.colors.footerText,

            '&:hover, &:active, &:focus': {
                color: theme.colors.footerTextHover,
            },
        },
        footerLinkNoUnderline: {
            display: 'block',
            fontSize: 14,
            color: theme.colors.footerText,
            textDecoration: 'none',

            '&:hover, &:active, &:focus': {
                color: theme.colors.footerTextHover,
            },
        },
        footerVersion: {
            display: 'block',
            fontSize: 14,
            color: theme.colors.footerText,
        },
        footerItem: {
            marginBottom: -21,
        },
    });

const Footer: React.StatelessComponent<WithStyles<typeof styles>> = ({ classes }) => (
    <LinksConsumer>
        {links => (
            <footer className={classes.footer}>
                <div className={classes.container}>
                    <div className={classes.footerWrapper}>
                        <div className={classes.footerColumn}>
                            <p className={classes.footerItem}>© Красный Циркуль, {new Date().getFullYear()} г.</p>
                            <br />
                            <a
                                className={classes.footerLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={links.personalAgreement.url()}
                            >
                                Cогласие на обработку данных
                            </a>
                            <a
                                className={classes.footerLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={links.userAgreement.url()}
                            >
                                Пользовательское соглашение
                            </a>
                            <span className={classes.footerVersion}>Версия: {VERSION}</span>
                            <span>
                                Разработано{' '}
                                <a
                                    className={classes.footerLink}
                                    style={{ display: 'inline' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={'https://codestetic.com'}
                                >
                                    CODESTETIC, LLC
                                </a>
                            </span>
                        </div>
                        <div className={classes.footerColumn}>
                            <h2 className={classes.footerHead}>Информация</h2>
                            <Link className={classes.footerLink} to={links.about.url()}>
                                О нас
                            </Link>
                            <Link className={classes.footerLink} to={links.teachers.url()}>
                                О преподавателях
                            </Link>
                            <Link className={classes.footerLink} to={links.otzyvy.url()}>
                                Отзывы
                            </Link>
                            <Link className={classes.footerLink} to={links.contacts.url()}>
                                Контакты
                            </Link>
                            <Link className={classes.footerLink} to={links.faq.url()} title="Помощь">
                                Помощь
                            </Link>
                        </div>

                        <div className={classes.footerColumn}>
                            <h2 className={classes.footerHead}>Обучение</h2>
                            <Link className={classes.footerLinkNoUnderline} to={links.courseList.url()}>
                                Курсы
                            </Link>
                            <Link className={classes.footerLinkNoUnderline} to={links.articles.url()}>
                                Статьи
                            </Link>
                            <Link className={classes.footerLinkNoUnderline} to={links.servicePage.url()}>
                                Сервисы инвестора
                            </Link>
                        </div>

                        <div className={classes.footerColumn}>
                            <h2 className={classes.footerHead}>Контакты</h2>
                            <a
                                className={classes.footerLink}
                                target="blank"
                                rel="noopener noreferrer"
                                href={'mailto:' + HELP_EMAIL}
                            >
                                {HELP_EMAIL}{' '}
                            </a>
                            <a className={classes.footerLink} href={'tel:' + PHONE_1.replace(/\s+/g, '')}>
                                {PHONE_1}{' '}
                            </a>
                            <SocialIcons mode="dark" />
                        </div>
                    </div>
                </div>
            </footer>
        )}
    </LinksConsumer>
);

export default injectSheet(styles)(Footer);
