import { postJson, resolveUrl, getQuery } from 'core/api/core';
import { detectIE } from 'core/utils';

type ICredentials = {
    email: string;
    password: string;
    notMyComp: boolean;
    isAdminPanel?: boolean;
};

/** Вход */
export const login = ({ email, password, notMyComp, isAdminPanel = false }: ICredentials) => {
    return postJson('api/account/login', {
        UserName: email,
        Password: password,
        RememberMe: !notMyComp,
        isAdminPanel,
    });
};

type ICreateOAuthAccountCodeContract = {
    redirectUri: string;
};

/** Создать авторизационный OAuth код */
export const createOAuthAccountCode = ({ redirectUri }: ICreateOAuthAccountCodeContract) => {
    return postJson<string>('api/account/CreateOAuthAccountCode', {
        RedirectUri: redirectUri,
    });
};

type ISignUpParams = {
    email: string;
    password: string;
    token: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    phone: string;
    redirectUrl?: string;
    contentProviderId?: number;
    needConfirm?: boolean;
    refCode?: string;
};

/** Регистрация */
export const signup = ({
    email,
    password,
    token,
    firstName,
    lastName,
    middleName,
    phone,
    redirectUrl,
    contentProviderId,
    refCode,
    needConfirm = false,
}: ISignUpParams) => {
    return postJson('api/account/register', {
        Email: email,
        Password: password,
        CaptchaToken: token,
        FirstName: firstName,
        LastName: lastName,
        MiddleName: middleName,
        Phone: phone,
        RedirectUrl: redirectUrl,
        ContentProviderId: contentProviderId,
        NeedConfirm: needConfirm,
        RefCode: refCode,
    });
};

/** Выход */
export const logout = () => {
    return postJson('api/account/logout');
};

/** Вход через соц сети */
export function externalLogin(provider: string, refCode?: string) {
    return new Promise((resolve, reject) => {
        // заводим новое окно
        const loginWindow = window.open(
            resolveUrl(
                `api/account/externallogin?` +
                    getQuery({
                        Provider: provider,
                        ReturnUrl: window.location.href,
                        RefCode: refCode,
                    }),
            ),
            'AuthWindow',
            'width=800, height=600',
        );

        if (!loginWindow) {
            reject({
                Result: '',
                status: 500,
                Errors: [],
            });
        }

        // запоминаем URL (без параметров)
        const currentUrl = window.location.origin + window.location.pathname;

        // следим за авторизацией, завершилась - закрываем окно (URL должны совпасть)
        const pollTimer = setInterval(() => {
            // если пользователь сам закрыл окно - ошибка
            if (!loginWindow || loginWindow.closed) {
                clearInterval(pollTimer);
                reject({
                    Result: '',
                    status: 500,
                    Errors: [],
                });
            }

            // мы не можем получить доступ к окну (document.URL) с другим происхождением
            // поэтому каждые 100 мс выдается Uncaught DOMException
            // но после успешного входа origin совпадает и мы получаем доступ к URL
            // подробнее: https://stackoverflow.com/questions/25098021/securityerror-blocked-a-frame-with-origin-from-accessing-a-cross-origin-frame
            try {
                if (loginWindow.location.origin + loginWindow.location.pathname === currentUrl) {
                    clearInterval(pollTimer);
                    // пользователи IE должны сами закрыть окно
                    if (detectIE() === false) loginWindow.close();
                    resolve({
                        Result: '',
                        status: 200,
                        Errors: [],
                    });
                }
            } catch (error) {
                // nothing happens
            }
        }, 100);
    });
}

/** Сброс пароля */
export function restorePassword(email: string) {
    return postJson('api/account/restorepassword', { Email: email });
}

/** Смена пароля */
export const resetPassword = ({ token, newPassword }) => {
    return postJson('api/account/resetpassword', {
        Token: token,
        NewPassword: newPassword,
    });
};
