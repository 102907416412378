import c from 'classnames';
import { useDeviceInfo } from 'core/components/DeviceInfoContext';
import { useLinks } from 'core/components/linksContext';
import useDropdown from 'core/hooks/useDropdown';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '../../../core/svg/arrow_left.svg';
import CalendarIcon from '../../svg/calendar.svg';
import ReferralIcon from '../../svg/cycle.svg';
import EmailIcon from '../../svg/email.svg';
import LogoutIcon from '../../svg/logout.svg';
import ContactsIcon from '../../svg/phone.svg';
import QuestionIcon from '../../svg/question-mark.svg';
import UserIcon from '../../svg/user.svg';
import AuthMenuMobileMainState from './AuthMenuMobileMainState';
import { headerHeight, mobileHeaderHeight } from './const';
import HeaderHamburger from './HeaderHamburger';

type IMenuItemLinkProps = {
    Icon: React.FunctionComponent<IIconProps>;
    title: string;
    linkTo: string;
};

type IMenuItemProps = {
    Icon: React.FunctionComponent<IIconProps>;
    title: string;
    click(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
    onlyMobile?: boolean;
};

type IAuthMenuDropDownProps = {
    onLogout(): void;
} & WithStyles<typeof styles>;

const AuthMenuDropDown: React.FC<IAuthMenuDropDownProps> = ({ classes, onLogout }) => {
    const [containerRef, isOpen, toggle, , close] = useDropdown();
    const [isMobileMenuMainState, setMobileMenuState] = useState(true);
    const links = useLinks();
    const { isSSR } = useDeviceInfo();
    const goToSecondaryState = useCallback(() => setMobileMenuState(false), []);
    const goToMainState = useCallback((event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (event) {
            event.preventDefault();
        }

        setMobileMenuState(true);
    }, []);
    const logout = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            close();
            goToMainState();
            onLogout();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [close, onLogout],
    );

    const MenuItemLink: React.FC<IMenuItemLinkProps> = ({ Icon, title, linkTo }) => (
        <div className={classes.item}>
            <Icon className={classes.itemIcon} />
            <Link to={linkTo} title={title} onClick={close}>
                {title}
            </Link>
        </div>
    );

    const MenuItem: React.FC<IMenuItemProps> = ({ Icon, title, click, onlyMobile = false }) => (
        <div className={c(classes.item, { [classes.hideIfDekstop]: onlyMobile })}>
            <Icon className={classes.itemIcon} />
            <a href="#" onClick={click}>
                {title}
            </a>
        </div>
    );

    const CommonMenu: React.FC = () => (
        <>
            <MenuItemLink Icon={UserIcon} title="Мой профиль" linkTo={`${links.account.url()}profile`} />
            <MenuItemLink Icon={CalendarIcon} title="Мои курсы" linkTo={`${links.account.url()}courses`} />
            <MenuItemLink
                Icon={EmailIcon}
                title="Мои подписки"
                linkTo={`${links.account.url()}subscriptions?tab=subscriptions`}
            />
            <MenuItemLink Icon={ReferralIcon} title="Реферальная программа" linkTo={`${links.account.url()}referral`} />
            <hr className={classes.divider} />
            <MenuItemLink Icon={QuestionIcon} title="Помощь" linkTo={links.faq.url()} />
            <MenuItemLink Icon={ContactsIcon} title="Контакты" linkTo={links.contacts.url()} />
            <MenuItem Icon={LogoutIcon} title="Выйти" click={logout} />
        </>
    );

    return (
        <>
            <div className={c(classes.container, { [classes.containerActive]: isOpen })} ref={containerRef}>
                {/* summary dekstop */}
                <div className={c(classes.summaryContainer, classes.hideIfMobile)} onClick={toggle}>
                    <span className={classes.summaryText}>Личный кабинет</span>
                    <ArrowLeftIcon className={classes.summaryIcon} />
                </div>
                {/* summary mobile */}
                <div className={classes.hideIfDekstop}>
                    <HeaderHamburger isOpen={isOpen} click={toggle} />
                </div>
                {/* details dekstop */}
                <div className={c(classes.detailsContainer, classes.hideIfMobile)} hidden={!isOpen}>
                    <CommonMenu />
                </div>
                {/* details mobile main state */}
                <div
                    className={c(classes.detailsContainer, classes.hideIfDekstop)}
                    hidden={!isOpen || !isMobileMenuMainState}
                >
                    <AuthMenuMobileMainState closeDetails={close} goToSecondaryState={goToSecondaryState} />
                </div>
                {/* details mobile secondary state */}
                <div
                    className={c(classes.detailsContainer, classes.hideIfDekstop)}
                    hidden={!isOpen || isMobileMenuMainState}
                >
                    <MenuItem Icon={ArrowLeftIcon} title="Вернуться в меню" click={goToMainState} onlyMobile />
                    <hr className={c(classes.divider, classes.hideIfDekstop)} />
                    <CommonMenu />
                </div>
            </div>

            {/* Портал необходим, чтобы Header перекрывал затемнение. Иначе этого не происходит, т.к. AuthMenuDropDown является потомком Header */}
            {!isSSR && ReactDOM.createPortal(<div className={classes.overflow} hidden={!isOpen} />, document.body)}
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        containerActive: {
            [theme.breakpoints.up('sm')]: {
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    borderBottom: `${theme.colors.accentMenu} 4px solid`,
                    width: 100 + '%',
                    left: 0,
                    bottom: 0,
                },

                '& $summaryText': {
                    color: theme.colors.accent,
                },

                '& $summaryIcon': {
                    fill: theme.colors.accent,
                },
            },
        },
        overflow: {
            zIndex: 19,
            position: 'fixed',
            height: 100 + 'vh',
            width: 100 + 'vw',
            left: 0,
            top: 0,
            background: 'rgba(30, 37, 47, 0.22)',
        },
        summaryContainer: {
            cursor: 'pointer',

            '&:hover, &:focus': {
                '& $summaryText': {
                    color: theme.colors.accent,
                },

                '& $summaryIcon': {
                    fill: theme.colors.accent,
                },
            },
        },
        summaryText: {
            color: theme.colors.textBase,
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginRight: 5,
        },
        summaryIcon: {
            width: 10,
            height: 10,
            fill: '#3f4647',
            fillOpacity: 0.5,
            transform: 'rotate(-90deg)',
        },
        detailsContainer: {
            zIndex: 20,
            position: 'absolute',
            padding: 1 + 'rem',
            right: 0,
            width: 270,
            top: headerHeight,
            background: '#fff',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0 7px 9px -7px rgba(0,0,0,0.4)',
            fontSize: 16,

            [theme.breakpoints.down('xs')]: {
                top: mobileHeaderHeight,
            },
        },
        item: {
            marginTop: 12,
            display: 'flex',
            alignItems: 'center',

            '&:first-child': {
                marginTop: 0,
            },

            '& a': {
                textDecoration: 'none',
                color: theme.colors.textBase,
            },

            '& a:hover': {
                textDecoration: 'underline',
            },

            [theme.breakpoints.down('xs')]: {
                marginTop: 24,
            },
        },
        itemIcon: {
            width: 20,
            height: 20,
            marginRight: 15,
            fill: theme.colors.textBase,
        },
        divider: {
            margin: '15px 0',
            border: 0,
            borderTop: `1px solid ${theme.colors.textBase}`,

            [theme.breakpoints.down('xs')]: {
                margin: '24px 0',
            },
        },
        hideIfMobile: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        hideIfDekstop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    });

export default injectSheet(styles)(AuthMenuDropDown);
