export function firstCharToUpperCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stringHash(str: string): number {
    let hash = 5381;
    let i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */

    return hash >>> 0;
}

export function priceToString(price: number) {
    return price.toLocaleString('ru', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 });
}

export const numEnding = (amount: number, endings: string[]) => {
    let ending = '';
    amount = Math.abs(amount) % 100;
    if (amount > 10 && amount < 20) {
        ending = endings[2];
    } else {
        amount = amount % 10;
        switch (amount) {
            case 1:
                ending = endings[0];
                break;
            case 2:
            case 3:
            case 4:
                ending = endings[1];
                break;
            default:
                ending = endings[2];
        }
    }

    return ending;
};

export const getCsvData = (fields: string[], data: object[]): string => {
    const csvString = data.map(d => fields.map(f => d[f]).join(',')).join('\n');
    const csvFile = new Blob(['\ufeff', csvString], { type: 'text/csv' });

    return window.URL.createObjectURL(csvFile);
};
