import c from 'classnames';
import React from 'react';
import { IAuthFormState } from '../AuthProvider';

type IAuthFormPasswordFieldProps = {
    state: IAuthFormState;
    password: string;
    passwordError: string;
    validatePassword: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    classes: Record<'field' | 'fieldInput' | 'fieldInputInvalid' | 'error', string>;
};

const AuthFormPasswordField: React.FC<IAuthFormPasswordFieldProps> = ({
    classes,
    onChange,
    password,
    passwordError,
    state,
    validatePassword,
}) => (
    <label className={classes.field}>
        <input
            type="password"
            name="password"
            autoComplete="current-password"
            className={c(classes.fieldInput, { [classes.fieldInputInvalid]: passwordError })}
            placeholder={state === 'login' ? 'Введите пароль' : 'Придумайте пароль'}
            value={password}
            onChange={onChange}
            onBlur={validatePassword}
        />
        <span className={classes.error}>{passwordError}</span>
    </label>
);

export default AuthFormPasswordField;
