import { IResponseError } from 'core/api/core';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { useAuth } from '../AuthProvider';
import SocialsLogin from '../SocialsLogin';
import { textCenter } from './AuthFormContent';

type IAuthFormSocialsProps = {
    showSocialLogin: boolean;
    setLoginErrors: (errors: IResponseError[]) => void;
} & WithStyles<typeof styles>;

const AuthFormSocials: React.FC<IAuthFormSocialsProps> = ({ classes, showSocialLogin, setLoginErrors }) => {
    const { state } = useAuth();

    return (
        state !== 'success' &&
        state !== 'restore' &&
        showSocialLogin && (
            <>
                <div className={classes.textBoldCenter}>или через</div>
                <div className={classes.socialLogin}>
                    <SocialsLogin setLoginErrors={setLoginErrors} />
                </div>
            </>
        )
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        textBoldCenter: {
            ...textCenter(theme),
            fontWeight: 700,
        },
        socialLogin: {
            ...textCenter(theme),
            marginBottom: '2rem',
        },
    });

export default injectSheet(styles, 'AuthFormSocials')(AuthFormSocials);
