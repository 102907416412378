import Modal from 'core/components/Modal';
import React from 'react';
import AuthForm from './auth-form/AuthForm';
import { useAuth } from './AuthProvider';

const AuthModal: React.FC = () => {
    const { openModal, closeAuthModal } = useAuth();

    return (
        <Modal label="Форма входа" open={openModal} onClose={closeAuthModal}>
            <AuthForm showRememberMe showSocialLogin />
        </Modal>
    );
};

export default AuthModal;
