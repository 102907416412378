import { ISettingsContext } from 'core/components/settingsContext';
import { ifBrowser } from 'core/utils';
import { postFile } from './core';

/** Создание изображения */
export const createPicture = (file: File) => postFile('api/picture/create', file);

/**
 * Все доступные размеры изображений
 *
 * @important При изменении синхронизировать с константой на сервере
 * `\src\Compass\Controllers\Pictures\PictureController.cs`
 */
export const PICTURE_SIZES = {
    '200x200': '200x200',
    '370x180': '370x180',
    '570x305': '570x305',
    '370x275': '370x275',
    '370x740': '370x740',
    '300x300': '300x300',
} as const;

/** Получить префикс для S3 контейнера по текущему контуру */
const getPrefix = (settings: ISettingsContext) => {
    // Клиент
    if (ifBrowser()) {
        // На сайте есть глобальная переменная с окружением
        if (window && window.__ENVIRONMENT__ && typeof window.__ENVIRONMENT__ === 'string') {
            return window.__ENVIRONMENT__ === 'Prod' ? '' : window.__ENVIRONMENT__.toLowerCase() + '-';
        }

        // Для админки окружение можно получить только из урла
        let origin = location.origin;

        // Для витрин окружение можем получить только из настроек
        if (settings && settings.endPoint) {
            origin = settings.endPoint;
        }

        if (origin.includes('test')) {
            return 'test-';
        }

        if (origin.includes('staging')) {
            return 'staging-';
        }

        // Для боевого контура не нужен префикс
        return '';
    }

    // SSR
    if (
        global &&
        global.APP_ENVIRONMENT &&
        typeof global.APP_ENVIRONMENT === 'string' &&
        global.APP_ENVIRONMENT !== 'Prod'
    ) {
        return global.APP_ENVIRONMENT.toLowerCase() + '-';
    }

    return '';
};

/**
 * Получить ссылку на изображение
 * @param id Идентификатор изображения
 * @param size Размер изображения
 * @param settings Контекст настроек. Необходим, если компонент используется на витрине, иначе можно передать `null`
 */
export function getPictureUrl(
    id: number | string,
    size: keyof typeof PICTURE_SIZES,
    settings: ISettingsContext,
): string {
    return `${LOCAL_SETTINGS.S3_ENDPOINT}/${getPrefix(settings)}${LOCAL_SETTINGS.S3_BUCKET}/${id}_${size}.jpg`;
}

/**
 * Получить ссылку на оригинал изображения
 * @param id Идентификатор изображения
 * @param settings Контекст настроек. Необходим, если компонент используется на витрине, иначе можно передать `null`
 */
export function getOriginalPictureUrl(id: number | string, settings: ISettingsContext): string {
    // Обрезает конец строки с "-resize"
    // Перенести в LOCAL_SETTINGS если будут проблемы
    const originalBucket = LOCAL_SETTINGS.S3_BUCKET.substring(0, LOCAL_SETTINGS.S3_BUCKET.length - 7);

    return `${LOCAL_SETTINGS.S3_ENDPOINT}/${getPrefix(settings)}${originalBucket}/${id}`;
}
