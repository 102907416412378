import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';

type ICustomCheckboxProps = {
    className?: string;
    checked: boolean;
    label: string | JSX.Element;
    onChange(checked: boolean): void;
    disabled?: boolean;
} & WithStyles<typeof styles>;

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({ classes, checked, label, onChange, className, disabled }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        onChange(checked);
    };

    return (
        <label
            className={c(classes.container, className, {
                [classes.containerChecked]: checked,
                [classes.containerDisabled]: disabled,
            })}
        >
            {label}
            <input type="checkbox" checked={checked} onChange={handleChange} disabled={disabled} />
            <span className={classes.checkmark} />
        </label>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            display: 'block',
            position: 'relative',
            paddingLeft: 32,
            marginBottom: 12,
            cursor: 'pointer',
            userSelect: 'none',
            fontSize: 0.9 + 'rem',

            '& input': {
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
            },

            '&:hover input:enabled ~ $checkmark, & input:focus ~ $checkmark': {
                backgroundColor: '#eee',
            },

            '& input:checked ~ $checkmark:after': {
                display: 'block',
            },

            '& $checkmark:after': {
                left: 5,
                top: 0,
                width: 4,
                height: 11,
                border: 'solid black',
                borderWidth: '0 3px 3px 0',
                transform: 'rotate(35deg)',
            },

            '&:last-child': {
                marginBottom: 0,
            },

            [theme.breakpoints.down('xs')]: {
                marginBottom: 6,
                marginTop: 6,
            },
        },
        checkmark: {
            position: 'absolute',
            top: 4,
            left: 0,
            height: 16,
            width: 16,
            border: '1px solid #666666',

            '&:after': {
                content: '""',
                position: 'absolute',
                display: 'none',
            },
        },
        containerChecked: {
            [theme.breakpoints.down('xs')]: {
                fontWeight: 600,
            },
        },
        containerDisabled: {
            color: '#999',
            cursor: 'default',

            '& input ~ $checkmark': {
                backgroundColor: '#ccc',
            },
        },
    });

export default injectSheet(styles)(CustomCheckbox);
