const inMemory = {
    pincode: '',
    timer: null,
    timeout: 20, // минут
    setPin(pin: string) {
        this.pincode = pin;
        this.startTimer();
    },
    resetPin() {
        this.pincode = '';
        if (this.timer) {
            clearTimeout(this.timer);
        }
    },
    startTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.resetPin();
            this.timer = null;
        }, 1000 * 60 * this.timeout);
    },
};

export default inMemory;
