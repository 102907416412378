import { ApolloError } from 'apollo-boost';
import { ServerError } from 'apollo-link-http-common';
import { GraphQLError } from 'graphql';

export type IApolloError = Pick<GraphQLError, 'message' | 'extensions'>;

export const getErrors = (error: ApolloError): IApolloError[] => {
    if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
        return (error.graphQLErrors as unknown) as IApolloError[];
    }

    if (
        error &&
        error.networkError &&
        (error.networkError as ServerError).result &&
        (error.networkError as ServerError).result.errors
    ) {
        return (error.networkError as ServerError).result.errors;
    }

    return [{ message: 'Необработанная ошибка', extensions: {} }];
};

export const getErrorData = (error: ApolloError) => (error.networkError as ServerError).result.data;
