import * as Sentry from '@sentry/browser';
import { gql } from 'apollo-boost';
import { logout as logoutApi } from 'core/api/auth';
import { useEffect } from 'react';
import { useGetMyAccountQuery } from 'site/graphql';

export const AccountSubscriptionFragment = gql`
    fragment AccountSubscriptionFragment on accountSubscription {
        id
        notPayedNotify
        courseStartNotify
        courseEndNotify
        mondayRegularSubscription
        thursdayRegularSubscription
        newCourseSubscription
        nearestCoursesSubscription
        weekCoursesSubscription
        subscriptionAutopayment
        courseSuccessRegistrationNotify
        ofzDisclaimerAgree
        welcomeEmails
        service52Weeks
    }
`;

gql`
    query GetMyAccount {
        myAccount {
            id
            email
            firstName
            lastName
            middleName
            phones
            vkLink
            facebookLink
			telegramUserName
            refLink
            refPoints
            hasTinkoffInvest
            accessToFreeForms
            accountSubscription {
                ...AccountSubscriptionFragment
            }
            subscription {
                autopayment
                until
                type
            }
            subscriptionFreeCourses {
                until
            }
            subscriptionCorporateBonds {
                until
            }
        }
    }
    ${AccountSubscriptionFragment}
`;

const useGetMyAccount = () => {
    // Запрос аккаунта пользователя
    const { client, loading, data, refetch } = useGetMyAccountQuery({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data && data.myAccount) {
                Sentry.configureScope(function (scope) {
                    scope.setUser({ id: `${data.myAccount.id}`, email: data.myAccount.email });
                });
            }
        },
    });

    const myAccount = data && data.myAccount;

    useEffect(() => {
        if (myAccount && window?.OneSignal?.sendTags) {
            window.OneSignal.sendTags({
                id: myAccount.id,
                email: myAccount.email,
            });
        }
    }, [myAccount]);

    // Перезапрос всех активных query
    // (отлавливаем ошибки, т.к. Apollo делает перезапрос в Promise.all и любой networkError(403, 404, ...)
    // выбрасывает ошибку с uncaught im promise error)
    // eslint-disable-next-line no-console
    const resetStore = () => client.resetStore().catch(error => console.log('Error reset store: ', error));

    // Выход
    const logout = () => logoutApi().then(resetStore);

    return [myAccount, loading, refetch, logout, resetStore] as const;
};

export default useGetMyAccount;
