import { ifBrowser } from 'core/utils';
import React, { useCallback, useEffect, useState } from 'react';

const TABLET_WIDTH = 768;
const DESKTOP_WIDTH = 1200;

const calculateDeviceInfo = (): IDeviceInfoContextContext => {
    if (!ifBrowser()) {
        return {
            isMobile: false,
            isTablet: false,
            isDesktop: false,
            isSSR: true,
            device: 'ssr',
        };
    }

    const width = window.innerWidth;

    if (width <= TABLET_WIDTH) {
        return {
            isMobile: true,
            isTablet: false,
            isDesktop: false,
            isSSR: false,
            device: 'mobile',
        };
    }

    if (width <= DESKTOP_WIDTH) {
        return {
            isMobile: false,
            isTablet: true,
            isDesktop: false,
            isSSR: false,
            device: 'tablet',
        };
    }

    return {
        isMobile: false,
        isTablet: false,
        isDesktop: true,
        isSSR: false,
        device: 'desktop',
    };
};

type IDeviceInfoContextContext = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isSSR: boolean;
    device: 'mobile' | 'tablet' | 'desktop' | 'ssr';
};

const DeviceInfoContext = React.createContext<IDeviceInfoContextContext>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isSSR: true,
    device: 'ssr',
});

const DeviceInfoProvider: React.FC = ({ children }) => {
    const [deviceInfo, setDeviceInfo] = useState(calculateDeviceInfo());

    const onResize = useCallback(() => {
        const newDeviceInfo = calculateDeviceInfo();

        if (deviceInfo.device !== newDeviceInfo.device) {
            setDeviceInfo(newDeviceInfo);
        }
    }, [deviceInfo]);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [deviceInfo, onResize]);

    return <DeviceInfoContext.Provider value={deviceInfo}>{children}</DeviceInfoContext.Provider>;
};

const useDeviceInfo = () => React.useContext(DeviceInfoContext);

export { DeviceInfoProvider, useDeviceInfo };
