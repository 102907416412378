import { ApolloError, gql } from 'apollo-boost';
import c from 'classnames';
import CenterLoader from 'core/components/common/CenterLoader';
import { showSnackbar } from 'core/components/snackbar';
import { button, buttonAccent, field, fieldInput, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { getErrors } from 'core/utils/apollo';
import React, { useCallback, useState } from 'react';
import { useAuth } from 'site/components/auth/AuthProvider';
import {
    AccountPublicUpdateInput,
    GetPayProductFormQuery,
    useAgreementProductOfferMutation,
    useUpdateMyAccountMutation,
} from 'site/graphql';
import CustomCheckbox from '../../../core/components/CustomCheckbox';
import { phoneRegex } from '../const';
import checkAccountRequiredFields, {
    checkRequiredField,
    ProductFormRequiredFields,
} from './checkAccountRequiredFields';

gql`
    mutation AgreementProductOffer($productFormId: Int!) {
        agreementProductOffer(productFormId: $productFormId) {
            id
            hasProductAgreement
        }
    }
`;

type IProfileFieldsFormProps = {
    productForm: GetPayProductFormQuery['productForm'];
} & WithStyles<typeof styles>;

const ProfileFieldsForm: React.FC<IProfileFieldsFormProps> = ({ classes, productForm }) => {
    const { myAccount } = useAuth();
    const [account, setAccount] = useState(myAccount);
    const [checkOffer, setCheckOffer] = useState(false);
    const { id, product, hasProductAgreement, requiredFieldsNumber } = productForm;

    const [updateAccount, { loading }] = useUpdateMyAccountMutation({
        onCompleted: () => showSnackbar({ snackbarMessage: 'Изменения сохранены' }),
        onError: (error: ApolloError) => showSnackbar({ snackbarErrors: getErrors(error) }),
    });

    const handleChange = useCallback(
        (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setAccount(acc => ({ ...acc, [fieldName]: value }));
        },
        [setAccount],
    );

    const [agreementProductOffer] = useAgreementProductOfferMutation();

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            updateAccount({
                variables: {
                    account: {
                        firstName: account.firstName,
                        lastName: account.lastName,
                        middleName: account.middleName,
                        phones: account.phones,
                        telegramUserName: account.telegramUserName,
                        vkLink: account.vkLink,
                        facebookLink: account.facebookLink,
                    },
                },
            });

            if (
                !!product.offer &&
                checkRequiredField(ProductFormRequiredFields.offer, requiredFieldsNumber) &&
                !hasProductAgreement
            ) {
                agreementProductOffer({ variables: { productFormId: id } });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, updateAccount, product, checkRequiredField, requiredFieldsNumber, hasProductAgreement],
    );

    const renderInput = (label: string, fieldName: keyof AccountPublicUpdateInput, autoComplete: string) => {
        const isError = fieldName === 'phones' ? !phoneRegex.test(account.phones) : !account[fieldName];

        return (
            <label className={classes.label}>
                {label}
                <input
                    type="text"
                    onChange={handleChange(fieldName)}
                    value={account[fieldName] || ''}
                    autoComplete={autoComplete}
                    className={c(classes.input, {
                        [classes.inputError]: isError,
                    })}
                />
            </label>
        );
    };

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Пожалуйста заполните поля</h2>
            <form className={classes.form} onSubmit={handleSubmit}>
                {checkRequiredField(ProductFormRequiredFields.lastName, requiredFieldsNumber) &&
                    renderInput('Фамилия', 'lastName', 'family-name')}
                {checkRequiredField(ProductFormRequiredFields.firstName, requiredFieldsNumber) &&
                    renderInput('Имя', 'firstName', 'given-name')}
                {checkRequiredField(ProductFormRequiredFields.middleName, requiredFieldsNumber) &&
                    renderInput('Отчество', 'middleName', 'additional-name')}
                {checkRequiredField(ProductFormRequiredFields.phone, requiredFieldsNumber) &&
                    renderInput('Телефон', 'phones', 'tel')}
                {checkRequiredField(ProductFormRequiredFields.telegramUserName, requiredFieldsNumber) &&
                    renderInput('Telegram username без @', 'telegramUserName', '')}
                {!!product.offer && checkRequiredField(ProductFormRequiredFields.offer, requiredFieldsNumber) && (
                    <CustomCheckbox
                        checked={checkOffer}
                        className={classes.checkbox}
                        onChange={setCheckOffer}
                        label={
                            <span>
                                Подтверждаю своё согласие на обратную связь в соответствии с&nbsp;
                                <a href={product.offer} rel="noopener noreferrer" target="_blank">
                                    Положением о работе с персональными данными
                                </a>
                            </span>
                        }
                    />
                )}
                {loading ? (
                    <CenterLoader absolute />
                ) : (
                    <button
                        type="submit"
                        className={classes.submit}
                        disabled={
                            !checkAccountRequiredFields(
                                { ...myAccount, ...account },
                                requiredFieldsNumber,
                                checkOffer,
                                product.offer,
                            )
                        }
                    >
                        Присоединиться
                    </button>
                )}
            </form>
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: 1 + 'rem',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)',
                height: 'calc(100vh - 2rem)',
                justifyContent: 'center',
            },
        },
        title: {
            ...title({ size: 'h3' }),
            textAlign: 'center',
            fontSize: 1.2 + 'rem',
            marginTop: 1 + 'rem',
            marginBottom: 1 + 'rem',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        submit: {
            ...button(theme),
            ...buttonAccent(theme),
            marginTop: 1 + 'rem',

            '&:disabled': {
                backgroundColor: '#808080',
                cursor: 'not-allowed',
            },
        },
        label: {
            ...field,
            marginBottom: 1 + 'rem',
        },
        input: {
            ...fieldInput,
            width: 350,

            [theme.breakpoints.down('xs')]: {
                width: 'calc(100% - 2rem)',
            },
        },
        checkbox: {
            maxWidth: 350,
        },
        inputError: {
            border: `1px solid ${theme.colors.accent}`,
        },
    });

export default injectSheet(styles, 'ProfileFieldsForm')(ProfileFieldsForm);
