import Modal from 'core/components/Modal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback } from 'react';
import PinForm from './PinForm';
import { useTinkoff } from './tinkoffContext';

type IPinModalProps = {} & WithStyles<typeof styles>;

const PinModal: React.FC<IPinModalProps> = ({ classes }) => {
    const { isPinModalOpen, next, setIsPinModalOpen, setNext } = useTinkoff();

    const close = useCallback(() => {
        setIsPinModalOpen(false);
        setNext(() => () => null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal label="Введите пинкод" open={isPinModalOpen} onClose={close} classes={classes} zIndex={101}>
            <PinForm
                onNext={() => {
                    close();
                    next();
                }}
                onAttachClick={() => {
                    close();
                }}
            />
        </Modal>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        content: {
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
            },
        },
    });

export default injectSheet(styles)(PinModal);
