import c from 'classnames';
import EmailWithHint from 'core/components/common/EmailWithHint';
import React from 'react';

type IAuthFormEmailFieldProps = {
    email: string;
    emailError: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    validateEmail: () => boolean;
    classes: Record<'field' | 'fieldInput' | 'fieldInputInvalid' | 'error', string>;
};

const AuthFormEmailField: React.FC<IAuthFormEmailFieldProps> = ({
    classes,
    email,
    emailError,
    onChange,
    validateEmail,
}) => (
    <EmailWithHint
        value={email}
        error={emailError}
        labelClassName={classes.field}
        inputClassName={c(classes.fieldInput, { [classes.fieldInputInvalid]: emailError })}
        errorClassName={classes.error}
        onChange={onChange}
        onBlur={validateEmail}
    />
);

export default AuthFormEmailField;
