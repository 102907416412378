import React from 'react';
import { IPaymentType } from 'site/components/payments/PaymentSuccess';

export const linkContext = React.createContext<ILinksContext>({});

const { Provider, Consumer } = linkContext;

export const LinksProvider = Provider;
export const LinksConsumer = Consumer;

export type ILinksContext = Partial<{
    home: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    courseList: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    productDetail: {
        url: (id) => string;
        Link: React.ComponentType<any>;
    };
    accountProductDetail: {
        url: (id) => string;
        Link: React.ComponentType<any>;
    };
    accountCourses: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    accountInvestment: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    account: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    brokers: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    contacts: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    articles: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    articleDetail: {
        url: (slug) => string;
        Link: React.ComponentType<any>;
    };
    otzyvy: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    faq: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    ofzFaq: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    about: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    teachers: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    teacherDetail: {
        url: (id) => string;
        Link: React.ComponentType<any>;
    };
    resetPassword: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    userAgreement: {
        url: () => string;
    };
    personalAgreement: {
        url: () => string;
    };
    pageDetail: {
        url: (slug: string) => string;
    };
    ofz: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    ofzDisclaimer: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    ofzConnectTinkoff: {
        url: (to?: string) => string;
        Link: React.ComponentType<any>;
    };
    attachTinkoffDisclaimer: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    heatmap: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    expcalendar: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    oauth: {
        url: () => string;
    };
    highLow: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    dividendTable: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    dividendDetail: {
        url: (symbol: string) => string;
        Link: React.ComponentType<any>;
    };
    evolution: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    servicePage: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    search: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    comRegion: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    financialCalculators: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    service52weeks: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    serviceMarketMap: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    roboadviser: {
        url: () => string;
        Link: React.ComponentType<any>;
    };
    paymentSuccess: {
        url: (paymentType: IPaymentType) => string;
        Link: React.ComponentType<any>;
    };
}>;

/** Usage: `const links = useLinks();` */
export const useLinks = () => React.useContext<ILinksContext>(linkContext);
