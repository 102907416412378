import Modal from 'core/components/Modal';
import React, { useCallback } from 'react';
import { useAuth } from 'site/components/auth/AuthProvider';
import RegPayForm from './RegPayForm';

const RegPayModal: React.FC = () => {
    const { payProductFormId, setPayProductFormId } = useAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closePayModal = useCallback(() => setPayProductFormId(null), []);

    return (
        <Modal label="" open={payProductFormId !== null} onClose={closePayModal}>
            <RegPayForm />
        </Modal>
    );
};

export default RegPayModal;
