import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: string;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: string;
  Decimal: number;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
};

/** Аккаунт пользователя */
export type Account = {
  __typename?: 'account';
  /** Имя */
  firstName?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Фамилия */
  lastName?: Maybe<Scalars['String']>;
  /** Отчество */
  middleName?: Maybe<Scalars['String']>;
  /** Изображение */
  picture?: Maybe<Picture>;
  /** Изображение */
  pictureId?: Maybe<Scalars['Int']>;
};

export type AccountListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<AccountOrderEnum>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
  telegramUserName?: Maybe<Scalars['String']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  isAuthor?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  refAccountId?: Maybe<Scalars['Int']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  autopayment?: Maybe<Scalars['Boolean']>;
  socLinkType?: Maybe<AccountSocLinksTypeEnum>;
  regCount?: Maybe<Scalars['Int']>;
  ordersCount?: Maybe<Scalars['Int']>;
  hasRefAccount?: Maybe<Scalars['Boolean']>;
  needCallback?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

/** Order the list of account */
export enum AccountOrderEnum {
  Id = 'ID',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  RegCount = 'REG_COUNT',
  PaidCount = 'PAID_COUNT',
  PaidSum = 'PAID_SUM',
  RefCount = 'REF_COUNT',
  RefTotalAccrued = 'REF_TOTAL_ACCRUED',
  RefTotalWrittenOff = 'REF_TOTAL_WRITTEN_OFF',
  RefBalance = 'REF_BALANCE',
  RefOwner = 'REF_OWNER',
  SaleCallback = 'SALE_CALLBACK',
  RefTotalPaidByReferrals = 'REF_TOTAL_PAID_BY_REFERRALS',
  TelegramUsername = 'TELEGRAM_USERNAME'
}

export type AccountPublicUpdateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
  telegramUserName?: Maybe<Scalars['String']>;
  pictureId?: Maybe<Scalars['Int']>;
  vkLink?: Maybe<Scalars['String']>;
  facebookLink?: Maybe<Scalars['String']>;
  accountSubscription?: Maybe<AccountSubscriptionPublicUpdateInput>;
};

/** Фильтр по заполненным ссылкам на соц сети аккаунтов */
export enum AccountSocLinksTypeEnum {
  Vk = 'VK',
  Facebook = 'FACEBOOK',
  Telegram = 'TELEGRAM'
}

/** Подписки пользователя */
export type AccountSubscription = {
  __typename?: 'accountSubscription';
  /** Аккаунт пользователя */
  account?: Maybe<Account>;
  /** Уведомления о том, что курс закончился */
  courseEndNotify: Scalars['Boolean'];
  /** Уведомления о том, что курс близко */
  courseStartNotify: Scalars['Boolean'];
  /** Уведомления об успешной регистрации на курс */
  courseSuccessRegistrationNotify: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Регулярная рассылка по понедельникам */
  mondayRegularSubscription: Scalars['Boolean'];
  /** Подписка о курсах на сегодня и завтра */
  nearestCoursesSubscription: Scalars['Boolean'];
  /** Подписка о новом курсе в системе */
  newCourseSubscription: Scalars['Boolean'];
  /** Нотификации о необходимости оплатить курс */
  notPayedNotify: Scalars['Boolean'];
  /** Согласие с условиями сервиса BondsPortfolio */
  ofzDisclaimerAgree: Scalars['Boolean'];
  /** Уведомления в сервисе 52 недели богатства */
  service52Weeks: Scalars['Boolean'];
  /** Автоплатеж за подписку */
  subscriptionAutopayment: Scalars['Boolean'];
  /** Регулярная рассылка по четвергам */
  thursdayRegularSubscription: Scalars['Boolean'];
  /** Подписка о курсах на неделю */
  weekCoursesSubscription: Scalars['Boolean'];
  /** Цепочка приветственных писем при регистрации */
  welcomeEmails: Scalars['Boolean'];
};

export type AccountSubscriptionPublicUpdateInput = {
  notPayedNotify: Scalars['Boolean'];
  courseStartNotify: Scalars['Boolean'];
  courseEndNotify: Scalars['Boolean'];
  mondayRegularSubscription: Scalars['Boolean'];
  thursdayRegularSubscription: Scalars['Boolean'];
  newCourseSubscription: Scalars['Boolean'];
  nearestCoursesSubscription: Scalars['Boolean'];
  weekCoursesSubscription: Scalars['Boolean'];
  subscriptionAutopayment: Scalars['Boolean'];
  courseSuccessRegistrationNotify: Scalars['Boolean'];
  ofzDisclaimerAgree: Scalars['Boolean'];
  welcomeEmails: Scalars['Boolean'];
  service52Weeks: Scalars['Boolean'];
};

export type ActiveSubscriptionPublicType = {
  __typename?: 'ActiveSubscriptionPublicType';
  /** Включен ли автоплатеж? */
  autopayment: Scalars['Boolean'];
  /** Тип подписки */
  type?: Maybe<SubscriptionPublicTypeEnum>;
  /** Дата до которой действует подписка */
  until?: Maybe<Scalars['DateTime']>;
};

/** Статья */
export type Article = {
  __typename?: 'article';
  /** Аккаунт автора */
  account?: Maybe<Account>;
  /** ID автора статьи */
  accountId: Scalars['Int'];
  /** Описание */
  content: Scalars['String'];
  /** Дата создания */
  date?: Maybe<Scalars['DateTime']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Опубликовано */
  isVisible: Scalars['Boolean'];
  /** Количество просмотров страницы со статьей */
  pageViews: Scalars['Int'];
  /** ID большого изображения */
  pictureBigId: Scalars['Int'];
  /** ID маленького изображения */
  pictureSmallId?: Maybe<Scalars['Int']>;
  /** SEO статьи */
  seo?: Maybe<SeoPageInfo>;
  /** Краткое описание */
  shortDescription?: Maybe<Scalars['String']>;
  /** Slug */
  slug: Scalars['String'];
  /** Рекомендованные статьи */
  suggestedArticles?: Maybe<Array<Maybe<Article>>>;
  /** Рекомендованный продукт */
  suggestedProduct?: Maybe<Product>;
  /** ID рекомендованного продукта */
  suggestedProductId?: Maybe<Scalars['Int']>;
  /** Теги массивом строк */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Заголовок */
  title: Scalars['String'];
};

export type ArticleListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<ArticleOrderEnum>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  authors?: Maybe<Array<Maybe<Scalars['Int']>>>;
  slugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageViews?: Maybe<Scalars['Int']>;
  notArticleId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

/** Order the list of articles */
export enum ArticleOrderEnum {
  Id = 'ID',
  Title = 'TITLE',
  Slug = 'SLUG',
  Visible = 'VISIBLE',
  Date = 'DATE',
  PageViews = 'PAGE_VIEWS'
}

/** Облигация */
export type BpBond = {
  __typename?: 'bpBond';
  couponDate?: Maybe<Scalars['DateTime']>;
  couponFrequency?: Maybe<Scalars['Int']>;
  couponPercent?: Maybe<Scalars['Decimal']>;
  couponPeriod?: Maybe<Scalars['Int']>;
  couponType?: Maybe<BpCouponTypePublicEnum>;
  couponValue?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['String']>;
  daysToMaturity?: Maybe<Scalars['Int']>;
  faceValue?: Maybe<Scalars['Decimal']>;
  initialFaceValue?: Maybe<Scalars['Decimal']>;
  isin?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issueSize?: Maybe<Scalars['Decimal']>;
  maturityDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  nkd?: Maybe<Scalars['Decimal']>;
  offerDate?: Maybe<Scalars['DateTime']>;
  offerDateEnd?: Maybe<Scalars['DateTime']>;
  offerDateStart?: Maybe<Scalars['DateTime']>;
  prevPrice?: Maybe<Scalars['Decimal']>;
  regNumber?: Maybe<Scalars['String']>;
  secId?: Maybe<Scalars['String']>;
  secSubtype?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['String']>;
  yield?: Maybe<Scalars['Decimal']>;
};

/** Сортировка облигаций при сборке портфеля */
export enum BpBondsOrderPublicEnum {
  Liquidity = 'LIQUIDITY',
  PaymentsCount = 'PAYMENTS_COUNT',
  Yield = 'YIELD',
  MaturityDate = 'MATURITY_DATE'
}

/** Тип купона облигации, постоянный или переменный */
export enum BpCouponTypePublicEnum {
  Undef = 'UNDEF',
  Fixed = 'FIXED',
  Floating = 'FLOATING'
}

export type BpPayment = {
  __typename?: 'bpPayment';
  amount: Scalars['Decimal'];
  date?: Maybe<Scalars['DateTime']>;
  number: Scalars['Int'];
  position?: Maybe<BpPosition>;
};

/** Календарь платежей по портфелю облигаций */
export type BpPaymentsCalendar = {
  __typename?: 'bpPaymentsCalendar';
  payments?: Maybe<Array<Maybe<BpPayment>>>;
};

/** Результат расчётов, портфель, который нужно собрать */
export type BpPortfolio = {
  __typename?: 'bpPortfolio';
  calendar?: Maybe<BpPaymentsCalendar>;
  nominal: Scalars['Decimal'];
  positions?: Maybe<Array<Maybe<BpPosition>>>;
  yieldToMaturity: Scalars['Decimal'];
};

/** Тип суммы при сборе портфеля */
export enum BpPortfolioAmountTypePublicEnum {
  CouponPerMonth = 'COUPON_PER_MONTH',
  AmountOfInvestment = 'AMOUNT_OF_INVESTMENT'
}

/** Тип облигаций портфеля */
export enum BpPortfolioBondsTypePublicEnum {
  Ofz = 'OFZ',
  Corporate = 'CORPORATE',
  Mixed = 'MIXED'
}

/** Позиция */
export type BpPosition = {
  __typename?: 'bpPosition';
  bond?: Maybe<BpBond>;
  calendar?: Maybe<BpPaymentsCalendar>;
  /** Nominal */
  nominal: Scalars['Decimal'];
  /** Количество */
  quantity: Scalars['Int'];
};

/** Брокер */
export type Broker = {
  __typename?: 'broker';
  /** Ссылка на подробности */
  about?: Maybe<Scalars['String']>;
  /** Комиссия */
  commission?: Maybe<Scalars['String']>;
  /** Особенности */
  features?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Ссылка */
  link?: Maybe<Scalars['String']>;
  /** Минимум для открытия */
  minimum?: Maybe<Scalars['String']>;
  /** Название */
  name: Scalars['String'];
  /** Изображение */
  picture?: Maybe<Picture>;
  /** Идентификатор изображения */
  pictureId?: Maybe<Scalars['Int']>;
  /** Позиция в выводе */
  position: Scalars['Int'];
  /** Предложение */
  sentence?: Maybe<Scalars['String']>;
};

export type BrokerListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<BrokerOrderEnum>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Order the list of brokers */
export enum BrokerOrderEnum {
  Id = 'ID',
  Name = 'NAME',
  Visible = 'VISIBLE',
  Position = 'POSITION'
}

/** Параметры для сбора портфеля */
export type BuildPortfolioRequest = {
  /** Сумма */
  amount: Scalars['Decimal'];
  /** Тип облигаций портфеля */
  type?: Maybe<BpPortfolioBondsTypePublicEnum>;
  /** Тип суммы при сборе портфеля */
  amountType?: Maybe<BpPortfolioAmountTypePublicEnum>;
  /** Сортировка облигаций при сборке портфеля */
  order?: Maybe<BpBondsOrderPublicEnum>;
  /** Облигации без оферты */
  withoutOffer: Scalars['Boolean'];
  /** Каждая облигация от уникального эмитента */
  differentEmitter: Scalars['Boolean'];
  /** Облигации без субордов */
  withoutSubboards: Scalars['Boolean'];
  /** Исключаемые из сборки облигации */
  excludeBonds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Отбор по дате погашения последней облигации */
  maturityDate?: Maybe<Scalars['Date']>;
  /** Включен ли строгий поиск облигаций до выбранной даты погашения */
  strictlyUpToDate?: Maybe<Scalars['Boolean']>;
};

/** Сертификат */
export type Certificate = {
  __typename?: 'certificate';
  /** Дата окончания (который завершился [DateEnd]) */
  dateEnd?: Maybe<Scalars['DateTime']>;
  /** Продолжительность (Продолжительностью [Duration]) */
  duration: Scalars['String'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Сертификат пользователя (null если отсутствует) */
  myCertificate?: Maybe<CertificateToAccount>;
  /** Название (Прослушал курс [Name]) */
  name: Scalars['String'];
  /** Форма продукта */
  productForm: ProductForm;
  /** Состояние сертификата пользователя */
  state?: Maybe<CertificatePublicStateEnum>;
};

export type CertificateListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<CertificateOrderEnum>;
  name?: Maybe<Scalars['String']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

/** Order the list of certificates */
export enum CertificateOrderEnum {
  Id = 'ID'
}

/** Состояние сертификата */
export enum CertificatePublicStateEnum {
  NotAvaliable = 'NOT_AVALIABLE',
  CanPay = 'CAN_PAY',
  Await = 'AWAIT',
  CanDownload = 'CAN_DOWNLOAD',
  NeedCondidtions = 'NEED_CONDIDTIONS',
  CertificateNotFound = 'CERTIFICATE_NOT_FOUND',
  OrderNotFound = 'ORDER_NOT_FOUND',
  OrderNotPurchased = 'ORDER_NOT_PURCHASED',
  PaymentNotFound = 'PAYMENT_NOT_FOUND',
  PaymentNotSuccess = 'PAYMENT_NOT_SUCCESS'
}

/** Сертификаты пользователя */
export type CertificateToAccount = {
  __typename?: 'certificateToAccount';
  /** Пользователь */
  account: Account;
  /** Идентификатор пользователя */
  accountId: Scalars['Int'];
  /** Сертификат */
  certificate: Certificate;
  /** Идентификатор сертификата */
  certificateId: Scalars['Int'];
  /** Идентификатор CertificateToAccount */
  id: Scalars['String'];
  /** Уникальный номер сертификата */
  number: Scalars['String'];
};

/** Провайдер контента */
export type ContentProvider = {
  __typename?: 'contentProvider';
  /** Код */
  code: Scalars['String'];
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Почта */
  email?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Каких преподавателей отображает данный провайдер на своей витрине */
  lecturers?: Maybe<Array<Maybe<Lecturer>>>;
  /** Название */
  name?: Maybe<Scalars['String']>;
  /** Ссылка на файл оферты */
  offer: Scalars['String'];
  /** Контактное лицо */
  person?: Maybe<Scalars['String']>;
  /** Телефон */
  phone?: Maybe<Scalars['String']>;
  /** Сайт */
  site?: Maybe<Scalars['String']>;
};

export type ContentProviderListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<ContentProviderOrderEnum>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Order the list of content provider */
export enum ContentProviderOrderEnum {
  Id = 'ID',
  Name = 'NAME'
}

/** Валюта */
export enum CurrencyEnum {
  Rub = 'RUB',
  Usd = 'USD'
}





export type DividendCandle = {
  __typename?: 'dividendCandle';
  close: Scalars['Decimal'];
  high: Scalars['Decimal'];
  low: Scalars['Decimal'];
  open: Scalars['Decimal'];
  time: Scalars['Date'];
};

export type DividendHistory = {
  __typename?: 'dividendHistory';
  /** Дивиденд (₽) */
  dividend: Scalars['Decimal'];
  /** Закрылся ли див. гэп */
  gapClosed: Scalars['Boolean'];
  /** Размер див. гэпа (%) */
  gapDawn?: Maybe<Scalars['Decimal']>;
  /** Последний день, чтобы купить */
  lastDayToBuy: Scalars['Date'];
  /** Выплачен или нет */
  paid: Scalars['Boolean'];
  /** Доходность на момент выплаты (%) */
  paymentYield: Scalars['Decimal'];
  /** За какой период работы был выплачен дивиденд */
  period: Scalars['String'];
  /** Дата закрытия реестра */
  registryClose: Scalars['Date'];
  /** Срок закрытия див. гэпа (день) */
  timeGap: Scalars['Int'];
  /** Текущая доходность (₽) */
  yield: Scalars['Decimal'];
};

export type DividendShare = {
  __typename?: 'dividendShare';
  /** Свечи */
  candles: Array<DividendCandle>;
  /** Количество див. выплат за год */
  dividendsCountPerYear: Scalars['Int'];
  /** Див. дох-сть */
  dividendYield: Scalars['Decimal'];
  /** Закрытие гэпа (день) */
  gapClosing: Scalars['Decimal'];
  /** Дивидендная история */
  history?: Maybe<Array<DividendHistory>>;
  /** Ошибка при получении истории от Conomy */
  historyErrorMessage?: Maybe<Scalars['String']>;
  /** Сколько лет подряд повышает дивиденд */
  increaseYears: Scalars['Int'];
  /** Последний день для покупки */
  lastDayToBuy: Scalars['Date'];
  /** Последний див. гэп (день) */
  lastDividendGap: Scalars['Int'];
  /** Закрылся ли последний див. гэп */
  lastDividendGapClosed: Scalars['Boolean'];
  /** Дата последнего обновления данных */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** Индекс голубых фишек Московской Биржи */
  moscowExchangeBlueChipIndex: Scalars['Boolean'];
  /** Индекс МосБиржи */
  moscowExchangesIndex: Scalars['Boolean'];
  /** Название */
  name: Scalars['String'];
  /** Ближайшая дивидендная выплата (₽) */
  nearestDividendPayout: Scalars['Decimal'];
  /** Сколько лет подряд платит дивиденд */
  payYears: Scalars['Int'];
  /** Цена */
  price: Scalars['Decimal'];
  /** Данные для отрисовки графика минимума и максимума */
  security: HighLowSecurity;
  /** Потенциальная стоимость акции (₽) */
  target: Scalars['Decimal'];
  /** Тикер */
  ticker: Scalars['String'];
  /** Доходность за 12 месяцев (%) */
  yearYield: Scalars['Decimal'];
};

/** Запись события */
export type EventRecord = {
  __typename?: 'eventRecord';
  /** Дата записи */
  date?: Maybe<Scalars['DateTime']>;
  /** Описание */
  description: Scalars['String'];
  /** Продолжительность */
  hours: Scalars['Seconds'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Был ли этот форм итем просмотрен ранее? */
  isViewed?: Maybe<Scalars['Boolean']>;
  /** Ссылка на скачивание/просмотр */
  link?: Maybe<Scalars['String']>;
  /** Идентификатор изображения */
  pictureId?: Maybe<Scalars['Int']>;
  /** Форма продукта */
  productForm?: Maybe<ProductForm>;
  /** Запись какого семинара */
  seminar?: Maybe<Seminar>;
  /** Заголовок */
  title: Scalars['String'];
  /** Запись какого вебинара */
  webinar?: Maybe<Webinar>;
};

export type EventRecordListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<EventRecordOrderEnum>;
  title?: Maybe<Scalars['String']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  hasDate?: Maybe<Scalars['Boolean']>;
};

/** Order the list of event records */
export enum EventRecordOrderEnum {
  Id = 'ID',
  Date = 'DATE'
}

/** Расширенный заказ */
export type ExtendedOrder = {
  __typename?: 'extendedOrder';
  /** Доступ до */
  accessUntil?: Maybe<Scalars['Date']>;
  /** Покупатель */
  account: Account;
  /** Дата вебинара или семинара */
  courseDate?: Maybe<Scalars['DateTime']>;
  /** Продолжительность вебинара или семинара */
  courseHours?: Maybe<Scalars['Seconds']>;
  /** Заголовок вебинара или семинара */
  courseTitle?: Maybe<Scalars['String']>;
  /** Дата создания заказа */
  created?: Maybe<Scalars['DateTime']>;
  /** Цена, по которой была куплена форма */
  historyPrice: Scalars['Decimal'];
  /** Идентификатор OrderExtended */
  id: Scalars['String'];
  /** Пригласительная ссылка в telegram чат */
  inviteLink?: Maybe<InviteLink>;
  /** Последняя дата изменения заказа */
  lastChanged?: Maybe<Scalars['DateTime']>;
  /** Покупаемая форма */
  productForm: ProductForm;
  /** Заголовок формы продукта */
  productFormTitle?: Maybe<Scalars['String']>;
  /** Тип формы продукта */
  productFormType?: Maybe<ProductFormPublicTypeEnum>;
  /** ID продукта */
  productId?: Maybe<Scalars['Int']>;
  /** Название продукта */
  productName?: Maybe<Scalars['String']>;
  /** Основная форма заказа (с чем идет в комплекте) */
  refOrder?: Maybe<Order>;
  /** Дата семинара */
  seminarDate?: Maybe<Scalars['DateTime']>;
  /** Продолжительность семинара */
  seminarHours?: Maybe<Scalars['Seconds']>;
  /** ID семинара */
  seminarId?: Maybe<Scalars['Int']>;
  /** Заголовок семинара */
  seminarTitle?: Maybe<Scalars['String']>;
  /** Состояние */
  state?: Maybe<OrderPublicStateEnum>;
  /** Дата вебинара */
  webinarDate?: Maybe<Scalars['DateTime']>;
  /** Продолжительность вебинара */
  webinarHours?: Maybe<Scalars['Seconds']>;
  /** ID вебинара */
  webinarId?: Maybe<Scalars['Int']>;
  /** Заголовок вебинара */
  webinarTitle?: Maybe<Scalars['String']>;
};

/** Отзыв к продукту */
export type FeedbackToProduct = {
  __typename?: 'feedbackToProduct';
  /** Идентификатор пользователя */
  accountId: Scalars['Int'];
  /** Имя пользователя */
  accountName?: Maybe<Scalars['String']>;
  /** Дата создания */
  date?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  dateUpdate?: Maybe<Scalars['DateTime']>;
  /** Описание */
  description: Scalars['String'];
  /** Идентификатор FeedbackToProduct */
  id: Scalars['String'];
  /** Связь с продуктом */
  product?: Maybe<Product>;
  /** Идентификатор продукта */
  productId: Scalars['Int'];
  /** Оценка */
  rating?: Maybe<Scalars['Int']>;
};

export type FeedbackToProductListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<FeedbackToProductOrderEnum>;
  accountId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  onlyTop?: Maybe<Scalars['Boolean']>;
  onlyVisible?: Maybe<Scalars['Boolean']>;
};

/** Enum sort order of FeedbackToProduct */
export enum FeedbackToProductOrderEnum {
  AccountId = 'ACCOUNT_ID',
  ProductId = 'PRODUCT_ID',
  Rating = 'RATING',
  Date = 'DATE'
}

export type FeedbackToProductPublicInsertInput = {
  productId: Scalars['Int'];
  rating: Scalars['Int'];
  description: Scalars['String'];
};

export type FeedbackToProductPublicUpdateInput = {
  productId: Scalars['Int'];
  rating: Scalars['Int'];
  description: Scalars['String'];
};

export enum FinancialCalculatorGetCurrencyEnum {
  Rub = 'RUB',
  Usd = 'USD'
}

/** Входные данные для финансового калькулятора */
export type FinancialCalculatorGetInput = {
  /** Период инвестирования месяц/год */
  period: FinancialCalculatorGetPeriodEnum;
  /** Сколько лет инвестировать */
  years: Scalars['Int'];
  /** Период дохода ежемесячный/ежегодный */
  profit: FinancialCalculatorGetProfitEnum;
  /** Ожидаемый доход */
  amount: Scalars['Float'];
  /** Валюта ожидаемого дохода */
  currency: FinancialCalculatorGetCurrencyEnum;
  /** Тип денег текущие/будущие */
  type: FinancialCalculatorGetTypeEnum;
  /** Инфляция для рублей (%) */
  iRRUB: Scalars['Int'];
  /** Инфляция для долларов (%) */
  iRUSD: Scalars['Int'];
  /** Доходность российского рынка акций (%) */
  iRER: Scalars['Int'];
  /** Доходность российского рынка облигаций (%) */
  iRBR: Scalars['Int'];
  /** Доходность американского рынка акций (%) */
  iREUS: Scalars['Int'];
  /** Доходность американского рынка облигаций (%) */
  iRBUS: Scalars['Int'];
};

export enum FinancialCalculatorGetPeriodEnum {
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum FinancialCalculatorGetProfitEnum {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

/** Выходные данные для финансового калькулятора */
export type FinancialCalculatorGetResult = {
  __typename?: 'financialCalculatorGetResult';
  /** Деньги в будущем */
  amount: Scalars['Float'];
  /** Капитал */
  capital: Scalars['Float'];
  /** Инвестиции */
  investments: Scalars['Float'];
  /** Инвестиционный доход */
  investmentYield: Scalars['Float'];
  /** За все время вы инвестируете */
  total: Scalars['Float'];
};

export enum FinancialCalculatorGetTypeEnum {
  Current = 'CURRENT',
  Future = 'FUTURE'
}

/** Входные данные для вопросов финансового калькулятора */
export type FinancialCalculatorQuestionGetInput = {
  /** Период инвестирования месяц/2 недели */
  period: FinancialCalculatorQuestionGetPeriodEnum;
  /** Сколько лет инвестировать */
  years: Scalars['Int'];
  /** Ожидаемый капитал */
  capital: Scalars['Float'];
  /** Ожидаемая инвестиция */
  investment: Scalars['Float'];
  /** Ожидаемый доход */
  amount: Scalars['Float'];
  /** Тип расчета с инфляцией или без */
  type: FinancialCalculatorQuestionGetTypeEnum;
  /** Тип ответа */
  typeResponse: FinancialCalculatorQuestionGetTypeResponseEnum;
  /** Валюта ожидаемого дохода */
  currency: FinancialCalculatorGetCurrencyEnum;
  /** Инфляция для рублей (%) */
  iRRUB: Scalars['Int'];
  /** Инфляция для долларов (%) */
  iRUSD: Scalars['Int'];
  /** Доходность российского рынка акций (%) */
  iRER: Scalars['Int'];
  /** Доходность российского рынка облигаций (%) */
  iRBR: Scalars['Int'];
  /** Доходность американского рынка акций (%) */
  iREUS: Scalars['Int'];
  /** Доходность американского рынка облигаций (%) */
  iRBUS: Scalars['Int'];
};

export enum FinancialCalculatorQuestionGetPeriodEnum {
  Month = 'MONTH',
  BiWeekly = 'BI_WEEKLY'
}

/** Выходные данные для вопросов финансового калькулятора */
export type FinancialCalculatorQuestionGetResult = {
  __typename?: 'financialCalculatorQuestionGetResult';
  /** Доход */
  amountResult: Scalars['Float'];
  /** Доход с инфляцией */
  amountResultInflation: Scalars['Float'];
  /** Капитал */
  capitalResult: Scalars['Float'];
  /** Капитал с инфляцией */
  capitalResultInflation: Scalars['Float'];
  /** Инвестиции */
  investmentsResult: Scalars['Float'];
  /** Количество лет */
  yearResult: Array<Scalars['Int']>;
};

export enum FinancialCalculatorQuestionGetTypeEnum {
  NotInflation = 'NOT_INFLATION',
  Inflation = 'INFLATION'
}

export enum FinancialCalculatorQuestionGetTypeResponseEnum {
  PassiveYear = 'PASSIVE_YEAR',
  PassiveAmount = 'PASSIVE_AMOUNT',
  PassiveInvestment = 'PASSIVE_INVESTMENT',
  SaveYear = 'SAVE_YEAR',
  SaveInvestment = 'SAVE_INVESTMENT',
  SaveCapital = 'SAVE_CAPITAL'
}

/** HighLow security */
export type HighLowSecurity = {
  __typename?: 'highLowSecurity';
  /** Амплитуда, % */
  amplitude: Scalars['Decimal'];
  /** Текущая цена */
  current: Scalars['Decimal'];
  /** Дата текущей цены */
  currentDate?: Maybe<Scalars['DateTime']>;
  /** 1Д, % */
  d1: Scalars['Decimal'];
  /** Exchange Traded Fund */
  eTF: Scalars['Boolean'];
  /** Максимальная цена */
  high: Scalars['Decimal'];
  /** Дата максимальной цены */
  highDate?: Maybe<Scalars['DateTime']>;
  /** Повышение цены, % */
  highPercent?: Maybe<Scalars['Decimal']>;
  /** Индекс */
  index: Scalars['Boolean'];
  /** Капитализация */
  issueCapitalization: Scalars['Decimal'];
  /** Минимальная цена */
  low: Scalars['Decimal'];
  /** Дата минимальной цены */
  lowDate?: Maybe<Scalars['DateTime']>;
  /** Понижение цены, % */
  lowPercent?: Maybe<Scalars['Decimal']>;
  /** 1М, % */
  m1: Scalars['Decimal'];
  /** Индекс голубых фишек Московской Биржи */
  moscowExchangeBlueChipIndex: Scalars['Boolean'];
  /** Индекс МосБиржи */
  moscowExchangesIndex: Scalars['Boolean'];
  /** Полное наименование */
  name: Scalars['String'];
  /** Позиция текущей цены на графике, % */
  position: Scalars['Int'];
  /** Код ценной бумаги */
  secId: Scalars['String'];
  /** 1Н, % */
  w1: Scalars['Decimal'];
  /** 1Г, % */
  y1: Scalars['Decimal'];
  /** YTD, % */
  yTD: Scalars['Decimal'];
};

/** Высокодоходная облигация */
export type HighYieldBond = {
  __typename?: 'highYieldBond';
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  matdays?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Decimal']>;
  yieldIis?: Maybe<Scalars['Decimal']>;
};

/** Пригласительная ссылка в telegram */
export type InviteLink = {
  __typename?: 'inviteLink';
  /** Аккаунт */
  account?: Maybe<Account>;
  /** id аккаунта */
  accountId: Scalars['Int'];
  /** Дата создания */
  date?: Maybe<Scalars['DateTime']>;
  /** Дата экспирации */
  dateExpire?: Maybe<Scalars['DateTime']>;
  /** id */
  id: Scalars['Int'];
  /** ссылка */
  link: Scalars['String'];
  /** id заказа */
  orderId: Scalars['Int'];
};

/** Преподаватель */
export type Lecturer = {
  __typename?: 'lecturer';
  /** О преподавателе */
  about?: Maybe<Scalars['String']>;
  /** Аккаунт преподавателя */
  account: Account;
  /** Образование */
  education?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Продукты, которые ведет преподаватель */
  products: Array<Product>;
  /** Опыт */
  skill?: Maybe<Scalars['String']>;
};


/** Преподаватель */
export type LecturerProductsArgs = {
  filter?: Maybe<ProductListRequest>;
};

export type LecturerListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<LecturerOrderEnum>;
  search?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
  courseStartNotify?: Maybe<Scalars['Boolean']>;
  courseEndNotify?: Maybe<Scalars['Boolean']>;
  showcaseContentProviderId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  seminarId?: Maybe<Scalars['Int']>;
  webinarId?: Maybe<Scalars['Int']>;
};

/** Order the list of lecturer */
export enum LecturerOrderEnum {
  Id = 'ID',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  CourseStartNotify = 'COURSE_START_NOTIFY',
  CourseEndNotify = 'COURSE_END_NOTIFY'
}

export type ListOperationResultTypeAccountPublicType = {
  __typename?: 'ListOperationResultTypeAccountPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Account>>;
};

export type ListOperationResultTypeArticlePublicType = {
  __typename?: 'ListOperationResultTypeArticlePublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Article>>;
};

export type ListOperationResultTypeBrokerPublicType = {
  __typename?: 'ListOperationResultTypeBrokerPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Broker>>;
};

export type ListOperationResultTypeCertificatePublicType = {
  __typename?: 'ListOperationResultTypeCertificatePublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Certificate>>;
};

export type ListOperationResultTypeContentProviderPublicType = {
  __typename?: 'ListOperationResultTypeContentProviderPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<ContentProvider>>;
};

export type ListOperationResultTypeEventRecordPublicType = {
  __typename?: 'ListOperationResultTypeEventRecordPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<EventRecord>>;
};

export type ListOperationResultTypeExtendedOrderPublicType = {
  __typename?: 'ListOperationResultTypeExtendedOrderPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<ExtendedOrder>>;
};

export type ListOperationResultTypeFeedbackToProductPublicType = {
  __typename?: 'ListOperationResultTypeFeedbackToProductPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<FeedbackToProduct>>;
};

export type ListOperationResultTypeLecturerPublicType = {
  __typename?: 'ListOperationResultTypeLecturerPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Lecturer>>;
};

export type ListOperationResultTypeMaterialPublicType = {
  __typename?: 'ListOperationResultTypeMaterialPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Material>>;
};

export type ListOperationResultTypeOrderPublicType1 = {
  __typename?: 'ListOperationResultTypeOrderPublicType1';
  count: Scalars['Int'];
  result: Array<Maybe<Order>>;
};

export type ListOperationResultTypePagePublicType = {
  __typename?: 'ListOperationResultTypePagePublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Page>>;
};

export type ListOperationResultTypePicturePublicType = {
  __typename?: 'ListOperationResultTypePicturePublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Picture>>;
};

export type ListOperationResultTypeProductFormPublicType = {
  __typename?: 'ListOperationResultTypeProductFormPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<ProductForm>>;
};

export type ListOperationResultTypeProductPublicType = {
  __typename?: 'ListOperationResultTypeProductPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Product>>;
};

export type ListOperationResultTypeReferralPublicType = {
  __typename?: 'ListOperationResultTypeReferralPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<ReferralTransaction>>;
};

export type ListOperationResultTypeSeminarPublicType = {
  __typename?: 'ListOperationResultTypeSeminarPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Seminar>>;
};

export type ListOperationResultTypeSeoPageInfoPublicType = {
  __typename?: 'ListOperationResultTypeSeoPageInfoPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<SeoPageInfo>>;
};

export type ListOperationResultTypeTagPublicType = {
  __typename?: 'ListOperationResultTypeTagPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Tag>>;
};

export type ListOperationResultTypeVideoCoursePublicType = {
  __typename?: 'ListOperationResultTypeVideoCoursePublicType';
  count: Scalars['Int'];
  result: Array<Maybe<VideoCourse>>;
};

export type ListOperationResultTypeWebinarPublicType = {
  __typename?: 'ListOperationResultTypeWebinarPublicType';
  count: Scalars['Int'];
  result: Array<Maybe<Webinar>>;
};

/** Материал */
export type Material = {
  __typename?: 'material';
  /** Дата материала */
  date?: Maybe<Scalars['DateTime']>;
  /** Описание */
  description: Scalars['String'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Ссылка на скачивание/просмотр */
  link?: Maybe<Scalars['String']>;
  /** Форма продукта */
  productForm?: Maybe<ProductForm>;
  /** Материал каких семинаров */
  seminars?: Maybe<Array<Maybe<Seminar>>>;
  /** Заголовок */
  title: Scalars['String'];
  /** Материал каких видеокурсов */
  videoCourses?: Maybe<Array<Maybe<VideoCourse>>>;
  /** Материал каких вебинаров */
  webinars?: Maybe<Array<Maybe<Webinar>>>;
};

export type MaterialListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<MaterialOrderEnum>;
  title?: Maybe<Scalars['String']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

/** Order the list of materials */
export enum MaterialOrderEnum {
  Id = 'ID'
}


export type Mutation = {
  __typename?: 'Mutation';
  /** Согласие пользователя с правилами продукта */
  agreementProductOffer: ProductForm;
  /** Соглашение пользователя с условиями сервиса ОФЗ */
  agreeOfzDisclaimer?: Maybe<AccountSubscription>;
  /** Заархивировать заказы продукта */
  archiveProduct?: Maybe<Scalars['Boolean']>;
  /** Привязка счёта тинькофф */
  attachTinkoffToken?: Maybe<Scalars['Boolean']>;
  createFeedbackToProduct?: Maybe<FeedbackToProduct>;
  createOrder?: Maybe<Order>;
  createPayment?: Maybe<Payment>;
  /** Создание подписки с привязанным платежом */
  createSubscription?: Maybe<Payment>;
  deleteOrder?: Maybe<Order>;
  /** Удалить все заказы продукта */
  deleteProductOrders?: Maybe<Scalars['Boolean']>;
  /** Удаление привязки счёта тинькофф */
  detachTinkoffToken?: Maybe<Scalars['Boolean']>;
  /** Отключить автоматическую оплату подписки */
  disableSubscriptionAutopayment?: Maybe<Scalars['Boolean']>;
  /** Пометить форм итем просмотренным/непросмотренным */
  markFormItemAsViewedOrUnviewed?: Maybe<Scalars['Int']>;
  /** Разместить рыночную заявку */
  placeMarketOrder?: Maybe<TinkoffPlacedMarketOrderType>;
  /** Сбросить накопления пользователя в сервисе 52 недели богатства */
  reset52weeks?: Maybe<Scalars['Boolean']>;
  /** Начало использование сервиса 52 недели богатства */
  start52weeks?: Maybe<Service52WeeksUser>;
  /** Подписка на рассылки MailChimp */
  subscribe?: Maybe<MyAccount>;
  /** Подписка на рассылки MailChimp */
  subscribeByEmail?: Maybe<Scalars['Boolean']>;
  /** Синхронизация подписок с MailChimp */
  syncedAccountSubscriptions?: Maybe<MyAccount>;
  /** Синхронизация заказов с гугл-календарем */
  syncWithCalendarOrder?: Maybe<Scalars['Int']>;
  /** Обновление накоплений пользователя в сервисе 52 недели богатства */
  update52weeks?: Maybe<Service52WeeksUser>;
  updateAccount?: Maybe<MyAccount>;
  updateAccountSubscription?: Maybe<AccountSubscription>;
  updateFeedbackToProduct?: Maybe<FeedbackToProduct>;
};


export type MutationAgreementProductOfferArgs = {
  productFormId: Scalars['Int'];
};


export type MutationArchiveProductArgs = {
  productId: Scalars['Int'];
};


export type MutationAttachTinkoffTokenArgs = {
  openApiToken: Scalars['String'];
  pin: Scalars['String'];
};


export type MutationCreateFeedbackToProductArgs = {
  feedbackToProduct: FeedbackToProductPublicInsertInput;
};


export type MutationCreateOrderArgs = {
  order: OrderPublicInsertInput;
};


export type MutationCreatePaymentArgs = {
  arguments: PaymentPublicInsertInput;
};


export type MutationCreateSubscriptionArgs = {
  returnUrl: Scalars['String'];
  type: SubscriptionPublicTypeEnum;
};


export type MutationDeleteOrderArgs = {
  order: OrderPublicDeletetInput;
};


export type MutationDeleteProductOrdersArgs = {
  productId: Scalars['Int'];
};


export type MutationMarkFormItemAsViewedOrUnviewedArgs = {
  productFormType: ProductFormPublicTypeEnum;
  formItemId: Scalars['Int'];
};


export type MutationPlaceMarketOrderArgs = {
  pin: Scalars['String'];
  figi: Scalars['String'];
  brokerAccountId: Scalars['String'];
  lots: Scalars['Int'];
};


export type MutationStart52weeksArgs = {
  date: Scalars['Date'];
  sum: Scalars['Decimal'];
  target?: Maybe<Scalars['String']>;
  currency: CurrencyEnum;
};


export type MutationSubscribeByEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdate52weeksArgs = {
  sum: Scalars['Decimal'];
  weekNumber: Scalars['Int'];
};


export type MutationUpdateAccountArgs = {
  account: AccountPublicUpdateInput;
};


export type MutationUpdateAccountSubscriptionArgs = {
  accountSubscription: AccountSubscriptionPublicUpdateInput;
};


export type MutationUpdateFeedbackToProductArgs = {
  feedbackToProduct: FeedbackToProductPublicUpdateInput;
};

/** Аккаунт текущего пользователя */
export type MyAccount = {
  __typename?: 'MyAccount';
  /** Доступ к видеозаписям и материалам */
  accessToFreeForms?: Maybe<Scalars['Date']>;
  /** Подписки */
  accountSubscription?: Maybe<AccountSubscription>;
  /** Каких контент провайдеров представляет данный пользователь */
  contentProviders?: Maybe<Array<Maybe<ContentProvider>>>;
  /** Email */
  email: Scalars['String'];
  /** Ссылка на социальную сеть Facebook пользователя */
  facebookLink?: Maybe<Scalars['String']>;
  /** Имя */
  firstName?: Maybe<Scalars['String']>;
  /** Проверка Google авторизации для синхронизации с календарем */
  googleAuthorizationCalendar?: Maybe<Scalars['Boolean']>;
  /** Проверка Google авторизации для управления Youtube трансляциями */
  googleAuthorizationYouTube?: Maybe<Scalars['Boolean']>;
  /** Подключен ли счёт тинькофф инвестиций */
  hasTinkoffInvest?: Maybe<Scalars['Boolean']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Фамилия */
  lastName?: Maybe<Scalars['String']>;
  /** Отчество */
  middleName?: Maybe<Scalars['String']>;
  /** Права пользователя */
  permissions: Scalars['String'];
  /** Телефон */
  phones?: Maybe<Scalars['String']>;
  /** Реферальная ссылка */
  refLink?: Maybe<Scalars['String']>;
  /** Сумма реферальных баллов */
  refPoints?: Maybe<Scalars['Int']>;
  /** Возвращает состояние подписки */
  subscription: ActiveSubscriptionPublicType;
  /**
   * Возвращает состояние подписки CorporateBonds
   * @deprecated Данная подписка больше не используется
   */
  subscriptionCorporateBonds: ActiveSubscriptionPublicType;
  /**
   * Возвращает состояние подписки FreeCourses
   * @deprecated Данная подписка больше не используется
   */
  subscriptionFreeCourses: ActiveSubscriptionPublicType;
  /** Telegram аккаунт пользователя */
  telegramUserName?: Maybe<Scalars['String']>;
  /** Ссылка на социальную сеть VK пользователя */
  vkLink?: Maybe<Scalars['String']>;
};

/** Заказ */
export type Order = {
  __typename?: 'order';
  /** Доступ до */
  accessUntil?: Maybe<Scalars['Date']>;
  /** Покупатель */
  account: Account;
  /** Дата создания заказа */
  created?: Maybe<Scalars['DateTime']>;
  /** Цена, по которой была куплена форма */
  historyPrice: Scalars['Decimal'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Пригласительная ссылка в telegram чат */
  inviteLink?: Maybe<InviteLink>;
  /** Последняя дата изменения заказа */
  lastChanged?: Maybe<Scalars['DateTime']>;
  /** Покупаемая форма */
  productForm: ProductForm;
  /** Основная форма заказа (с чем идет в комплекте) */
  refOrder?: Maybe<Order>;
  /** Состояние */
  state?: Maybe<OrderPublicStateEnum>;
};

export type OrderListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<OrderOrderEnum>;
  formId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  state?: Maybe<OrderPublicStateEnum>;
  productId?: Maybe<Scalars['Int']>;
  onlyCourses?: Maybe<Scalars['Boolean']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  isFree?: Maybe<Scalars['Boolean']>;
  courseStartDate?: Maybe<Scalars['DateTime']>;
  courseEndDate?: Maybe<Scalars['DateTime']>;
};

/** Order the list of orders */
export enum OrderOrderEnum {
  Id = 'ID',
  Form = 'FORM',
  Account = 'ACCOUNT',
  Date = 'DATE',
  State = 'STATE'
}

export type OrderPublicDeletetInput = {
  id: Scalars['Int'];
};

export type OrderPublicInsertInput = {
  formId: Scalars['Int'];
  registeredFromSite?: Maybe<Scalars['String']>;
};

/** Состояние заказа */
export enum OrderPublicStateEnum {
  InCart = 'IN_CART',
  InProcessing = 'IN_PROCESSING',
  Purchased = 'PURCHASED',
  Banned = 'BANNED'
}

/** Страница */
export type Page = {
  __typename?: 'page';
  /** Описание */
  content?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Краткое описание */
  shortDescription?: Maybe<Scalars['String']>;
  /** Slug */
  slug: Scalars['String'];
  /** Заголовок */
  title: Scalars['String'];
  /** Тип страницы */
  type?: Maybe<PagePublicTypeEnum>;
};

export type PageListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<PageOrderEnum>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PagePublicTypeEnum>;
  slugs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Order the list of pages */
export enum PageOrderEnum {
  Id = 'ID',
  Title = 'TITLE',
  Slug = 'SLUG',
  Visible = 'VISIBLE',
  Type = 'TYPE'
}

/** Тип страницы */
export enum PagePublicTypeEnum {
  Article = 'ARTICLE',
  TextBlock = 'TEXT_BLOCK'
}

/** Партнер */
export type Partner = {
  __typename?: 'partner';
  /** Идентификатор */
  id: Scalars['Int'];
  /** Ссылка */
  link: Scalars['String'];
  /** Название */
  name: Scalars['String'];
  /** Идентификатор изображения */
  pictureId: Scalars['Int'];
  /** Позиция в карусели */
  position: Scalars['Int'];
};

/** Платеж */
export type Payment = {
  __typename?: 'payment';
  /** Адрес для оплаты */
  confirmationUrl?: Maybe<Scalars['String']>;
  /** Дата создания платежа */
  created: Scalars['Date'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Дата последнего изменения статуса платежа */
  lastChanged: Scalars['Date'];
  /** Сумма */
  price: Scalars['Decimal'];
  /** Состояние платежа */
  status?: Maybe<PaymentStatusEnum>;
  /** Тип платежа */
  type?: Maybe<PaymentTypeEnum>;
};

export type PaymentPublicInsertInput = {
  orderId: Scalars['Int'];
  method?: Maybe<PaymentPublicMethodEnum>;
  returnUrl?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
};

/** Способ оплаты платежа */
export enum PaymentPublicMethodEnum {
  BankCard = 'BANK_CARD',
  Cash = 'CASH',
  Qiwi = 'QIWI',
  AlfaBank = 'ALFA_BANK',
  WebMoney = 'WEB_MONEY',
  SberBank = 'SBER_BANK',
  YooMoney = 'YOO_MONEY',
  ApplePay = 'APPLE_PAY',
  MobileBalance = 'MOBILE_BALANCE',
  Points = 'POINTS',
  Installments = 'INSTALLMENTS',
  Gift = 'GIFT'
}

/** Статус платежа */
export enum PaymentStatusEnum {
  Pending = 'PENDING',
  WaitingForCapture = 'WAITING_FOR_CAPTURE',
  Succeeded = 'SUCCEEDED',
  Canceled = 'CANCELED'
}

/** Тип платежа */
export enum PaymentTypeEnum {
  BankCard = 'BANK_CARD',
  Cash = 'CASH',
  Qiwi = 'QIWI',
  AlfaBank = 'ALFA_BANK',
  WebMoney = 'WEB_MONEY',
  SberBank = 'SBER_BANK',
  YooMoney = 'YOO_MONEY',
  ApplePay = 'APPLE_PAY',
  MobileBalance = 'MOBILE_BALANCE',
  Points = 'POINTS',
  Installments = 'INSTALLMENTS',
  Gift = 'GIFT'
}

/** Изображение */
export type Picture = {
  __typename?: 'picture';
  /** Filename picture */
  fileName: Scalars['String'];
  /** The id of the picture */
  id: Scalars['Int'];
  /** Размер изображения */
  imageSize?: Maybe<Scalars['String']>;
};

export type PictureListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<PictureOrderEnum>;
  fileName?: Maybe<Scalars['String']>;
};

/** Order the list of pictures */
export enum PictureOrderEnum {
  Id = 'ID',
  Filename = 'FILENAME'
}

/** Продукт */
export type Product = {
  __typename?: 'product';
  /** Уровень сложности */
  complexity?: Maybe<Scalars['Int']>;
  /** Провайдер контента */
  contentProvider: ContentProvider;
  /** Полное описание */
  description: Scalars['String'];
  /** Наличие у пользователя хотя бы одной купленной формы из данного продукта */
  hasAnyPurchasedForm: Scalars['Boolean'];
  /** Наличие у пользователя отзыва к данному продукту */
  hasFeedback: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** активная ссылка приглашение в telegram */
  inviteLink?: Maybe<InviteLink>;
  /** [PurchasedProduct] Хотя бы один из заказов в архиве у пользователя */
  isArchive?: Maybe<Scalars['Boolean']>;
  /** [PurchasedProduct] Все заказы у данного продукта - бесплатные */
  isFree?: Maybe<Scalars['Boolean']>;
  /** [PurchasedProduct] Последняя дата оплаты */
  lastPurchasedDate?: Maybe<Scalars['DateTime']>;
  /** Преподаватели */
  lecturers: Array<Lecturer>;
  /** Название */
  name: Scalars['String'];
  /** Ссылка на файл согласия на коммуникацию */
  offer?: Maybe<Scalars['String']>;
  /** Превью */
  picture?: Maybe<Picture>;
  /** ID изображения с превью */
  pictureId?: Maybe<Scalars['Int']>;
  /** Формы продукта */
  productForms: Array<ProductForm>;
  /** Проценты с цены форм продукта, выплачивающийся рефералам */
  refPercent: Scalars['Int'];
  /** Блок чему вы научитесь */
  salesPoints?: Maybe<Scalars['String']>;
  /** Краткое описание */
  shortDescription?: Maybe<Scalars['String']>;
  /** Рекомендуемый продукт */
  suggestedProduct?: Maybe<Product>;
  /** Id тегов */
  tags: Array<Scalars['Int']>;
  /** Теги массивом строк */
  tagsString: Array<Scalars['String']>;
  /** Блок внимание */
  warnings?: Maybe<Scalars['String']>;
};


/** Продукт */
export type ProductProductFormsArgs = {
  filter?: Maybe<ProductFormListRequest>;
};

/** Order the list of products */
export enum ProductAdminOrderEnum {
  Id = 'ID',
  ContentProvider = 'CONTENT_PROVIDER',
  Complexity = 'COMPLEXITY',
  Visibility = 'VISIBILITY',
  RegCount = 'REG_COUNT',
  PaidCount = 'PAID_COUNT',
  Views = 'VIEWS'
}

/** Видимость продукта */
export enum ProductAdminVisibilityEnum {
  Admin = 'ADMIN',
  Showcase = 'SHOWCASE',
  Public = 'PUBLIC',
  Archived = 'ARCHIVED',
  Link = 'LINK'
}

/** Форма продукта */
export type ProductForm = {
  __typename?: 'productForm';
  /** Время доступа */
  accessDay: Scalars['Int'];
  /** До какой даты форма доступна для покупки */
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Сертификаты */
  certificates: Array<Certificate>;
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Пользователь соглашался с правилами продукта ранее */
  hasProductAgreement?: Maybe<Scalars['Boolean']>;
  /** Формы, которые содержат в комплекте данную форму */
  iAmInSetForms: Array<ProductForm>;
  /** Формы, которые открывает данная форма */
  iAmOpeningForms: Array<ProductForm>;
  /** Формы, которые открывает данная форма(массивом id) */
  iAmOpeningFormsArray: Array<Scalars['Int']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Нельзя купить отдельно */
  isNonSingle: Scalars['Boolean'];
  /** Материалы */
  materials: Array<Material>;
  /** Максимальное число заказов */
  maxOrders: Scalars['Int'];
  /** Формы, которые открывают данную форму */
  meOpeningForms: Array<ProductForm>;
  /** Последний заказ формы */
  order?: Maybe<Order>;
  /** Текущее число заказов */
  ordersCount: Scalars['Int'];
  /** Статус покупки формы продукта */
  paymentStatus: ProductFormPaymentStatusEnum;
  /** ID изображения продукта */
  pictureId?: Maybe<Scalars['Int']>;
  /** Цена */
  price: Scalars['Decimal'];
  /** Связь с продуктом */
  product: Product;
  /** Записи */
  records: Array<EventRecord>;
  /** Список обязательных полей аккаунта необходимых для регистрации */
  requiredFieldsNumber: Scalars['Int'];
  /** Семинары */
  seminars: Array<Seminar>;
  /** Показывать количество занятий и блок В комплекте */
  showNumberLessons: Scalars['Boolean'];
  /** Показывать оставшиеся места */
  showRemainingOrders: Scalars['Boolean'];
  /** Доступ к контенту формы только по подписке */
  subscriptionAccess: Scalars['Boolean'];
  /** Рекомендуемая форма продукта */
  suggestedProductForm?: Maybe<ProductForm>;
  /** ID рекомендованной формы продукта */
  suggestedProductFormId?: Maybe<Scalars['Int']>;
  /** Описание к рекомендованной форме продукта */
  suggestedTitle?: Maybe<Scalars['String']>;
  /** Заголовок формы */
  title?: Maybe<Scalars['String']>;
  /** Тип формы */
  type: ProductFormPublicTypeEnum;
  /** Видеокурсы */
  videoCourses: Array<VideoCourse>;
  /** Вебинары */
  webinars: Array<Webinar>;
  /** Формы, которые идут в комплекте с данной формой */
  withMeInSetForms: Array<ProductForm>;
};

export type ProductFormListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<ProductFormOrderEnum>;
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  types?: Maybe<Array<Maybe<ProductFormPublicTypeEnum>>>;
  excludeTypes?: Maybe<Array<Maybe<ProductFormPublicTypeEnum>>>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lecturers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productId?: Maybe<Scalars['Int']>;
  complexity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  excludeId?: Maybe<Scalars['Int']>;
  contentProviderId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  isStock?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  isFree?: Maybe<Scalars['Boolean']>;
  /** Заменено на isFree */
  onlyFree?: Maybe<Scalars['Boolean']>;
  /** Заменено на isFree */
  onlyPaid?: Maybe<Scalars['Boolean']>;
  onlyWithPlaces?: Maybe<Scalars['Boolean']>;
  onlyAvailable?: Maybe<Scalars['Boolean']>;
  showPublic?: Maybe<Scalars['Boolean']>;
  isPastAvailableUntil?: Maybe<Scalars['Boolean']>;
  paymentStatus?: Maybe<ProductFormPaymentStatusEnum>;
  onlyGiftsOfFormId?: Maybe<Scalars['Int']>;
  onlyNotGiftsOfFormId?: Maybe<Scalars['Int']>;
  includeWords?: Maybe<Scalars['String']>;
  excludeWords?: Maybe<Scalars['String']>;
  excludeProductVisibilityLink?: Maybe<Scalars['Boolean']>;
  isSubscriptionAccess?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

/** Order the list of product form */
export enum ProductFormOrderEnum {
  Id = 'ID',
  Type = 'TYPE',
  Price = 'PRICE',
  AvailableUntil = 'AVAILABLE_UNTIL',
  Complexity = 'COMPLEXITY',
  Popular = 'POPULAR',
  StartDate = 'START_DATE',
  OrderDate = 'ORDER_DATE',
  RegCount = 'REG_COUNT',
  PaidCount = 'PAID_COUNT'
}

/** Статус покупки формы */
export enum ProductFormPaymentStatusEnum {
  NotAuthorized = 'NOT_AUTHORIZED',
  Never = 'NEVER',
  Cart = 'CART',
  Already = 'ALREADY',
  Expired = 'EXPIRED',
  Blocked = 'BLOCKED',
  Processing = 'PROCESSING',
  NoPlaces = 'NO_PLACES',
  NotAvailable = 'NOT_AVAILABLE'
}

/** Тип формы */
export enum ProductFormPublicTypeEnum {
  Webinar = 'WEBINAR',
  Seminar = 'SEMINAR',
  Material = 'MATERIAL',
  EventRecord = 'EVENT_RECORD',
  VideoCourse = 'VIDEO_COURSE',
  Certificate = 'CERTIFICATE',
  Offer = 'OFFER'
}

export type ProductListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<ProductAdminOrderEnum>;
  contentProviderId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  visibility?: Maybe<ProductAdminVisibilityEnum>;
  lecturerId?: Maybe<Scalars['Int']>;
  onlyPurchased?: Maybe<Scalars['Boolean']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  dashboardProductFormType?: Maybe<ProductFormPublicTypeEnum>;
};

/** Промокод */
export type PromoCode = {
  __typename?: 'promoCode';
  /** Сумма скидки по промокоду (зависит от типа промокода) */
  amount: Scalars['Int'];
  /** Идентификатор */
  id: Scalars['String'];
  /** Название */
  name: Scalars['String'];
  /** Тип промокода */
  type?: Maybe<PromoCodeAdminTypeEnum>;
};

/** Тип промокода */
export enum PromoCodeAdminTypeEnum {
  Percent = 'PERCENT',
  Amount = 'AMOUNT'
}

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts?: Maybe<ListOperationResultTypeAccountPublicType>;
  accountSubscription?: Maybe<AccountSubscription>;
  article?: Maybe<Article>;
  /** Получение страницы по slug */
  articleBySlug?: Maybe<Article>;
  articles?: Maybe<ListOperationResultTypeArticlePublicType>;
  broker?: Maybe<Broker>;
  brokers?: Maybe<ListOperationResultTypeBrokerPublicType>;
  /** Сбор портфеля из облигаций */
  buildPortfolio?: Maybe<BpPortfolio>;
  certificate?: Maybe<Certificate>;
  certificates?: Maybe<ListOperationResultTypeCertificatePublicType>;
  /** Проверка доступности промокода */
  checkPromoCode?: Maybe<PromoCode>;
  contentProvider?: Maybe<ContentProvider>;
  contentProviders?: Maybe<ListOperationResultTypeContentProviderPublicType>;
  dividend: DividendShare;
  dividends: Array<DividendShare>;
  eventRecord?: Maybe<EventRecord>;
  eventRecords?: Maybe<ListOperationResultTypeEventRecordPublicType>;
  evolutionRegister?: Maybe<Scalars['Boolean']>;
  extendedOrders?: Maybe<ListOperationResultTypeExtendedOrderPublicType>;
  feedbackToProduct?: Maybe<FeedbackToProduct>;
  feedbackToProducts?: Maybe<ListOperationResultTypeFeedbackToProductPublicType>;
  /** Финансовый калькулятор */
  financialCalculatorGet: FinancialCalculatorGetResult;
  /** Вопросы для финансового калькулятора */
  financialCalculatorQuestionGet: FinancialCalculatorQuestionGetResult;
  /** Получить накопления пользователя для сервиса 52 недели богатства */
  get52weeks?: Maybe<Service52WeeksUser>;
  /** Имеет ли аккаунт будущие, оплаченные семинары или вебинары? */
  hasAnyFutureCourses?: Maybe<Scalars['Boolean']>;
  highLowData?: Maybe<Array<Maybe<HighLowSecurity>>>;
  lecturer?: Maybe<Lecturer>;
  lecturers?: Maybe<ListOperationResultTypeLecturerPublicType>;
  material?: Maybe<Material>;
  materials?: Maybe<ListOperationResultTypeMaterialPublicType>;
  myAccount?: Maybe<MyAccount>;
  order?: Maybe<Order>;
  orders?: Maybe<ListOperationResultTypeOrderPublicType1>;
  page?: Maybe<Page>;
  /** Получение страницы по slug */
  pageBySlug?: Maybe<Page>;
  pages?: Maybe<ListOperationResultTypePagePublicType>;
  /** Получить список партнеров */
  partners: Array<Partner>;
  picture?: Maybe<Picture>;
  pictures?: Maybe<ListOperationResultTypePicturePublicType>;
  product?: Maybe<Product>;
  productForm?: Maybe<ProductForm>;
  productForms?: Maybe<ListOperationResultTypeProductFormPublicType>;
  products?: Maybe<ListOperationResultTypeProductPublicType>;
  purchasedProduct?: Maybe<Product>;
  /** Получить оплаченные продукты с добавленным полем (последняя дата оплаты) */
  purchasedProducts?: Maybe<ListOperationResultTypeProductPublicType>;
  /** Получить информацию о реферальной программе пользователя */
  referralAccountInfo?: Maybe<ReferralAccountInfo>;
  /** Получить информация о реферальной программе пользователя по каждому дню за последние 30 дней */
  referralAccountInfoPerDay?: Maybe<Array<Maybe<ReferralAccountInfoPerDay>>>;
  referralTransaction?: Maybe<ReferralTransaction>;
  referralTransactions?: Maybe<ListOperationResultTypeReferralPublicType>;
  seminar?: Maybe<Seminar>;
  seminars?: Maybe<ListOperationResultTypeSeminarPublicType>;
  seoPageInfo?: Maybe<SeoPageInfo>;
  /** Получение страницы по pathname */
  seoPageInfoByPathname?: Maybe<SeoPageInfo>;
  seoPageInfos?: Maybe<ListOperationResultTypeSeoPageInfoPublicType>;
  tag?: Maybe<Tag>;
  tags?: Maybe<ListOperationResultTypeTagPublicType>;
  tagsByType?: Maybe<ListOperationResultTypeTagPublicType>;
  /** Данные для формы выставления маркетной заявки */
  tinkoffFormData?: Maybe<TinkoffMarketFormDataType>;
  vdoData?: Maybe<Array<Maybe<HighYieldBond>>>;
  vdoPurchaseRequest?: Maybe<Scalars['Boolean']>;
  videoCourse?: Maybe<VideoCourse>;
  videoCourses?: Maybe<ListOperationResultTypeVideoCoursePublicType>;
  viewedFormItems?: Maybe<Array<Maybe<ViewedFormItem>>>;
  webinar?: Maybe<Webinar>;
  webinars?: Maybe<ListOperationResultTypeWebinarPublicType>;
};


export type QueryAccountArgs = {
  id: Scalars['Int'];
};


export type QueryAccountsArgs = {
  filter?: Maybe<AccountListRequest>;
};


export type QueryAccountSubscriptionArgs = {
  id: Scalars['Int'];
};


export type QueryArticleArgs = {
  id: Scalars['Int'];
};


export type QueryArticleBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryArticlesArgs = {
  filter?: Maybe<ArticleListRequest>;
};


export type QueryBrokerArgs = {
  id: Scalars['Int'];
};


export type QueryBrokersArgs = {
  filter?: Maybe<BrokerListRequest>;
};


export type QueryBuildPortfolioArgs = {
  request: BuildPortfolioRequest;
};


export type QueryCertificateArgs = {
  id: Scalars['Int'];
};


export type QueryCertificatesArgs = {
  filter?: Maybe<CertificateListRequest>;
};


export type QueryCheckPromoCodeArgs = {
  name: Scalars['String'];
  orderId: Scalars['Int'];
};


export type QueryContentProviderArgs = {
  id: Scalars['Int'];
};


export type QueryContentProvidersArgs = {
  filter?: Maybe<ContentProviderListRequest>;
};


export type QueryDividendArgs = {
  symbol: Scalars['String'];
};


export type QueryEventRecordArgs = {
  id: Scalars['Int'];
};


export type QueryEventRecordsArgs = {
  filter?: Maybe<EventRecordListRequest>;
};


export type QueryEvolutionRegisterArgs = {
  phone: Scalars['String'];
};


export type QueryExtendedOrdersArgs = {
  filter?: Maybe<OrderListRequest>;
};


export type QueryFeedbackToProductArgs = {
  productId: Scalars['Int'];
};


export type QueryFeedbackToProductsArgs = {
  filter?: Maybe<FeedbackToProductListRequest>;
};


export type QueryFinancialCalculatorGetArgs = {
  input: FinancialCalculatorGetInput;
};


export type QueryFinancialCalculatorQuestionGetArgs = {
  input: FinancialCalculatorQuestionGetInput;
};


export type QueryLecturerArgs = {
  id: Scalars['Int'];
  contentProviderId?: Maybe<Scalars['Int']>;
};


export type QueryLecturersArgs = {
  filter?: Maybe<LecturerListRequest>;
};


export type QueryMaterialArgs = {
  id: Scalars['Int'];
};


export type QueryMaterialsArgs = {
  filter?: Maybe<MaterialListRequest>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrdersArgs = {
  filter?: Maybe<OrderListRequest>;
};


export type QueryPageArgs = {
  id: Scalars['Int'];
};


export type QueryPageBySlugArgs = {
  slug: Scalars['String'];
  withTextBlocks?: Maybe<Scalars['Boolean']>;
};


export type QueryPagesArgs = {
  filter?: Maybe<PageListRequest>;
};


export type QueryPictureArgs = {
  id: Scalars['Int'];
};


export type QueryPicturesArgs = {
  filter?: Maybe<PictureListRequest>;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
  contentProviderId?: Maybe<Scalars['Int']>;
  showPublic?: Maybe<Scalars['Boolean']>;
};


export type QueryProductFormArgs = {
  id: Scalars['Int'];
};


export type QueryProductFormsArgs = {
  filter?: Maybe<ProductFormListRequest>;
};


export type QueryProductsArgs = {
  filter?: Maybe<ProductListRequest>;
};


export type QueryPurchasedProductArgs = {
  id: Scalars['Int'];
};


export type QueryPurchasedProductsArgs = {
  filter?: Maybe<ProductListRequest>;
};


export type QueryReferralTransactionArgs = {
  id: Scalars['Int'];
};


export type QueryReferralTransactionsArgs = {
  filter?: Maybe<ReferralListRequest>;
};


export type QuerySeminarArgs = {
  id: Scalars['Int'];
};


export type QuerySeminarsArgs = {
  filter?: Maybe<SeminarListRequest>;
};


export type QuerySeoPageInfoArgs = {
  id: Scalars['Int'];
};


export type QuerySeoPageInfoByPathnameArgs = {
  pathname: Scalars['String'];
};


export type QuerySeoPageInfosArgs = {
  filter?: Maybe<SeoPageInfoListRequest>;
};


export type QueryTagArgs = {
  id: Scalars['Int'];
};


export type QueryTagsArgs = {
  filter?: Maybe<TagListRequest>;
};


export type QueryTagsByTypeArgs = {
  objectType?: Maybe<Scalars['String']>;
};


export type QueryTinkoffFormDataArgs = {
  pin: Scalars['String'];
  ticker: Scalars['String'];
};


export type QueryVdoPurchaseRequestArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};


export type QueryVideoCourseArgs = {
  id: Scalars['Int'];
};


export type QueryVideoCoursesArgs = {
  filter?: Maybe<VideoCourseListRequest>;
};


export type QueryWebinarArgs = {
  id: Scalars['Int'];
};


export type QueryWebinarsArgs = {
  filter?: Maybe<WebinarListRequest>;
};

/** Информация о реферальной программе пользователя */
export type ReferralAccountInfo = {
  __typename?: 'referralAccountInfo';
  /** Осталось баллов */
  balance: Scalars['Int'];
  /** Количество оплат всех рефералов пользователя */
  paymentsCount: Scalars['Int'];
  /** Количество рефералов пользователя */
  refAccountsCount: Scalars['Int'];
  /** Начислено баллов */
  totalAccrued: Scalars['Int'];
  /** Списано баллов */
  totalWrittenOff: Scalars['Int'];
};

/** Информация о реферальной программе пользователя за определенный день */
export type ReferralAccountInfoPerDay = {
  __typename?: 'referralAccountInfoPerDay';
  /** День */
  date?: Maybe<Scalars['DateTime']>;
  /** Количество новых рефералов за день */
  refAccountsCount: Scalars['Int'];
  /** Начислено баллов за день */
  totalAccrued: Scalars['Int'];
  /** Списано баллов за день */
  totalWriteOff: Scalars['Int'];
};

export type ReferralListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<ReferralOrderEnum>;
  accountId?: Maybe<Scalars['Int']>;
};

/** Порядок сортировки транзакций */
export enum ReferralOrderEnum {
  Id = 'ID',
  Date = 'DATE',
  Amount = 'AMOUNT'
}

/** Транзакция баллов по реферальной программе */
export type ReferralTransaction = {
  __typename?: 'referralTransaction';
  /** Идентификатор аккаунта */
  accountId: Scalars['Int'];
  /** Сумма */
  amount: Scalars['Int'];
  /** Дата */
  date?: Maybe<Scalars['DateTime']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Идентификатор заказа */
  orderId?: Maybe<Scalars['Int']>;
};


/** Семинар */
export type Seminar = {
  __typename?: 'seminar';
  /** Адрес */
  address: Scalars['String'];
  /** Город */
  city: Scalars['String'];
  /** Дата проведения */
  date?: Maybe<Scalars['DateTime']>;
  /** Программа */
  description: Scalars['String'];
  /** Запись семинара */
  eventRecord?: Maybe<EventRecord>;
  /** Продолжительность */
  hours: Scalars['Seconds'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Преподаватели */
  lecturers?: Maybe<Array<Maybe<Lecturer>>>;
  /** Материалы семинара */
  materials?: Maybe<Array<Maybe<Material>>>;
  /** Форма продукта */
  productForm?: Maybe<ProductForm>;
  /** Причина, почему не указано точное начало семинара */
  soonCause?: Maybe<Scalars['String']>;
  /** Заголовок */
  title: Scalars['String'];
};

export type SeminarListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<SeminarOrderEnum>;
  title?: Maybe<Scalars['String']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  lecturerId?: Maybe<Scalars['Int']>;
  isPastDate?: Maybe<Scalars['Boolean']>;
};

/** Order the list of seminars */
export enum SeminarOrderEnum {
  Id = 'ID',
  Date = 'DATE'
}

/** Страница для поисковиков */
export type SeoPageInfo = {
  __typename?: 'seoPageInfo';
  /** Метатег Description */
  description?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Метатег Keywords */
  keywords?: Maybe<Scalars['String']>;
  /** Путь (часть url) для которого задается метаинформация */
  pathname: Scalars['String'];
  /** Метатег og:image */
  pictureId?: Maybe<Scalars['Int']>;
  /** Метатег title */
  title?: Maybe<Scalars['String']>;
};

export type SeoPageInfoListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<SeoPageInfoOrderEnum>;
  pathname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Order the list of seoPageInfos */
export enum SeoPageInfoOrderEnum {
  Id = 'ID',
  Pathname = 'PATHNAME',
  Title = 'TITLE'
}

export type Service52WeeksInvestment = {
  __typename?: 'service52WeeksInvestment';
  id: Scalars['Int'];
  /** Накопления в эту неделю */
  sum: Scalars['Decimal'];
  /** Номер недели (от 1 до 52) */
  weekNumber: Scalars['Int'];
};

export type Service52WeeksUser = {
  __typename?: 'service52WeeksUser';
  /** Аккаунт */
  account: MyAccount;
  /** Цель накоплений */
  currency: CurrencyEnum;
  id: Scalars['Int'];
  /** Накопления */
  investments: Array<Service52WeeksInvestment>;
  /** Дата начала накоплений */
  startDate: Scalars['DateTime'];
  /** Начальная сумма */
  startSum: Scalars['Decimal'];
  /** Цель накоплений */
  target?: Maybe<Scalars['String']>;
};

/** Тип подписки */
export enum SubscriptionPublicTypeEnum {
  FreeCourses = 'FREE_COURSES',
  CorporateBonds = 'CORPORATE_BONDS',
  Month = 'MONTH',
  Year = 'YEAR'
}

/** Тег */
export type Tag = {
  __typename?: 'tag';
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Идентификатор */
  id: Scalars['Int'];
  /** Название тега */
  name: Scalars['String'];
};

export type TagListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<TagOrderEnum>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  objectType?: Maybe<Scalars['String']>;
};

/** Order the list of tags */
export enum TagOrderEnum {
  Id = 'ID',
  Name = 'NAME',
  Active = 'ACTIVE'
}

/** Перечисление типов счетов */
export enum TinkoffBrokerAccountType {
  Tinkoff = 'TINKOFF',
  TinkoffIis = 'TINKOFF_IIS'
}

/** Перечисление валют тинькофф */
export enum TinkoffCurrency {
  Rub = 'RUB',
  Usd = 'USD',
  Eur = 'EUR'
}

export type TinkoffCurrencyPositionType = {
  __typename?: 'TinkoffCurrencyPositionType';
  /** Баланс пользователя */
  balance: Scalars['Float'];
  /** Заблокировано средств */
  blocked: Scalars['Float'];
  /** Счет пользователя */
  brokerAccountId: Scalars['String'];
  /** Валюта */
  currency?: Maybe<TinkoffCurrency>;
};

export type TinkoffInstrumentType = {
  __typename?: 'TinkoffInstrumentType';
  /** Валюта */
  currency?: Maybe<TinkoffCurrency>;
  /** Figi идентификатор */
  figi: Scalars['String'];
  /** Isin идентификатор */
  isin: Scalars['String'];
  /** Шаг лота */
  lot: Scalars['Int'];
  /** Шаг цены */
  minPriceIncrement: Scalars['Float'];
  /** Название */
  name: Scalars['String'];
};

export type TinkoffMarketFormDataType = {
  __typename?: 'TinkoffMarketFormDataType';
  /** Список счетов пользователя */
  accounts?: Maybe<Array<Maybe<UserAccount>>>;
  /** Баланс */
  balance?: Maybe<Array<Maybe<TinkoffCurrencyPositionType>>>;
  /** Лучшая цена продажи */
  bestAsk?: Maybe<Scalars['Decimal']>;
  /** Инструмент */
  instrument?: Maybe<TinkoffInstrumentType>;
};

/** Состояние заявки */
export enum TinkoffOrderStatusEnum {
  New = 'NEW',
  PartiallyFill = 'PARTIALLY_FILL',
  Fill = 'FILL',
  Cancelled = 'CANCELLED',
  Replaced = 'REPLACED',
  PendingCancel = 'PENDING_CANCEL',
  Rejected = 'REJECTED',
  PendingReplace = 'PENDING_REPLACE',
  PendingNew = 'PENDING_NEW'
}

export type TinkoffPlacedMarketOrderType = {
  __typename?: 'TinkoffPlacedMarketOrderType';
  message?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<TinkoffOrderStatusEnum>;
};

/** Счёт пользователя в Тинькофф Инвестиции */
export type UserAccount = {
  __typename?: 'userAccount';
  /** id счёта у брокера */
  brokerAccountId: Scalars['String'];
  /** тип счёта */
  brokerAccountType?: Maybe<TinkoffBrokerAccountType>;
};

/** Видеокурс */
export type VideoCourse = {
  __typename?: 'videoCourse';
  /** Описание */
  description: Scalars['String'];
  /** Продолжительность */
  hours: Scalars['Seconds'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Был ли этот форм итем просмотрен ранее? */
  isViewed?: Maybe<Scalars['Boolean']>;
  /** Ссылка на скачивание/просмотр */
  link?: Maybe<Scalars['String']>;
  /** Материалы видеокурса */
  materials?: Maybe<Array<Maybe<Material>>>;
  /** Идентификатор изображения */
  pictureId?: Maybe<Scalars['Int']>;
  /** Форма продукта */
  productForm?: Maybe<ProductForm>;
  /** Тема для связи с другими формами */
  theme: Scalars['String'];
  /** Заголовок */
  title: Scalars['String'];
};

export type VideoCourseListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<VideoCourseOrderEnum>;
  title?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

/** Order the list of video courses */
export enum VideoCourseOrderEnum {
  Id = 'ID'
}

/** Запись о просмотренном форм итеме */
export type ViewedFormItem = {
  __typename?: 'viewedFormItem';
  /** Идентификатор пользователя */
  accountId: Scalars['Int'];
  /** Идентификатор форм итема */
  formItemId: Scalars['Int'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Тип формы продукта итема */
  productFormType?: Maybe<ProductFormPublicTypeEnum>;
};

/** Вебинар */
export type Webinar = {
  __typename?: 'webinar';
  /** Дата проведения */
  date?: Maybe<Scalars['DateTime']>;
  /** Программа */
  description: Scalars['String'];
  /** Запись вебинара */
  eventRecord?: Maybe<EventRecord>;
  /** Продолжительность */
  hours: Scalars['Seconds'];
  /** Идентификатор */
  id: Scalars['Int'];
  /** Преподаватели */
  lecturers?: Maybe<Array<Maybe<Lecturer>>>;
  /** Материалы вебинара */
  materials?: Maybe<Array<Maybe<Material>>>;
  /** Участие пользователя в вебинаре(null если не участвует) */
  myParticipation?: Maybe<WebinarToAccount>;
  /** Форма продукта */
  productForm?: Maybe<ProductForm>;
  /** Причина, почему не указано точное начало вебинара */
  soonCause?: Maybe<Scalars['String']>;
  /** Состояние синхронизации с площадкой вебинара */
  syncState?: Maybe<WebinarSyncAdminStateEnum>;
  /** Заголовок */
  title: Scalars['String'];
};

export type WebinarListRequest = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  full?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  order?: Maybe<WebinarOrderEnum>;
  title?: Maybe<Scalars['String']>;
  webinarPlatformId?: Maybe<Scalars['Int']>;
  productFormId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  lecturerId?: Maybe<Scalars['Int']>;
  isPastDate?: Maybe<Scalars['Boolean']>;
};

/** Order the list of webinars */
export enum WebinarOrderEnum {
  Id = 'ID',
  Date = 'DATE',
  WebinarPlatform = 'WEBINAR_PLATFORM'
}

/** Тип формы */
export enum WebinarSyncAdminStateEnum {
  Pending = 'PENDING',
  Error = 'ERROR',
  Finished = 'FINISHED',
  DidNotStart = 'DID_NOT_START'
}

/** Связка вебинаров и пользователей */
export type WebinarToAccount = {
  __typename?: 'webinarToAccount';
  /** Пользователь */
  account: Account;
  /** Идентификатор пользователя */
  accountId: Scalars['Int'];
  /** Идентификатор WebinarToAccount */
  id: Scalars['String'];
  /** Ссылка на участие */
  url: Scalars['String'];
  /** Вебинар */
  webinar: Webinar;
  /** Идентификатор вебинара */
  webinarId: Scalars['Int'];
};

export type HasAnyFutureCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type HasAnyFutureCoursesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasAnyFutureCourses'>
);

export type SyncWithCalendarOrderMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncWithCalendarOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncWithCalendarOrder'>
);

export type GetInCartFormsQueryVariables = Exact<{
  filter?: Maybe<ProductFormListRequest>;
}>;


export type GetInCartFormsQuery = (
  { __typename?: 'Query' }
  & { productForms?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & Pick<ListOperationResultTypeProductFormPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'type' | 'price' | 'maxOrders' | 'ordersCount' | 'paymentStatus' | 'requiredFieldsNumber' | 'showNumberLessons'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )>, product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name' | 'shortDescription' | 'offer'>
        & { contentProvider: (
          { __typename?: 'contentProvider' }
          & Pick<ContentProvider, 'id' | 'offer'>
        ), lecturers: Array<(
          { __typename?: 'lecturer' }
          & Pick<Lecturer, 'id'>
          & { account: (
            { __typename?: 'account' }
            & Pick<Account, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      ), withMeInSetForms: Array<(
        { __typename?: 'productForm' }
        & Pick<ProductForm, 'id' | 'type'>
      )>, webinars: Array<(
        { __typename?: 'webinar' }
        & Pick<Webinar, 'id' | 'date'>
      )>, seminars: Array<(
        { __typename?: 'seminar' }
        & Pick<Seminar, 'id' | 'date'>
      )>, records: Array<(
        { __typename?: 'eventRecord' }
        & Pick<EventRecord, 'id' | 'date'>
      )> }
    )>> }
  )> }
);

export type GetPurchasedProductsQueryVariables = Exact<{
  filter?: Maybe<ProductListRequest>;
}>;


export type GetPurchasedProductsQuery = (
  { __typename?: 'Query' }
  & { purchasedProducts?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductPublicType' }
    & Pick<ListOperationResultTypeProductPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'shortDescription' | 'lastPurchasedDate' | 'isFree' | 'isArchive'>
      & { lecturers: Array<(
        { __typename?: 'lecturer' }
        & Pick<Lecturer, 'id'>
        & { account: (
          { __typename?: 'account' }
          & Pick<Account, 'id' | 'firstName' | 'lastName'>
        ) }
      )> }
    )>> }
  )> }
);

export type GetCourseCalenderDataQueryVariables = Exact<{
  filter?: Maybe<OrderListRequest>;
}>;


export type GetCourseCalenderDataQuery = (
  { __typename?: 'Query' }
  & { extendedOrders?: Maybe<(
    { __typename?: 'ListOperationResultTypeExtendedOrderPublicType' }
    & Pick<ListOperationResultTypeExtendedOrderPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'extendedOrder' }
      & Pick<ExtendedOrder, 'id' | 'courseDate' | 'courseTitle' | 'courseHours' | 'productName' | 'state' | 'productId'>
    )>> }
  )> }
);

export type UpdateMyAccountMutationVariables = Exact<{
  account: AccountPublicUpdateInput;
}>;


export type UpdateMyAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount?: Maybe<(
    { __typename?: 'MyAccount' }
    & Pick<MyAccount, 'id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'phones' | 'vkLink' | 'facebookLink' | 'telegramUserName'>
    & { accountSubscription?: Maybe<(
      { __typename?: 'accountSubscription' }
      & AccountSubscriptionFragmentFragment
    )> }
  )> }
);

export type DisableSubscriptionAutopaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableSubscriptionAutopaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableSubscriptionAutopayment'>
);

export type SyncedAccountSubscriptionsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncedAccountSubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & { syncedAccountSubscriptions?: Maybe<(
    { __typename?: 'MyAccount' }
    & Pick<MyAccount, 'id'>
    & { accountSubscription?: Maybe<(
      { __typename?: 'accountSubscription' }
      & AccountSubscriptionFragmentFragment
    )> }
  )> }
);

export type MailChimpSubscribeMutationVariables = Exact<{ [key: string]: never; }>;


export type MailChimpSubscribeMutation = (
  { __typename?: 'Mutation' }
  & { subscribe?: Maybe<(
    { __typename?: 'MyAccount' }
    & Pick<MyAccount, 'id'>
    & { accountSubscription?: Maybe<(
      { __typename?: 'accountSubscription' }
      & AccountSubscriptionFragmentFragment
    )> }
  )> }
);

export type GetArticleAuthorsQueryVariables = Exact<{
  filter?: Maybe<AccountListRequest>;
}>;


export type GetArticleAuthorsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<(
    { __typename?: 'ListOperationResultTypeAccountPublicType' }
    & { result: Array<Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName'>
    )>> }
  )> }
);

export type GetArticleQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetArticleQuery = (
  { __typename?: 'Query' }
  & { articleBySlug?: Maybe<(
    { __typename?: 'article' }
    & Pick<Article, 'id' | 'title' | 'slug' | 'shortDescription' | 'content' | 'isVisible' | 'date' | 'pictureBigId' | 'pictureSmallId' | 'pageViews' | 'tags'>
    & { account?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName'>
    )>, suggestedProduct?: Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'pictureId'>
    )>, suggestedArticles?: Maybe<Array<Maybe<(
      { __typename?: 'article' }
      & Pick<Article, 'id' | 'title' | 'slug' | 'pictureBigId' | 'tags' | 'date'>
      & { account?: Maybe<(
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
  )> }
);

export type GetArticlesQueryVariables = Exact<{
  filter?: Maybe<ArticleListRequest>;
}>;


export type GetArticlesQuery = (
  { __typename?: 'Query' }
  & { articles?: Maybe<(
    { __typename?: 'ListOperationResultTypeArticlePublicType' }
    & Pick<ListOperationResultTypeArticlePublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'article' }
      & Pick<Article, 'id' | 'title' | 'slug' | 'shortDescription' | 'content' | 'isVisible' | 'date' | 'pictureBigId' | 'pictureSmallId' | 'pageViews' | 'tags'>
      & { account?: Maybe<(
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName'>
      )>, suggestedArticles?: Maybe<Array<Maybe<(
        { __typename?: 'article' }
        & Pick<Article, 'id' | 'title' | 'slug' | 'pictureBigId' | 'tags' | 'date'>
        & { account?: Maybe<(
          { __typename?: 'account' }
          & Pick<Account, 'id' | 'firstName' | 'lastName'>
        )> }
      )>>> }
    )>> }
  )> }
);

export type AccountSubscriptionFragmentFragment = (
  { __typename?: 'accountSubscription' }
  & Pick<AccountSubscription, 'id' | 'notPayedNotify' | 'courseStartNotify' | 'courseEndNotify' | 'mondayRegularSubscription' | 'thursdayRegularSubscription' | 'newCourseSubscription' | 'nearestCoursesSubscription' | 'weekCoursesSubscription' | 'subscriptionAutopayment' | 'courseSuccessRegistrationNotify' | 'ofzDisclaimerAgree' | 'welcomeEmails' | 'service52Weeks'>
);

export type GetMyAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAccountQuery = (
  { __typename?: 'Query' }
  & { myAccount?: Maybe<(
    { __typename?: 'MyAccount' }
    & Pick<MyAccount, 'id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'phones' | 'vkLink' | 'facebookLink' | 'telegramUserName' | 'refLink' | 'refPoints' | 'hasTinkoffInvest' | 'accessToFreeForms'>
    & { accountSubscription?: Maybe<(
      { __typename?: 'accountSubscription' }
      & AccountSubscriptionFragmentFragment
    )>, subscription: (
      { __typename?: 'ActiveSubscriptionPublicType' }
      & Pick<ActiveSubscriptionPublicType, 'autopayment' | 'until' | 'type'>
    ), subscriptionFreeCourses: (
      { __typename?: 'ActiveSubscriptionPublicType' }
      & Pick<ActiveSubscriptionPublicType, 'until'>
    ), subscriptionCorporateBonds: (
      { __typename?: 'ActiveSubscriptionPublicType' }
      & Pick<ActiveSubscriptionPublicType, 'until'>
    ) }
  )> }
);

export type AgreeOfzDisclaimerMutationVariables = Exact<{ [key: string]: never; }>;


export type AgreeOfzDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { agreeOfzDisclaimer?: Maybe<(
    { __typename?: 'accountSubscription' }
    & Pick<AccountSubscription, 'id' | 'ofzDisclaimerAgree'>
  )> }
);

export type BuildPortfolioQueryVariables = Exact<{
  request: BuildPortfolioRequest;
}>;


export type BuildPortfolioQuery = (
  { __typename?: 'Query' }
  & { buildPortfolio?: Maybe<(
    { __typename?: 'bpPortfolio' }
    & Pick<BpPortfolio, 'nominal' | 'yieldToMaturity'>
    & { positions?: Maybe<Array<Maybe<(
      { __typename?: 'bpPosition' }
      & Pick<BpPosition, 'quantity' | 'nominal'>
      & { bond?: Maybe<(
        { __typename?: 'bpBond' }
        & Pick<BpBond, 'secId' | 'shortName' | 'maturityDate' | 'couponValue' | 'offerDate'>
      )>, calendar?: Maybe<(
        { __typename?: 'bpPaymentsCalendar' }
        & { payments?: Maybe<Array<Maybe<(
          { __typename?: 'bpPayment' }
          & Pick<BpPayment, 'number' | 'date' | 'amount'>
        )>>> }
      )> }
    )>>>, calendar?: Maybe<(
      { __typename?: 'bpPaymentsCalendar' }
      & { payments?: Maybe<Array<Maybe<(
        { __typename?: 'bpPayment' }
        & Pick<BpPayment, 'number' | 'date' | 'amount'>
        & { position?: Maybe<(
          { __typename?: 'bpPosition' }
          & { bond?: Maybe<(
            { __typename?: 'bpBond' }
            & Pick<BpBond, 'secId' | 'shortName' | 'maturityDate' | 'offerDateEnd'>
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type GetBrokersQueryVariables = Exact<{
  filter?: Maybe<BrokerListRequest>;
}>;


export type GetBrokersQuery = (
  { __typename?: 'Query' }
  & { brokers?: Maybe<(
    { __typename?: 'ListOperationResultTypeBrokerPublicType' }
    & Pick<ListOperationResultTypeBrokerPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'broker' }
      & Pick<Broker, 'id' | 'name' | 'link' | 'about' | 'commission' | 'minimum' | 'features' | 'sentence' | 'position' | 'pictureId'>
    )>> }
  )> }
);

export type GetCourseFilterTagsQueryVariables = Exact<{
  filter?: Maybe<TagListRequest>;
}>;


export type GetCourseFilterTagsQuery = (
  { __typename?: 'Query' }
  & { tags?: Maybe<(
    { __typename?: 'ListOperationResultTypeTagPublicType' }
    & { result: Array<Maybe<(
      { __typename?: 'tag' }
      & Pick<Tag, 'id' | 'name' | 'description'>
    )>> }
  )> }
);

export type GetCourseListQueryVariables = Exact<{
  filter?: Maybe<ProductFormListRequest>;
}>;


export type GetCourseListQuery = (
  { __typename?: 'Query' }
  & { productForms?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & Pick<ListOperationResultTypeProductFormPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'type' | 'price' | 'title' | 'maxOrders' | 'showRemainingOrders' | 'ordersCount' | 'paymentStatus' | 'requiredFieldsNumber' | 'subscriptionAccess' | 'showNumberLessons'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )>, product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name' | 'shortDescription' | 'pictureId' | 'offer'>
        & { contentProvider: (
          { __typename?: 'contentProvider' }
          & Pick<ContentProvider, 'id' | 'offer'>
        ), lecturers: Array<(
          { __typename?: 'lecturer' }
          & Pick<Lecturer, 'id'>
          & { account: (
            { __typename?: 'account' }
            & Pick<Account, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      ), withMeInSetForms: Array<(
        { __typename?: 'productForm' }
        & Pick<ProductForm, 'id' | 'type' | 'subscriptionAccess' | 'price'>
      )>, webinars: Array<(
        { __typename?: 'webinar' }
        & Pick<Webinar, 'id' | 'date' | 'soonCause'>
      )>, seminars: Array<(
        { __typename?: 'seminar' }
        & Pick<Seminar, 'id' | 'date' | 'soonCause'>
      )>, records: Array<(
        { __typename?: 'eventRecord' }
        & Pick<EventRecord, 'id' | 'date'>
      )> }
    )>> }
  )> }
);

export type GetDividendDetailQueryVariables = Exact<{
  symbol: Scalars['String'];
}>;


export type GetDividendDetailQuery = (
  { __typename?: 'Query' }
  & { dividend: (
    { __typename?: 'dividendShare' }
    & Pick<DividendShare, 'lastUpdate' | 'historyErrorMessage' | 'price' | 'name' | 'target'>
    & { candles: Array<(
      { __typename?: 'dividendCandle' }
      & Pick<DividendCandle, 'time' | 'open' | 'close' | 'high' | 'low'>
    )>, history?: Maybe<Array<(
      { __typename?: 'dividendHistory' }
      & Pick<DividendHistory, 'period' | 'lastDayToBuy' | 'registryClose' | 'dividend' | 'paymentYield' | 'yield' | 'paid' | 'timeGap' | 'gapClosed' | 'gapDawn'>
    )>>, security: (
      { __typename?: 'highLowSecurity' }
      & Pick<HighLowSecurity, 'secId' | 'name' | 'high' | 'highDate' | 'low' | 'lowDate' | 'current' | 'currentDate' | 'position' | 'amplitude' | 'highPercent' | 'lowPercent' | 'd1' | 'w1' | 'm1' | 'y1' | 'yTD' | 'moscowExchangesIndex' | 'moscowExchangeBlueChipIndex'>
    ) }
  ) }
);

export type GetDividendsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDividendsListQuery = (
  { __typename?: 'Query' }
  & { dividends: Array<(
    { __typename?: 'dividendShare' }
    & Pick<DividendShare, 'lastUpdate' | 'moscowExchangesIndex' | 'moscowExchangeBlueChipIndex' | 'name' | 'ticker' | 'price' | 'yearYield' | 'nearestDividendPayout' | 'dividendsCountPerYear' | 'dividendYield' | 'lastDayToBuy' | 'payYears' | 'increaseYears' | 'gapClosing' | 'lastDividendGap' | 'lastDividendGapClosed'>
  )> }
);

export type GetFeedbacksToProductQueryVariables = Exact<{
  filter?: Maybe<FeedbackToProductListRequest>;
}>;


export type GetFeedbacksToProductQuery = (
  { __typename?: 'Query' }
  & { feedbackToProducts?: Maybe<(
    { __typename?: 'ListOperationResultTypeFeedbackToProductPublicType' }
    & Pick<ListOperationResultTypeFeedbackToProductPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'feedbackToProduct' }
      & Pick<FeedbackToProduct, 'id' | 'accountName' | 'rating' | 'date' | 'dateUpdate' | 'description'>
    )>> }
  )> }
);

export type GetFeedbackProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFeedbackProductQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'hasFeedback' | 'hasAnyPurchasedForm'>
  )> }
);

export type CreateFeedbackToProductMutationVariables = Exact<{
  feedbackToProduct: FeedbackToProductPublicInsertInput;
}>;


export type CreateFeedbackToProductMutation = (
  { __typename?: 'Mutation' }
  & { createFeedbackToProduct?: Maybe<(
    { __typename?: 'feedbackToProduct' }
    & Pick<FeedbackToProduct, 'id'>
  )> }
);

export type UpdateFeedbackToProductMutationVariables = Exact<{
  feedbackToProduct: FeedbackToProductPublicUpdateInput;
}>;


export type UpdateFeedbackToProductMutation = (
  { __typename?: 'Mutation' }
  & { updateFeedbackToProduct?: Maybe<(
    { __typename?: 'feedbackToProduct' }
    & Pick<FeedbackToProduct, 'id'>
  )> }
);

export type FinancialCalculatorGetQueryVariables = Exact<{
  input: FinancialCalculatorGetInput;
}>;


export type FinancialCalculatorGetQuery = (
  { __typename?: 'Query' }
  & { financialCalculatorGet: (
    { __typename?: 'financialCalculatorGetResult' }
    & Pick<FinancialCalculatorGetResult, 'amount' | 'capital' | 'investments' | 'total' | 'investmentYield'>
  ) }
);

export type FinancialCalculatorQuestionGetQueryVariables = Exact<{
  input: FinancialCalculatorQuestionGetInput;
}>;


export type FinancialCalculatorQuestionGetQuery = (
  { __typename?: 'Query' }
  & { financialCalculatorQuestionGet: (
    { __typename?: 'financialCalculatorQuestionGetResult' }
    & Pick<FinancialCalculatorQuestionGetResult, 'yearResult' | 'amountResult' | 'amountResultInflation' | 'capitalResult' | 'capitalResultInflation' | 'investmentsResult'>
  ) }
);

export type GetHighLowDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHighLowDataQuery = (
  { __typename?: 'Query' }
  & { highLowData?: Maybe<Array<Maybe<(
    { __typename?: 'highLowSecurity' }
    & Pick<HighLowSecurity, 'secId' | 'name' | 'high' | 'highDate' | 'low' | 'lowDate' | 'current' | 'currentDate' | 'position' | 'amplitude' | 'highPercent' | 'lowPercent' | 'd1' | 'w1' | 'm1' | 'y1' | 'yTD' | 'moscowExchangesIndex' | 'moscowExchangeBlueChipIndex' | 'index' | 'eTF'>
  )>>> }
);

export type GetLecturerQueryVariables = Exact<{
  id: Scalars['Int'];
  productsFilter?: Maybe<ProductListRequest>;
}>;


export type GetLecturerQuery = (
  { __typename?: 'Query' }
  & { lecturer?: Maybe<(
    { __typename?: 'lecturer' }
    & Pick<Lecturer, 'id' | 'education' | 'skill' | 'about'>
    & { account: (
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName' | 'middleName' | 'pictureId'>
    ), products: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  )> }
);

export type GetLecturersQueryVariables = Exact<{
  lecturersFilter?: Maybe<LecturerListRequest>;
  productsFilter?: Maybe<ProductListRequest>;
}>;


export type GetLecturersQuery = (
  { __typename?: 'Query' }
  & { lecturers?: Maybe<(
    { __typename?: 'ListOperationResultTypeLecturerPublicType' }
    & Pick<ListOperationResultTypeLecturerPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'lecturer' }
      & Pick<Lecturer, 'id' | 'education' | 'skill' | 'about'>
      & { account: (
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName' | 'middleName' | 'pictureId'>
      ), products: Array<(
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type MainPageQueryVariables = Exact<{
  pageFilter?: Maybe<PageListRequest>;
}>;


export type MainPageQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<(
    { __typename?: 'ListOperationResultTypeAccountPublicType' }
    & Pick<ListOperationResultTypeAccountPublicType, 'count'>
  )>, pages?: Maybe<(
    { __typename?: 'ListOperationResultTypePagePublicType' }
    & Pick<ListOperationResultTypePagePublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'page' }
      & Pick<Page, 'id' | 'title' | 'slug' | 'content' | 'shortDescription' | 'type'>
    )>> }
  )> }
);

export type MainCoursesQueryVariables = Exact<{
  pageFilter?: Maybe<PageListRequest>;
  productFormFilter?: Maybe<ProductFormListRequest>;
}>;


export type MainCoursesQuery = (
  { __typename?: 'Query' }
  & { pages?: Maybe<(
    { __typename?: 'ListOperationResultTypePagePublicType' }
    & Pick<ListOperationResultTypePagePublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'page' }
      & Pick<Page, 'id' | 'title' | 'slug' | 'content' | 'shortDescription' | 'type'>
    )>> }
  )>, productForms?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & Pick<ListOperationResultTypeProductFormPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'type' | 'price' | 'title' | 'maxOrders' | 'ordersCount' | 'paymentStatus' | 'requiredFieldsNumber' | 'showRemainingOrders' | 'showNumberLessons' | 'pictureId'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )>, product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name' | 'shortDescription' | 'pictureId' | 'offer'>
        & { contentProvider: (
          { __typename?: 'contentProvider' }
          & Pick<ContentProvider, 'id' | 'offer'>
        ), lecturers: Array<(
          { __typename?: 'lecturer' }
          & Pick<Lecturer, 'id'>
          & { account: (
            { __typename?: 'account' }
            & Pick<Account, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      ), withMeInSetForms: Array<(
        { __typename?: 'productForm' }
        & Pick<ProductForm, 'id' | 'type'>
      )>, webinars: Array<(
        { __typename?: 'webinar' }
        & Pick<Webinar, 'id' | 'date' | 'soonCause'>
      )>, seminars: Array<(
        { __typename?: 'seminar' }
        & Pick<Seminar, 'id' | 'date' | 'soonCause'>
      )>, records: Array<(
        { __typename?: 'eventRecord' }
        & Pick<EventRecord, 'id' | 'date'>
      )> }
    )>> }
  )> }
);

export type GetPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetPageQuery = (
  { __typename?: 'Query' }
  & { pageBySlug?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'id' | 'slug' | 'title' | 'content'>
  )> }
);

export type GetTextBlockQueryVariables = Exact<{
  slug: Scalars['String'];
  withTextBlocks?: Maybe<Scalars['Boolean']>;
}>;


export type GetTextBlockQuery = (
  { __typename?: 'Query' }
  & { pageBySlug?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'id' | 'slug' | 'content'>
  )> }
);

export type GetPartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPartnersQuery = (
  { __typename?: 'Query' }
  & { partners: Array<(
    { __typename?: 'partner' }
    & Pick<Partner, 'id' | 'name' | 'link' | 'position' | 'pictureId'>
  )> }
);

export type DeleteOrderMutationVariables = Exact<{
  order: OrderPublicDeletetInput;
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
    & { productForm: (
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'paymentStatus'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )> }
    ) }
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  order: OrderPublicInsertInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
    & { productForm: (
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'paymentStatus' | 'type' | 'price'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )>, product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    ), inviteLink?: Maybe<(
      { __typename?: 'inviteLink' }
      & Pick<InviteLink, 'id' | 'dateExpire' | 'date' | 'link' | 'accountId' | 'orderId'>
    )> }
  )> }
);

export type CreatePaymentMutationVariables = Exact<{
  arguments: PaymentPublicInsertInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment?: Maybe<(
    { __typename?: 'payment' }
    & Pick<Payment, 'id' | 'confirmationUrl' | 'price'>
  )> }
);

export type GetPrivateProductDetailDataQueryVariables = Exact<{
  id: Scalars['Int'];
  filter?: Maybe<ProductFormListRequest>;
}>;


export type GetPrivateProductDetailDataQuery = (
  { __typename?: 'Query' }
  & { purchasedProduct?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'shortDescription' | 'hasAnyPurchasedForm' | 'hasFeedback' | 'pictureId'>
    & { lecturers: Array<(
      { __typename?: 'lecturer' }
      & Pick<Lecturer, 'id'>
      & { account: (
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName' | 'pictureId'>
      ) }
    )>, inviteLink?: Maybe<(
      { __typename?: 'inviteLink' }
      & Pick<InviteLink, 'id' | 'dateExpire' | 'date' | 'link' | 'orderId' | 'accountId'>
    )>, productForms: Array<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'title' | 'type' | 'subscriptionAccess'>
      & { order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id' | 'accessUntil'>
      )>, webinars: Array<(
        { __typename?: 'webinar' }
        & Pick<Webinar, 'id' | 'date' | 'title' | 'hours' | 'soonCause' | 'description' | 'syncState'>
        & { myParticipation?: Maybe<(
          { __typename?: 'webinarToAccount' }
          & Pick<WebinarToAccount, 'id' | 'url'>
        )>, eventRecord?: Maybe<(
          { __typename?: 'eventRecord' }
          & Pick<EventRecord, 'id' | 'link'>
        )>, materials?: Maybe<Array<Maybe<(
          { __typename?: 'material' }
          & Pick<Material, 'id' | 'link'>
        )>>>, lecturers?: Maybe<Array<Maybe<(
          { __typename?: 'lecturer' }
          & Pick<Lecturer, 'id'>
          & { account: (
            { __typename?: 'account' }
            & Pick<Account, 'id' | 'firstName' | 'lastName' | 'pictureId'>
          ) }
        )>>> }
      )>, seminars: Array<(
        { __typename?: 'seminar' }
        & Pick<Seminar, 'id' | 'date' | 'title' | 'hours' | 'description'>
        & { eventRecord?: Maybe<(
          { __typename?: 'eventRecord' }
          & Pick<EventRecord, 'id' | 'link'>
        )>, materials?: Maybe<Array<Maybe<(
          { __typename?: 'material' }
          & Pick<Material, 'id' | 'link'>
        )>>>, lecturers?: Maybe<Array<Maybe<(
          { __typename?: 'lecturer' }
          & Pick<Lecturer, 'id'>
          & { account: (
            { __typename?: 'account' }
            & Pick<Account, 'id' | 'firstName' | 'lastName' | 'pictureId'>
          ) }
        )>>> }
      )>, records: Array<(
        { __typename?: 'eventRecord' }
        & Pick<EventRecord, 'id' | 'title' | 'hours' | 'description' | 'pictureId' | 'link' | 'date' | 'isViewed'>
      )>, materials: Array<(
        { __typename?: 'material' }
        & Pick<Material, 'id' | 'title' | 'description' | 'link' | 'date'>
      )>, videoCourses: Array<(
        { __typename?: 'videoCourse' }
        & Pick<VideoCourse, 'id' | 'title' | 'hours' | 'description' | 'pictureId' | 'link' | 'isViewed'>
      )>, certificates: Array<(
        { __typename?: 'certificate' }
        & Pick<Certificate, 'id' | 'name' | 'state'>
        & { myCertificate?: Maybe<(
          { __typename?: 'certificateToAccount' }
          & Pick<CertificateToAccount, 'id' | 'number'>
        )> }
      )> }
    )> }
  )> }
);

export type ProductFormQueryForPaymentFragmentFragment = (
  { __typename?: 'productForm' }
  & Pick<ProductForm, 'id' | 'type' | 'price' | 'title' | 'maxOrders' | 'showRemainingOrders' | 'ordersCount' | 'paymentStatus' | 'pictureId' | 'requiredFieldsNumber'>
  & { order?: Maybe<(
    { __typename?: 'order' }
    & Pick<Order, 'id'>
  )>, product: (
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'offer'>
    & { contentProvider: (
      { __typename?: 'contentProvider' }
      & Pick<ContentProvider, 'id' | 'offer'>
    ) }
  ), webinars: Array<(
    { __typename?: 'webinar' }
    & Pick<Webinar, 'id' | 'date'>
  )>, seminars: Array<(
    { __typename?: 'seminar' }
    & Pick<Seminar, 'id' | 'date'>
  )>, records: Array<(
    { __typename?: 'eventRecord' }
    & Pick<EventRecord, 'id' | 'date'>
  )> }
);

export type GetProductDetailDataQueryVariables = Exact<{
  id: Scalars['Int'];
  contentProviderId?: Maybe<Scalars['Int']>;
  showPublic?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProductFormListRequest>;
}>;


export type GetProductDetailDataQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'shortDescription' | 'description' | 'salesPoints' | 'warnings' | 'complexity' | 'hasAnyPurchasedForm' | 'hasFeedback' | 'tagsString' | 'pictureId'>
    & { contentProvider: (
      { __typename?: 'contentProvider' }
      & Pick<ContentProvider, 'id' | 'name' | 'offer' | 'code'>
    ), lecturers: Array<(
      { __typename?: 'lecturer' }
      & Pick<Lecturer, 'id' | 'skill'>
      & { account: (
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName' | 'pictureId'>
      ) }
    )>, productForms: Array<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'title' | 'type' | 'price' | 'description' | 'paymentStatus' | 'requiredFieldsNumber' | 'maxOrders' | 'showRemainingOrders' | 'showNumberLessons' | 'ordersCount' | 'isNonSingle' | 'suggestedTitle' | 'subscriptionAccess'>
      & { suggestedProductForm?: Maybe<(
        { __typename?: 'productForm' }
        & ProductFormQueryForPaymentFragmentFragment
      )>, order?: Maybe<(
        { __typename?: 'order' }
        & Pick<Order, 'id'>
      )>, product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name' | 'offer'>
        & { contentProvider: (
          { __typename?: 'contentProvider' }
          & Pick<ContentProvider, 'id' | 'offer'>
        ) }
      ), withMeInSetForms: Array<(
        { __typename?: 'productForm' }
        & Pick<ProductForm, 'id' | 'title' | 'type' | 'subscriptionAccess' | 'price'>
        & { webinars: Array<(
          { __typename?: 'webinar' }
          & Pick<Webinar, 'id'>
        )>, seminars: Array<(
          { __typename?: 'seminar' }
          & Pick<Seminar, 'id'>
        )>, records: Array<(
          { __typename?: 'eventRecord' }
          & Pick<EventRecord, 'id'>
        )>, materials: Array<(
          { __typename?: 'material' }
          & Pick<Material, 'id'>
        )>, certificates: Array<(
          { __typename?: 'certificate' }
          & Pick<Certificate, 'id'>
        )>, videoCourses: Array<(
          { __typename?: 'videoCourse' }
          & Pick<VideoCourse, 'id'>
        )> }
      )>, webinars: Array<(
        { __typename?: 'webinar' }
        & Pick<Webinar, 'id' | 'date' | 'hours' | 'title' | 'soonCause'>
      )>, seminars: Array<(
        { __typename?: 'seminar' }
        & Pick<Seminar, 'id' | 'date' | 'hours' | 'title' | 'soonCause'>
      )>, records: Array<(
        { __typename?: 'eventRecord' }
        & Pick<EventRecord, 'id' | 'title' | 'date'>
      )>, materials: Array<(
        { __typename?: 'material' }
        & Pick<Material, 'id' | 'title'>
      )>, certificates: Array<(
        { __typename?: 'certificate' }
        & Pick<Certificate, 'id'>
      )>, videoCourses: Array<(
        { __typename?: 'videoCourse' }
        & Pick<VideoCourse, 'id' | 'title'>
      )> }
    )> }
  )> }
);

export type AgreementProductOfferMutationVariables = Exact<{
  productFormId: Scalars['Int'];
}>;


export type AgreementProductOfferMutation = (
  { __typename?: 'Mutation' }
  & { agreementProductOffer: (
    { __typename?: 'productForm' }
    & Pick<ProductForm, 'id' | 'hasProductAgreement'>
  ) }
);

export type GetPayProductFormQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPayProductFormQuery = (
  { __typename?: 'Query' }
  & { productForm?: Maybe<(
    { __typename?: 'productForm' }
    & Pick<ProductForm, 'id' | 'type' | 'title' | 'price' | 'paymentStatus' | 'requiredFieldsNumber' | 'hasProductAgreement'>
    & { product: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'offer' | 'name' | 'tagsString'>
      & { contentProvider: (
        { __typename?: 'contentProvider' }
        & Pick<ContentProvider, 'id' | 'offer' | 'name'>
      ) }
    ), order?: Maybe<(
      { __typename?: 'order' }
      & Pick<Order, 'id'>
    )>, records: Array<(
      { __typename?: 'eventRecord' }
      & Pick<EventRecord, 'id' | 'date'>
    )>, webinars: Array<(
      { __typename?: 'webinar' }
      & Pick<Webinar, 'id' | 'date'>
    )>, seminars: Array<(
      { __typename?: 'seminar' }
      & Pick<Seminar, 'id' | 'date'>
    )> }
  )> }
);

export type GetFeedbacksQueryVariables = Exact<{
  filter?: Maybe<FeedbackToProductListRequest>;
}>;


export type GetFeedbacksQuery = (
  { __typename?: 'Query' }
  & { feedbackToProducts?: Maybe<(
    { __typename?: 'ListOperationResultTypeFeedbackToProductPublicType' }
    & Pick<ListOperationResultTypeFeedbackToProductPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'feedbackToProduct' }
      & Pick<FeedbackToProduct, 'id' | 'accountName' | 'rating' | 'date' | 'dateUpdate' | 'description'>
      & { product?: Maybe<(
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type SearchNearestCoursesQueryVariables = Exact<{
  filter?: Maybe<ProductFormListRequest>;
}>;


export type SearchNearestCoursesQuery = (
  { __typename?: 'Query' }
  & { productForms?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & SearchResultProductFormFragmentFragment
  )> }
);

export type SearchResultProductFormFragmentFragment = (
  { __typename?: 'ListOperationResultTypeProductFormPublicType' }
  & Pick<ListOperationResultTypeProductFormPublicType, 'count'>
  & { result: Array<Maybe<(
    { __typename?: 'productForm' }
    & Pick<ProductForm, 'id' | 'type' | 'price' | 'title' | 'maxOrders' | 'ordersCount' | 'paymentStatus' | 'requiredFieldsNumber' | 'showRemainingOrders' | 'showNumberLessons'>
    & { order?: Maybe<(
      { __typename?: 'order' }
      & Pick<Order, 'id'>
    )>, product: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'shortDescription' | 'pictureId' | 'offer'>
      & { contentProvider: (
        { __typename?: 'contentProvider' }
        & Pick<ContentProvider, 'id' | 'offer'>
      ), lecturers: Array<(
        { __typename?: 'lecturer' }
        & Pick<Lecturer, 'id'>
        & { account: (
          { __typename?: 'account' }
          & Pick<Account, 'id' | 'firstName' | 'lastName'>
        ) }
      )> }
    ), withMeInSetForms: Array<(
      { __typename?: 'productForm' }
      & Pick<ProductForm, 'id' | 'type'>
    )>, webinars: Array<(
      { __typename?: 'webinar' }
      & Pick<Webinar, 'id' | 'date'>
    )>, seminars: Array<(
      { __typename?: 'seminar' }
      & Pick<Seminar, 'id' | 'date'>
    )>, records: Array<(
      { __typename?: 'eventRecord' }
      & Pick<EventRecord, 'id' | 'date'>
    )> }
  )>> }
);

export type SearchResultArticleFragmentFragment = (
  { __typename?: 'ListOperationResultTypeArticlePublicType' }
  & Pick<ListOperationResultTypeArticlePublicType, 'count'>
  & { result: Array<Maybe<(
    { __typename?: 'article' }
    & Pick<Article, 'id' | 'title' | 'slug' | 'shortDescription' | 'content' | 'isVisible' | 'date' | 'pictureBigId' | 'pictureSmallId' | 'pageViews' | 'tags'>
    & { account?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName'>
    )>, suggestedArticles?: Maybe<Array<Maybe<(
      { __typename?: 'article' }
      & Pick<Article, 'id' | 'title' | 'slug' | 'pictureBigId' | 'tags' | 'date'>
      & { account?: Maybe<(
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
  )>> }
);

export type SearchingQueryVariables = Exact<{
  coursesFilter?: Maybe<ProductFormListRequest>;
  videoCoursesFilter?: Maybe<ProductFormListRequest>;
  materialsFilter?: Maybe<ProductFormListRequest>;
  articlesFilter?: Maybe<ArticleListRequest>;
  lecturersFilter?: Maybe<LecturerListRequest>;
  lectureProductsFilter?: Maybe<ProductListRequest>;
}>;


export type SearchingQuery = (
  { __typename?: 'Query' }
  & { courses?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & SearchResultProductFormFragmentFragment
  )>, videoCourses?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & SearchResultProductFormFragmentFragment
  )>, materials?: Maybe<(
    { __typename?: 'ListOperationResultTypeProductFormPublicType' }
    & SearchResultProductFormFragmentFragment
  )>, articles?: Maybe<(
    { __typename?: 'ListOperationResultTypeArticlePublicType' }
    & SearchResultArticleFragmentFragment
  )>, lecturers?: Maybe<(
    { __typename?: 'ListOperationResultTypeLecturerPublicType' }
    & Pick<ListOperationResultTypeLecturerPublicType, 'count'>
    & { result: Array<Maybe<(
      { __typename?: 'lecturer' }
      & Pick<Lecturer, 'id'>
      & { account: (
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'firstName' | 'lastName' | 'middleName' | 'pictureId'>
      ), products: Array<(
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetSeoQueryVariables = Exact<{
  pathname: Scalars['String'];
}>;


export type GetSeoQuery = (
  { __typename?: 'Query' }
  & { seoPageInfoByPathname?: Maybe<(
    { __typename?: 'seoPageInfo' }
    & Pick<SeoPageInfo, 'id' | 'pathname' | 'title' | 'keywords' | 'description' | 'pictureId'>
  )> }
);

export type Start52weeksMutationVariables = Exact<{
  date: Scalars['Date'];
  sum: Scalars['Decimal'];
  target?: Maybe<Scalars['String']>;
  currency: CurrencyEnum;
}>;


export type Start52weeksMutation = (
  { __typename?: 'Mutation' }
  & { start52weeks?: Maybe<(
    { __typename?: 'service52WeeksUser' }
    & Pick<Service52WeeksUser, 'id' | 'startDate' | 'startSum' | 'target' | 'currency'>
    & { investments: Array<(
      { __typename?: 'service52WeeksInvestment' }
      & Pick<Service52WeeksInvestment, 'id' | 'sum' | 'weekNumber'>
    )> }
  )> }
);

export type Reset52weeksMutationVariables = Exact<{ [key: string]: never; }>;


export type Reset52weeksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reset52weeks'>
);

export type Get52WeeksSuggestedProductsQueryVariables = Exact<{
  id1: Scalars['Int'];
  id2: Scalars['Int'];
}>;


export type Get52WeeksSuggestedProductsQuery = (
  { __typename?: 'Query' }
  & { suggestedProduct1?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'pictureId' | 'name'>
  )>, suggestedProduct2?: Maybe<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'pictureId' | 'name'>
  )> }
);

export type Get52weeksQueryVariables = Exact<{ [key: string]: never; }>;


export type Get52weeksQuery = (
  { __typename?: 'Query' }
  & { get52weeks?: Maybe<(
    { __typename?: 'service52WeeksUser' }
    & Pick<Service52WeeksUser, 'id' | 'startDate' | 'startSum' | 'target' | 'currency'>
    & { investments: Array<(
      { __typename?: 'service52WeeksInvestment' }
      & Pick<Service52WeeksInvestment, 'id' | 'sum' | 'weekNumber'>
    )> }
  )> }
);

export type Update52weeksMutationVariables = Exact<{
  weekNumber: Scalars['Int'];
  sum: Scalars['Decimal'];
}>;


export type Update52weeksMutation = (
  { __typename?: 'Mutation' }
  & { update52weeks?: Maybe<(
    { __typename?: 'service52WeeksUser' }
    & Pick<Service52WeeksUser, 'id' | 'startDate' | 'startSum'>
    & { investments: Array<(
      { __typename?: 'service52WeeksInvestment' }
      & Pick<Service52WeeksInvestment, 'id' | 'sum' | 'weekNumber'>
    )> }
  )> }
);

export type GetServiceMarketMapDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceMarketMapDataQuery = (
  { __typename?: 'Query' }
  & { highLowData?: Maybe<Array<Maybe<(
    { __typename?: 'highLowSecurity' }
    & Pick<HighLowSecurity, 'issueCapitalization' | 'secId' | 'name' | 'moscowExchangesIndex' | 'moscowExchangeBlueChipIndex' | 'index' | 'eTF' | 'd1' | 'w1' | 'm1' | 'y1' | 'yTD' | 'currentDate'>
  )>>> }
);

export type CreateSubscriptionMutationVariables = Exact<{
  returnUrl: Scalars['String'];
  type: SubscriptionPublicTypeEnum;
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createSubscription?: Maybe<(
    { __typename?: 'payment' }
    & Pick<Payment, 'id' | 'confirmationUrl'>
  )> }
);

export const AccountSubscriptionFragmentFragmentDoc = gql`
    fragment AccountSubscriptionFragment on accountSubscription {
  id
  notPayedNotify
  courseStartNotify
  courseEndNotify
  mondayRegularSubscription
  thursdayRegularSubscription
  newCourseSubscription
  nearestCoursesSubscription
  weekCoursesSubscription
  subscriptionAutopayment
  courseSuccessRegistrationNotify
  ofzDisclaimerAgree
  welcomeEmails
  service52Weeks
}
    `;
export const ProductFormQueryForPaymentFragmentFragmentDoc = gql`
    fragment ProductFormQueryForPaymentFragment on productForm {
  id
  type
  price
  title
  maxOrders
  showRemainingOrders
  ordersCount
  paymentStatus
  pictureId
  requiredFieldsNumber
  order {
    id
  }
  product {
    id
    name
    offer
    contentProvider {
      id
      offer
    }
  }
  webinars {
    id
    date
  }
  seminars {
    id
    date
  }
  records {
    id
    date
  }
}
    `;
export const SearchResultProductFormFragmentFragmentDoc = gql`
    fragment SearchResultProductFormFragment on ListOperationResultTypeProductFormPublicType {
  count
  result {
    id
    type
    price
    title
    maxOrders
    ordersCount
    paymentStatus
    requiredFieldsNumber
    showRemainingOrders
    showNumberLessons
    order {
      id
    }
    product {
      id
      name
      shortDescription
      pictureId
      offer
      contentProvider {
        id
        offer
      }
      lecturers {
        id
        account {
          id
          firstName
          lastName
        }
      }
    }
    withMeInSetForms {
      id
      type
    }
    webinars {
      id
      date
    }
    seminars {
      id
      date
    }
    records {
      id
      date
    }
  }
}
    `;
export const SearchResultArticleFragmentFragmentDoc = gql`
    fragment SearchResultArticleFragment on ListOperationResultTypeArticlePublicType {
  count
  result {
    id
    title
    slug
    shortDescription
    content
    isVisible
    slug
    account {
      id
      firstName
      lastName
    }
    suggestedArticles {
      id
      title
      slug
      pictureBigId
      tags
      date
      account {
        id
        firstName
        lastName
      }
    }
    date
    pictureBigId
    pictureSmallId
    pageViews
    tags
  }
}
    `;
export const HasAnyFutureCoursesDocument = gql`
    query HasAnyFutureCourses {
  hasAnyFutureCourses
}
    `;

/**
 * __useHasAnyFutureCoursesQuery__
 *
 * To run a query within a React component, call `useHasAnyFutureCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAnyFutureCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAnyFutureCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasAnyFutureCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasAnyFutureCoursesQuery, HasAnyFutureCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<HasAnyFutureCoursesQuery, HasAnyFutureCoursesQueryVariables>(HasAnyFutureCoursesDocument, baseOptions);
      }
export function useHasAnyFutureCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasAnyFutureCoursesQuery, HasAnyFutureCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasAnyFutureCoursesQuery, HasAnyFutureCoursesQueryVariables>(HasAnyFutureCoursesDocument, baseOptions);
        }
export type HasAnyFutureCoursesQueryHookResult = ReturnType<typeof useHasAnyFutureCoursesQuery>;
export type HasAnyFutureCoursesLazyQueryHookResult = ReturnType<typeof useHasAnyFutureCoursesLazyQuery>;
export type HasAnyFutureCoursesQueryResult = ApolloReactCommon.QueryResult<HasAnyFutureCoursesQuery, HasAnyFutureCoursesQueryVariables>;
export const SyncWithCalendarOrderDocument = gql`
    mutation SyncWithCalendarOrder {
  syncWithCalendarOrder
}
    `;

/**
 * __useSyncWithCalendarOrderMutation__
 *
 * To run a mutation, you first call `useSyncWithCalendarOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncWithCalendarOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncWithCalendarOrderMutation, { data, loading, error }] = useSyncWithCalendarOrderMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncWithCalendarOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncWithCalendarOrderMutation, SyncWithCalendarOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncWithCalendarOrderMutation, SyncWithCalendarOrderMutationVariables>(SyncWithCalendarOrderDocument, baseOptions);
      }
export type SyncWithCalendarOrderMutationHookResult = ReturnType<typeof useSyncWithCalendarOrderMutation>;
export type SyncWithCalendarOrderMutationResult = ApolloReactCommon.MutationResult<SyncWithCalendarOrderMutation>;
export const GetInCartFormsDocument = gql`
    query GetInCartForms($filter: ProductFormListRequest) {
  productForms(filter: $filter) {
    count
    result {
      id
      type
      price
      maxOrders
      ordersCount
      paymentStatus
      requiredFieldsNumber
      showNumberLessons
      order {
        id
      }
      product {
        id
        name
        shortDescription
        offer
        contentProvider {
          id
          offer
        }
        lecturers {
          id
          account {
            id
            firstName
            lastName
          }
        }
      }
      withMeInSetForms {
        id
        type
      }
      webinars {
        id
        date
      }
      seminars {
        id
        date
      }
      records {
        id
        date
      }
    }
  }
}
    `;

/**
 * __useGetInCartFormsQuery__
 *
 * To run a query within a React component, call `useGetInCartFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInCartFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInCartFormsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInCartFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInCartFormsQuery, GetInCartFormsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInCartFormsQuery, GetInCartFormsQueryVariables>(GetInCartFormsDocument, baseOptions);
      }
export function useGetInCartFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInCartFormsQuery, GetInCartFormsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInCartFormsQuery, GetInCartFormsQueryVariables>(GetInCartFormsDocument, baseOptions);
        }
export type GetInCartFormsQueryHookResult = ReturnType<typeof useGetInCartFormsQuery>;
export type GetInCartFormsLazyQueryHookResult = ReturnType<typeof useGetInCartFormsLazyQuery>;
export type GetInCartFormsQueryResult = ApolloReactCommon.QueryResult<GetInCartFormsQuery, GetInCartFormsQueryVariables>;
export const GetPurchasedProductsDocument = gql`
    query GetPurchasedProducts($filter: ProductListRequest) {
  purchasedProducts(filter: $filter) {
    count
    result {
      id
      name
      shortDescription
      lastPurchasedDate
      isFree
      isArchive
      lecturers {
        id
        account {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetPurchasedProductsQuery__
 *
 * To run a query within a React component, call `useGetPurchasedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasedProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPurchasedProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchasedProductsQuery, GetPurchasedProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchasedProductsQuery, GetPurchasedProductsQueryVariables>(GetPurchasedProductsDocument, baseOptions);
      }
export function useGetPurchasedProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchasedProductsQuery, GetPurchasedProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchasedProductsQuery, GetPurchasedProductsQueryVariables>(GetPurchasedProductsDocument, baseOptions);
        }
export type GetPurchasedProductsQueryHookResult = ReturnType<typeof useGetPurchasedProductsQuery>;
export type GetPurchasedProductsLazyQueryHookResult = ReturnType<typeof useGetPurchasedProductsLazyQuery>;
export type GetPurchasedProductsQueryResult = ApolloReactCommon.QueryResult<GetPurchasedProductsQuery, GetPurchasedProductsQueryVariables>;
export const GetCourseCalenderDataDocument = gql`
    query GetCourseCalenderData($filter: OrderListRequest) {
  extendedOrders(filter: $filter) {
    count
    result {
      id
      courseDate
      courseTitle
      courseHours
      productName
      state
      productId
      productName
    }
  }
}
    `;

/**
 * __useGetCourseCalenderDataQuery__
 *
 * To run a query within a React component, call `useGetCourseCalenderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCalenderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCalenderDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCourseCalenderDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseCalenderDataQuery, GetCourseCalenderDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseCalenderDataQuery, GetCourseCalenderDataQueryVariables>(GetCourseCalenderDataDocument, baseOptions);
      }
export function useGetCourseCalenderDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCalenderDataQuery, GetCourseCalenderDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseCalenderDataQuery, GetCourseCalenderDataQueryVariables>(GetCourseCalenderDataDocument, baseOptions);
        }
export type GetCourseCalenderDataQueryHookResult = ReturnType<typeof useGetCourseCalenderDataQuery>;
export type GetCourseCalenderDataLazyQueryHookResult = ReturnType<typeof useGetCourseCalenderDataLazyQuery>;
export type GetCourseCalenderDataQueryResult = ApolloReactCommon.QueryResult<GetCourseCalenderDataQuery, GetCourseCalenderDataQueryVariables>;
export const UpdateMyAccountDocument = gql`
    mutation UpdateMyAccount($account: AccountPublicUpdateInput!) {
  updateAccount(account: $account) {
    id
    email
    firstName
    lastName
    middleName
    email
    phones
    vkLink
    facebookLink
    telegramUserName
    accountSubscription {
      ...AccountSubscriptionFragment
    }
  }
}
    ${AccountSubscriptionFragmentFragmentDoc}`;

/**
 * __useUpdateMyAccountMutation__
 *
 * To run a mutation, you first call `useUpdateMyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyAccountMutation, { data, loading, error }] = useUpdateMyAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateMyAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>(UpdateMyAccountDocument, baseOptions);
      }
export type UpdateMyAccountMutationHookResult = ReturnType<typeof useUpdateMyAccountMutation>;
export type UpdateMyAccountMutationResult = ApolloReactCommon.MutationResult<UpdateMyAccountMutation>;
export const DisableSubscriptionAutopaymentDocument = gql`
    mutation DisableSubscriptionAutopayment {
  disableSubscriptionAutopayment
}
    `;

/**
 * __useDisableSubscriptionAutopaymentMutation__
 *
 * To run a mutation, you first call `useDisableSubscriptionAutopaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSubscriptionAutopaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSubscriptionAutopaymentMutation, { data, loading, error }] = useDisableSubscriptionAutopaymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableSubscriptionAutopaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableSubscriptionAutopaymentMutation, DisableSubscriptionAutopaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableSubscriptionAutopaymentMutation, DisableSubscriptionAutopaymentMutationVariables>(DisableSubscriptionAutopaymentDocument, baseOptions);
      }
export type DisableSubscriptionAutopaymentMutationHookResult = ReturnType<typeof useDisableSubscriptionAutopaymentMutation>;
export type DisableSubscriptionAutopaymentMutationResult = ApolloReactCommon.MutationResult<DisableSubscriptionAutopaymentMutation>;
export const SyncedAccountSubscriptionsDocument = gql`
    mutation SyncedAccountSubscriptions {
  syncedAccountSubscriptions {
    id
    accountSubscription {
      ...AccountSubscriptionFragment
    }
  }
}
    ${AccountSubscriptionFragmentFragmentDoc}`;

/**
 * __useSyncedAccountSubscriptionsMutation__
 *
 * To run a mutation, you first call `useSyncedAccountSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncedAccountSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncedAccountSubscriptionsMutation, { data, loading, error }] = useSyncedAccountSubscriptionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncedAccountSubscriptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncedAccountSubscriptionsMutation, SyncedAccountSubscriptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncedAccountSubscriptionsMutation, SyncedAccountSubscriptionsMutationVariables>(SyncedAccountSubscriptionsDocument, baseOptions);
      }
export type SyncedAccountSubscriptionsMutationHookResult = ReturnType<typeof useSyncedAccountSubscriptionsMutation>;
export type SyncedAccountSubscriptionsMutationResult = ApolloReactCommon.MutationResult<SyncedAccountSubscriptionsMutation>;
export const MailChimpSubscribeDocument = gql`
    mutation MailChimpSubscribe {
  subscribe {
    id
    accountSubscription {
      ...AccountSubscriptionFragment
    }
  }
}
    ${AccountSubscriptionFragmentFragmentDoc}`;

/**
 * __useMailChimpSubscribeMutation__
 *
 * To run a mutation, you first call `useMailChimpSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailChimpSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailChimpSubscribeMutation, { data, loading, error }] = useMailChimpSubscribeMutation({
 *   variables: {
 *   },
 * });
 */
export function useMailChimpSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MailChimpSubscribeMutation, MailChimpSubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<MailChimpSubscribeMutation, MailChimpSubscribeMutationVariables>(MailChimpSubscribeDocument, baseOptions);
      }
export type MailChimpSubscribeMutationHookResult = ReturnType<typeof useMailChimpSubscribeMutation>;
export type MailChimpSubscribeMutationResult = ApolloReactCommon.MutationResult<MailChimpSubscribeMutation>;
export const GetArticleAuthorsDocument = gql`
    query GetArticleAuthors($filter: AccountListRequest) {
  accounts(filter: $filter) {
    result {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetArticleAuthorsQuery__
 *
 * To run a query within a React component, call `useGetArticleAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleAuthorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArticleAuthorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticleAuthorsQuery, GetArticleAuthorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticleAuthorsQuery, GetArticleAuthorsQueryVariables>(GetArticleAuthorsDocument, baseOptions);
      }
export function useGetArticleAuthorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticleAuthorsQuery, GetArticleAuthorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticleAuthorsQuery, GetArticleAuthorsQueryVariables>(GetArticleAuthorsDocument, baseOptions);
        }
export type GetArticleAuthorsQueryHookResult = ReturnType<typeof useGetArticleAuthorsQuery>;
export type GetArticleAuthorsLazyQueryHookResult = ReturnType<typeof useGetArticleAuthorsLazyQuery>;
export type GetArticleAuthorsQueryResult = ApolloReactCommon.QueryResult<GetArticleAuthorsQuery, GetArticleAuthorsQueryVariables>;
export const GetArticleDocument = gql`
    query GetArticle($slug: String!) {
  articleBySlug(slug: $slug) {
    id
    title
    slug
    shortDescription
    content
    isVisible
    account {
      id
      firstName
      lastName
    }
    suggestedProduct {
      id
      name
      pictureId
    }
    suggestedArticles {
      id
      title
      slug
      pictureBigId
      tags
      date
      account {
        id
        firstName
        lastName
      }
    }
    date
    pictureBigId
    pictureSmallId
    pageViews
    tags
  }
}
    `;

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, baseOptions);
      }
export function useGetArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, baseOptions);
        }
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>;
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>;
export type GetArticleQueryResult = ApolloReactCommon.QueryResult<GetArticleQuery, GetArticleQueryVariables>;
export const GetArticlesDocument = gql`
    query GetArticles($filter: ArticleListRequest) {
  articles(filter: $filter) {
    count
    result {
      id
      title
      slug
      shortDescription
      content
      isVisible
      slug
      account {
        id
        firstName
        lastName
      }
      suggestedArticles {
        id
        title
        slug
        pictureBigId
        tags
        date
        account {
          id
          firstName
          lastName
        }
      }
      date
      pictureBigId
      pictureSmallId
      pageViews
      tags
    }
  }
}
    `;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
      }
export function useGetArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = ApolloReactCommon.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetMyAccountDocument = gql`
    query GetMyAccount {
  myAccount {
    id
    email
    firstName
    lastName
    middleName
    phones
    vkLink
    facebookLink
    telegramUserName
    refLink
    refPoints
    hasTinkoffInvest
    accessToFreeForms
    accountSubscription {
      ...AccountSubscriptionFragment
    }
    subscription {
      autopayment
      until
      type
    }
    subscriptionFreeCourses {
      until
    }
    subscriptionCorporateBonds {
      until
    }
  }
}
    ${AccountSubscriptionFragmentFragmentDoc}`;

/**
 * __useGetMyAccountQuery__
 *
 * To run a query within a React component, call `useGetMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyAccountQuery, GetMyAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyAccountQuery, GetMyAccountQueryVariables>(GetMyAccountDocument, baseOptions);
      }
export function useGetMyAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyAccountQuery, GetMyAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyAccountQuery, GetMyAccountQueryVariables>(GetMyAccountDocument, baseOptions);
        }
export type GetMyAccountQueryHookResult = ReturnType<typeof useGetMyAccountQuery>;
export type GetMyAccountLazyQueryHookResult = ReturnType<typeof useGetMyAccountLazyQuery>;
export type GetMyAccountQueryResult = ApolloReactCommon.QueryResult<GetMyAccountQuery, GetMyAccountQueryVariables>;
export const AgreeOfzDisclaimerDocument = gql`
    mutation AgreeOfzDisclaimer {
  agreeOfzDisclaimer {
    id
    ofzDisclaimerAgree
  }
}
    `;

/**
 * __useAgreeOfzDisclaimerMutation__
 *
 * To run a mutation, you first call `useAgreeOfzDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeOfzDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeOfzDisclaimerMutation, { data, loading, error }] = useAgreeOfzDisclaimerMutation({
 *   variables: {
 *   },
 * });
 */
export function useAgreeOfzDisclaimerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgreeOfzDisclaimerMutation, AgreeOfzDisclaimerMutationVariables>) {
        return ApolloReactHooks.useMutation<AgreeOfzDisclaimerMutation, AgreeOfzDisclaimerMutationVariables>(AgreeOfzDisclaimerDocument, baseOptions);
      }
export type AgreeOfzDisclaimerMutationHookResult = ReturnType<typeof useAgreeOfzDisclaimerMutation>;
export type AgreeOfzDisclaimerMutationResult = ApolloReactCommon.MutationResult<AgreeOfzDisclaimerMutation>;
export const BuildPortfolioDocument = gql`
    query BuildPortfolio($request: buildPortfolioRequest!) {
  buildPortfolio(request: $request) {
    nominal
    yieldToMaturity
    positions {
      quantity
      nominal
      bond {
        secId
        shortName
        maturityDate
        couponValue
        offerDate
      }
      calendar {
        payments {
          number
          date
          amount
        }
      }
    }
    calendar {
      payments {
        number
        date
        amount
        position {
          bond {
            secId
            shortName
            maturityDate
            offerDateEnd
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBuildPortfolioQuery__
 *
 * To run a query within a React component, call `useBuildPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildPortfolioQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBuildPortfolioQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BuildPortfolioQuery, BuildPortfolioQueryVariables>) {
        return ApolloReactHooks.useQuery<BuildPortfolioQuery, BuildPortfolioQueryVariables>(BuildPortfolioDocument, baseOptions);
      }
export function useBuildPortfolioLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BuildPortfolioQuery, BuildPortfolioQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BuildPortfolioQuery, BuildPortfolioQueryVariables>(BuildPortfolioDocument, baseOptions);
        }
export type BuildPortfolioQueryHookResult = ReturnType<typeof useBuildPortfolioQuery>;
export type BuildPortfolioLazyQueryHookResult = ReturnType<typeof useBuildPortfolioLazyQuery>;
export type BuildPortfolioQueryResult = ApolloReactCommon.QueryResult<BuildPortfolioQuery, BuildPortfolioQueryVariables>;
export const GetBrokersDocument = gql`
    query GetBrokers($filter: BrokerListRequest) {
  brokers(filter: $filter) {
    count
    result {
      id
      name
      link
      about
      commission
      minimum
      features
      sentence
      position
      pictureId
    }
  }
}
    `;

/**
 * __useGetBrokersQuery__
 *
 * To run a query within a React component, call `useGetBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBrokersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBrokersQuery, GetBrokersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBrokersQuery, GetBrokersQueryVariables>(GetBrokersDocument, baseOptions);
      }
export function useGetBrokersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBrokersQuery, GetBrokersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBrokersQuery, GetBrokersQueryVariables>(GetBrokersDocument, baseOptions);
        }
export type GetBrokersQueryHookResult = ReturnType<typeof useGetBrokersQuery>;
export type GetBrokersLazyQueryHookResult = ReturnType<typeof useGetBrokersLazyQuery>;
export type GetBrokersQueryResult = ApolloReactCommon.QueryResult<GetBrokersQuery, GetBrokersQueryVariables>;
export const GetCourseFilterTagsDocument = gql`
    query GetCourseFilterTags($filter: TagListRequest) {
  tags(filter: $filter) {
    result {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetCourseFilterTagsQuery__
 *
 * To run a query within a React component, call `useGetCourseFilterTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseFilterTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseFilterTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCourseFilterTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseFilterTagsQuery, GetCourseFilterTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseFilterTagsQuery, GetCourseFilterTagsQueryVariables>(GetCourseFilterTagsDocument, baseOptions);
      }
export function useGetCourseFilterTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseFilterTagsQuery, GetCourseFilterTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseFilterTagsQuery, GetCourseFilterTagsQueryVariables>(GetCourseFilterTagsDocument, baseOptions);
        }
export type GetCourseFilterTagsQueryHookResult = ReturnType<typeof useGetCourseFilterTagsQuery>;
export type GetCourseFilterTagsLazyQueryHookResult = ReturnType<typeof useGetCourseFilterTagsLazyQuery>;
export type GetCourseFilterTagsQueryResult = ApolloReactCommon.QueryResult<GetCourseFilterTagsQuery, GetCourseFilterTagsQueryVariables>;
export const GetCourseListDocument = gql`
    query GetCourseList($filter: ProductFormListRequest) {
  productForms(filter: $filter) {
    count
    result {
      id
      type
      price
      title
      maxOrders
      showRemainingOrders
      ordersCount
      paymentStatus
      requiredFieldsNumber
      subscriptionAccess
      showNumberLessons
      order {
        id
      }
      product {
        id
        name
        shortDescription
        pictureId
        offer
        contentProvider {
          id
          offer
        }
        lecturers {
          id
          account {
            id
            firstName
            lastName
          }
        }
      }
      withMeInSetForms {
        id
        type
        subscriptionAccess
        price
      }
      webinars {
        id
        date
        soonCause
      }
      seminars {
        id
        date
        soonCause
      }
      records {
        id
        date
      }
    }
  }
}
    `;

/**
 * __useGetCourseListQuery__
 *
 * To run a query within a React component, call `useGetCourseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCourseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseListQuery, GetCourseListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseListQuery, GetCourseListQueryVariables>(GetCourseListDocument, baseOptions);
      }
export function useGetCourseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseListQuery, GetCourseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseListQuery, GetCourseListQueryVariables>(GetCourseListDocument, baseOptions);
        }
export type GetCourseListQueryHookResult = ReturnType<typeof useGetCourseListQuery>;
export type GetCourseListLazyQueryHookResult = ReturnType<typeof useGetCourseListLazyQuery>;
export type GetCourseListQueryResult = ApolloReactCommon.QueryResult<GetCourseListQuery, GetCourseListQueryVariables>;
export const GetDividendDetailDocument = gql`
    query GetDividendDetail($symbol: String!) {
  dividend(symbol: $symbol) {
    lastUpdate
    historyErrorMessage
    price
    name
    target
    candles {
      time
      open
      close
      high
      low
    }
    history {
      period
      lastDayToBuy
      registryClose
      dividend
      paymentYield
      yield
      paid
      timeGap
      gapClosed
      gapDawn
    }
    security {
      secId
      name
      high
      highDate
      low
      lowDate
      current
      currentDate
      position
      amplitude
      highPercent
      lowPercent
      d1
      w1
      m1
      y1
      yTD
      moscowExchangesIndex
      moscowExchangeBlueChipIndex
    }
  }
}
    `;

/**
 * __useGetDividendDetailQuery__
 *
 * To run a query within a React component, call `useGetDividendDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDividendDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDividendDetailQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetDividendDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDividendDetailQuery, GetDividendDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDividendDetailQuery, GetDividendDetailQueryVariables>(GetDividendDetailDocument, baseOptions);
      }
export function useGetDividendDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDividendDetailQuery, GetDividendDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDividendDetailQuery, GetDividendDetailQueryVariables>(GetDividendDetailDocument, baseOptions);
        }
export type GetDividendDetailQueryHookResult = ReturnType<typeof useGetDividendDetailQuery>;
export type GetDividendDetailLazyQueryHookResult = ReturnType<typeof useGetDividendDetailLazyQuery>;
export type GetDividendDetailQueryResult = ApolloReactCommon.QueryResult<GetDividendDetailQuery, GetDividendDetailQueryVariables>;
export const GetDividendsListDocument = gql`
    query GetDividendsList {
  dividends {
    lastUpdate
    moscowExchangesIndex
    moscowExchangeBlueChipIndex
    name
    ticker
    price
    yearYield
    nearestDividendPayout
    dividendsCountPerYear
    dividendYield
    lastDayToBuy
    payYears
    increaseYears
    gapClosing
    lastDividendGap
    lastDividendGapClosed
  }
}
    `;

/**
 * __useGetDividendsListQuery__
 *
 * To run a query within a React component, call `useGetDividendsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDividendsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDividendsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDividendsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDividendsListQuery, GetDividendsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDividendsListQuery, GetDividendsListQueryVariables>(GetDividendsListDocument, baseOptions);
      }
export function useGetDividendsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDividendsListQuery, GetDividendsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDividendsListQuery, GetDividendsListQueryVariables>(GetDividendsListDocument, baseOptions);
        }
export type GetDividendsListQueryHookResult = ReturnType<typeof useGetDividendsListQuery>;
export type GetDividendsListLazyQueryHookResult = ReturnType<typeof useGetDividendsListLazyQuery>;
export type GetDividendsListQueryResult = ApolloReactCommon.QueryResult<GetDividendsListQuery, GetDividendsListQueryVariables>;
export const GetFeedbacksToProductDocument = gql`
    query GetFeedbacksToProduct($filter: FeedbackToProductListRequest) {
  feedbackToProducts(filter: $filter) {
    count
    result {
      id
      accountName
      rating
      date
      dateUpdate
      description
    }
  }
}
    `;

/**
 * __useGetFeedbacksToProductQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksToProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksToProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksToProductQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFeedbacksToProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeedbacksToProductQuery, GetFeedbacksToProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeedbacksToProductQuery, GetFeedbacksToProductQueryVariables>(GetFeedbacksToProductDocument, baseOptions);
      }
export function useGetFeedbacksToProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeedbacksToProductQuery, GetFeedbacksToProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeedbacksToProductQuery, GetFeedbacksToProductQueryVariables>(GetFeedbacksToProductDocument, baseOptions);
        }
export type GetFeedbacksToProductQueryHookResult = ReturnType<typeof useGetFeedbacksToProductQuery>;
export type GetFeedbacksToProductLazyQueryHookResult = ReturnType<typeof useGetFeedbacksToProductLazyQuery>;
export type GetFeedbacksToProductQueryResult = ApolloReactCommon.QueryResult<GetFeedbacksToProductQuery, GetFeedbacksToProductQueryVariables>;
export const GetFeedbackProductDocument = gql`
    query GetFeedbackProduct($id: Int!) {
  product(id: $id) {
    id
    hasFeedback
    hasAnyPurchasedForm
  }
}
    `;

/**
 * __useGetFeedbackProductQuery__
 *
 * To run a query within a React component, call `useGetFeedbackProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeedbackProductQuery, GetFeedbackProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeedbackProductQuery, GetFeedbackProductQueryVariables>(GetFeedbackProductDocument, baseOptions);
      }
export function useGetFeedbackProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeedbackProductQuery, GetFeedbackProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeedbackProductQuery, GetFeedbackProductQueryVariables>(GetFeedbackProductDocument, baseOptions);
        }
export type GetFeedbackProductQueryHookResult = ReturnType<typeof useGetFeedbackProductQuery>;
export type GetFeedbackProductLazyQueryHookResult = ReturnType<typeof useGetFeedbackProductLazyQuery>;
export type GetFeedbackProductQueryResult = ApolloReactCommon.QueryResult<GetFeedbackProductQuery, GetFeedbackProductQueryVariables>;
export const CreateFeedbackToProductDocument = gql`
    mutation CreateFeedbackToProduct($feedbackToProduct: FeedbackToProductPublicInsertInput!) {
  createFeedbackToProduct(feedbackToProduct: $feedbackToProduct) {
    id
  }
}
    `;

/**
 * __useCreateFeedbackToProductMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackToProductMutation, { data, loading, error }] = useCreateFeedbackToProductMutation({
 *   variables: {
 *      feedbackToProduct: // value for 'feedbackToProduct'
 *   },
 * });
 */
export function useCreateFeedbackToProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFeedbackToProductMutation, CreateFeedbackToProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFeedbackToProductMutation, CreateFeedbackToProductMutationVariables>(CreateFeedbackToProductDocument, baseOptions);
      }
export type CreateFeedbackToProductMutationHookResult = ReturnType<typeof useCreateFeedbackToProductMutation>;
export type CreateFeedbackToProductMutationResult = ApolloReactCommon.MutationResult<CreateFeedbackToProductMutation>;
export const UpdateFeedbackToProductDocument = gql`
    mutation UpdateFeedbackToProduct($feedbackToProduct: FeedbackToProductPublicUpdateInput!) {
  updateFeedbackToProduct(feedbackToProduct: $feedbackToProduct) {
    id
  }
}
    `;

/**
 * __useUpdateFeedbackToProductMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackToProductMutation, { data, loading, error }] = useUpdateFeedbackToProductMutation({
 *   variables: {
 *      feedbackToProduct: // value for 'feedbackToProduct'
 *   },
 * });
 */
export function useUpdateFeedbackToProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFeedbackToProductMutation, UpdateFeedbackToProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFeedbackToProductMutation, UpdateFeedbackToProductMutationVariables>(UpdateFeedbackToProductDocument, baseOptions);
      }
export type UpdateFeedbackToProductMutationHookResult = ReturnType<typeof useUpdateFeedbackToProductMutation>;
export type UpdateFeedbackToProductMutationResult = ApolloReactCommon.MutationResult<UpdateFeedbackToProductMutation>;
export const FinancialCalculatorGetDocument = gql`
    query FinancialCalculatorGet($input: financialCalculatorGetInput!) {
  financialCalculatorGet(input: $input) {
    amount
    capital
    investments
    total
    investmentYield
  }
}
    `;

/**
 * __useFinancialCalculatorGetQuery__
 *
 * To run a query within a React component, call `useFinancialCalculatorGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialCalculatorGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialCalculatorGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinancialCalculatorGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinancialCalculatorGetQuery, FinancialCalculatorGetQueryVariables>) {
        return ApolloReactHooks.useQuery<FinancialCalculatorGetQuery, FinancialCalculatorGetQueryVariables>(FinancialCalculatorGetDocument, baseOptions);
      }
export function useFinancialCalculatorGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinancialCalculatorGetQuery, FinancialCalculatorGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinancialCalculatorGetQuery, FinancialCalculatorGetQueryVariables>(FinancialCalculatorGetDocument, baseOptions);
        }
export type FinancialCalculatorGetQueryHookResult = ReturnType<typeof useFinancialCalculatorGetQuery>;
export type FinancialCalculatorGetLazyQueryHookResult = ReturnType<typeof useFinancialCalculatorGetLazyQuery>;
export type FinancialCalculatorGetQueryResult = ApolloReactCommon.QueryResult<FinancialCalculatorGetQuery, FinancialCalculatorGetQueryVariables>;
export const FinancialCalculatorQuestionGetDocument = gql`
    query FinancialCalculatorQuestionGet($input: financialCalculatorQuestionGetInput!) {
  financialCalculatorQuestionGet(input: $input) {
    yearResult
    amountResult
    amountResultInflation
    capitalResult
    capitalResultInflation
    investmentsResult
  }
}
    `;

/**
 * __useFinancialCalculatorQuestionGetQuery__
 *
 * To run a query within a React component, call `useFinancialCalculatorQuestionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialCalculatorQuestionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialCalculatorQuestionGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinancialCalculatorQuestionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinancialCalculatorQuestionGetQuery, FinancialCalculatorQuestionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<FinancialCalculatorQuestionGetQuery, FinancialCalculatorQuestionGetQueryVariables>(FinancialCalculatorQuestionGetDocument, baseOptions);
      }
export function useFinancialCalculatorQuestionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinancialCalculatorQuestionGetQuery, FinancialCalculatorQuestionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinancialCalculatorQuestionGetQuery, FinancialCalculatorQuestionGetQueryVariables>(FinancialCalculatorQuestionGetDocument, baseOptions);
        }
export type FinancialCalculatorQuestionGetQueryHookResult = ReturnType<typeof useFinancialCalculatorQuestionGetQuery>;
export type FinancialCalculatorQuestionGetLazyQueryHookResult = ReturnType<typeof useFinancialCalculatorQuestionGetLazyQuery>;
export type FinancialCalculatorQuestionGetQueryResult = ApolloReactCommon.QueryResult<FinancialCalculatorQuestionGetQuery, FinancialCalculatorQuestionGetQueryVariables>;
export const GetHighLowDataDocument = gql`
    query GetHighLowData {
  highLowData {
    secId
    name
    high
    highDate
    low
    lowDate
    current
    currentDate
    position
    amplitude
    highPercent
    lowPercent
    d1
    w1
    m1
    y1
    yTD
    moscowExchangesIndex
    moscowExchangeBlueChipIndex
    index
    eTF
  }
}
    `;

/**
 * __useGetHighLowDataQuery__
 *
 * To run a query within a React component, call `useGetHighLowDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighLowDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighLowDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHighLowDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHighLowDataQuery, GetHighLowDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHighLowDataQuery, GetHighLowDataQueryVariables>(GetHighLowDataDocument, baseOptions);
      }
export function useGetHighLowDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHighLowDataQuery, GetHighLowDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHighLowDataQuery, GetHighLowDataQueryVariables>(GetHighLowDataDocument, baseOptions);
        }
export type GetHighLowDataQueryHookResult = ReturnType<typeof useGetHighLowDataQuery>;
export type GetHighLowDataLazyQueryHookResult = ReturnType<typeof useGetHighLowDataLazyQuery>;
export type GetHighLowDataQueryResult = ApolloReactCommon.QueryResult<GetHighLowDataQuery, GetHighLowDataQueryVariables>;
export const GetLecturerDocument = gql`
    query GetLecturer($id: Int!, $productsFilter: ProductListRequest) {
  lecturer(id: $id) {
    id
    education
    skill
    about
    account {
      id
      firstName
      lastName
      middleName
      pictureId
    }
    products(filter: $productsFilter) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLecturerQuery__
 *
 * To run a query within a React component, call `useGetLecturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLecturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLecturerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      productsFilter: // value for 'productsFilter'
 *   },
 * });
 */
export function useGetLecturerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLecturerQuery, GetLecturerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLecturerQuery, GetLecturerQueryVariables>(GetLecturerDocument, baseOptions);
      }
export function useGetLecturerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLecturerQuery, GetLecturerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLecturerQuery, GetLecturerQueryVariables>(GetLecturerDocument, baseOptions);
        }
export type GetLecturerQueryHookResult = ReturnType<typeof useGetLecturerQuery>;
export type GetLecturerLazyQueryHookResult = ReturnType<typeof useGetLecturerLazyQuery>;
export type GetLecturerQueryResult = ApolloReactCommon.QueryResult<GetLecturerQuery, GetLecturerQueryVariables>;
export const GetLecturersDocument = gql`
    query GetLecturers($lecturersFilter: LecturerListRequest, $productsFilter: ProductListRequest) {
  lecturers(filter: $lecturersFilter) {
    count
    result {
      id
      education
      skill
      about
      account {
        id
        firstName
        lastName
        middleName
        pictureId
      }
      products(filter: $productsFilter) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetLecturersQuery__
 *
 * To run a query within a React component, call `useGetLecturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLecturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLecturersQuery({
 *   variables: {
 *      lecturersFilter: // value for 'lecturersFilter'
 *      productsFilter: // value for 'productsFilter'
 *   },
 * });
 */
export function useGetLecturersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLecturersQuery, GetLecturersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLecturersQuery, GetLecturersQueryVariables>(GetLecturersDocument, baseOptions);
      }
export function useGetLecturersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLecturersQuery, GetLecturersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLecturersQuery, GetLecturersQueryVariables>(GetLecturersDocument, baseOptions);
        }
export type GetLecturersQueryHookResult = ReturnType<typeof useGetLecturersQuery>;
export type GetLecturersLazyQueryHookResult = ReturnType<typeof useGetLecturersLazyQuery>;
export type GetLecturersQueryResult = ApolloReactCommon.QueryResult<GetLecturersQuery, GetLecturersQueryVariables>;
export const MainPageDocument = gql`
    query MainPage($pageFilter: PageListRequest) {
  accounts {
    count
  }
  pages(filter: $pageFilter) {
    count
    result {
      id
      title
      slug
      content
      shortDescription
      type
    }
  }
}
    `;

/**
 * __useMainPageQuery__
 *
 * To run a query within a React component, call `useMainPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainPageQuery({
 *   variables: {
 *      pageFilter: // value for 'pageFilter'
 *   },
 * });
 */
export function useMainPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MainPageQuery, MainPageQueryVariables>) {
        return ApolloReactHooks.useQuery<MainPageQuery, MainPageQueryVariables>(MainPageDocument, baseOptions);
      }
export function useMainPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MainPageQuery, MainPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MainPageQuery, MainPageQueryVariables>(MainPageDocument, baseOptions);
        }
export type MainPageQueryHookResult = ReturnType<typeof useMainPageQuery>;
export type MainPageLazyQueryHookResult = ReturnType<typeof useMainPageLazyQuery>;
export type MainPageQueryResult = ApolloReactCommon.QueryResult<MainPageQuery, MainPageQueryVariables>;
export const MainCoursesDocument = gql`
    query MainCourses($pageFilter: PageListRequest, $productFormFilter: ProductFormListRequest) {
  pages(filter: $pageFilter) {
    count
    result {
      id
      title
      slug
      content
      shortDescription
      type
    }
  }
  productForms(filter: $productFormFilter) {
    count
    result {
      id
      type
      price
      title
      maxOrders
      ordersCount
      paymentStatus
      requiredFieldsNumber
      showRemainingOrders
      showNumberLessons
      pictureId
      order {
        id
      }
      product {
        id
        name
        shortDescription
        pictureId
        offer
        contentProvider {
          id
          offer
        }
        lecturers {
          id
          account {
            id
            firstName
            lastName
          }
        }
      }
      withMeInSetForms {
        id
        type
      }
      webinars {
        id
        date
        soonCause
      }
      seminars {
        id
        date
        soonCause
      }
      records {
        id
        date
      }
    }
  }
}
    `;

/**
 * __useMainCoursesQuery__
 *
 * To run a query within a React component, call `useMainCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainCoursesQuery({
 *   variables: {
 *      pageFilter: // value for 'pageFilter'
 *      productFormFilter: // value for 'productFormFilter'
 *   },
 * });
 */
export function useMainCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MainCoursesQuery, MainCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<MainCoursesQuery, MainCoursesQueryVariables>(MainCoursesDocument, baseOptions);
      }
export function useMainCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MainCoursesQuery, MainCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MainCoursesQuery, MainCoursesQueryVariables>(MainCoursesDocument, baseOptions);
        }
export type MainCoursesQueryHookResult = ReturnType<typeof useMainCoursesQuery>;
export type MainCoursesLazyQueryHookResult = ReturnType<typeof useMainCoursesLazyQuery>;
export type MainCoursesQueryResult = ApolloReactCommon.QueryResult<MainCoursesQuery, MainCoursesQueryVariables>;
export const GetPageDocument = gql`
    query GetPage($slug: String!) {
  pageBySlug(slug: $slug) {
    id
    slug
    title
    content
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
      }
export function useGetPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = ApolloReactCommon.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetTextBlockDocument = gql`
    query GetTextBlock($slug: String!, $withTextBlocks: Boolean) {
  pageBySlug(slug: $slug, withTextBlocks: $withTextBlocks) {
    id
    slug
    content
  }
}
    `;

/**
 * __useGetTextBlockQuery__
 *
 * To run a query within a React component, call `useGetTextBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTextBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTextBlockQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      withTextBlocks: // value for 'withTextBlocks'
 *   },
 * });
 */
export function useGetTextBlockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTextBlockQuery, GetTextBlockQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTextBlockQuery, GetTextBlockQueryVariables>(GetTextBlockDocument, baseOptions);
      }
export function useGetTextBlockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTextBlockQuery, GetTextBlockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTextBlockQuery, GetTextBlockQueryVariables>(GetTextBlockDocument, baseOptions);
        }
export type GetTextBlockQueryHookResult = ReturnType<typeof useGetTextBlockQuery>;
export type GetTextBlockLazyQueryHookResult = ReturnType<typeof useGetTextBlockLazyQuery>;
export type GetTextBlockQueryResult = ApolloReactCommon.QueryResult<GetTextBlockQuery, GetTextBlockQueryVariables>;
export const GetPartnersDocument = gql`
    query GetPartners {
  partners {
    id
    name
    link
    position
    pictureId
  }
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, baseOptions);
      }
export function useGetPartnersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, baseOptions);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersQueryResult = ApolloReactCommon.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($order: OrderPublicDeletetInput!) {
  deleteOrder(order: $order) {
    id
    productForm {
      id
      paymentStatus
      order {
        id
      }
    }
  }
}
    `;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, baseOptions);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = ApolloReactCommon.MutationResult<DeleteOrderMutation>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($order: OrderPublicInsertInput!) {
  createOrder(order: $order) {
    id
    productForm {
      id
      paymentStatus
      type
      price
      order {
        id
      }
      product {
        id
        name
      }
    }
    inviteLink {
      id
      dateExpire
      date
      link
      accountId
      orderId
    }
  }
}
    `;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($arguments: PaymentPublicInsertInput!) {
  createPayment(arguments: $arguments) {
    id
    confirmationUrl
    price
  }
}
    `;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      arguments: // value for 'arguments'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMutation>;
export const GetPrivateProductDetailDataDocument = gql`
    query GetPrivateProductDetailData($id: Int!, $filter: ProductFormListRequest) {
  purchasedProduct(id: $id) {
    id
    name
    shortDescription
    hasAnyPurchasedForm
    hasFeedback
    pictureId
    lecturers {
      id
      account {
        id
        firstName
        lastName
        pictureId
      }
    }
    inviteLink {
      id
      dateExpire
      date
      link
      orderId
      accountId
    }
    productForms(filter: $filter) {
      id
      title
      type
      subscriptionAccess
      order {
        id
        accessUntil
      }
      webinars {
        id
        date
        title
        hours
        soonCause
        description
        syncState
        myParticipation {
          id
          url
        }
        eventRecord {
          id
          link
        }
        materials {
          id
          link
        }
        lecturers {
          id
          account {
            id
            firstName
            lastName
            pictureId
          }
        }
      }
      seminars {
        id
        date
        title
        hours
        description
        eventRecord {
          id
          link
        }
        materials {
          id
          link
        }
        lecturers {
          id
          account {
            id
            firstName
            lastName
            pictureId
          }
        }
      }
      records {
        id
        title
        hours
        description
        pictureId
        link
        date
        isViewed
      }
      materials {
        id
        title
        description
        link
        date
      }
      videoCourses {
        id
        title
        hours
        description
        pictureId
        link
        isViewed
      }
      certificates {
        id
        name
        state
        myCertificate {
          id
          number
        }
      }
    }
  }
}
    `;

/**
 * __useGetPrivateProductDetailDataQuery__
 *
 * To run a query within a React component, call `useGetPrivateProductDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateProductDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateProductDetailDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPrivateProductDetailDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrivateProductDetailDataQuery, GetPrivateProductDetailDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrivateProductDetailDataQuery, GetPrivateProductDetailDataQueryVariables>(GetPrivateProductDetailDataDocument, baseOptions);
      }
export function useGetPrivateProductDetailDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrivateProductDetailDataQuery, GetPrivateProductDetailDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrivateProductDetailDataQuery, GetPrivateProductDetailDataQueryVariables>(GetPrivateProductDetailDataDocument, baseOptions);
        }
export type GetPrivateProductDetailDataQueryHookResult = ReturnType<typeof useGetPrivateProductDetailDataQuery>;
export type GetPrivateProductDetailDataLazyQueryHookResult = ReturnType<typeof useGetPrivateProductDetailDataLazyQuery>;
export type GetPrivateProductDetailDataQueryResult = ApolloReactCommon.QueryResult<GetPrivateProductDetailDataQuery, GetPrivateProductDetailDataQueryVariables>;
export const GetProductDetailDataDocument = gql`
    query GetProductDetailData($id: Int!, $contentProviderId: Int, $showPublic: Boolean, $filter: ProductFormListRequest) {
  product(id: $id, contentProviderId: $contentProviderId, showPublic: $showPublic) {
    id
    name
    shortDescription
    description
    salesPoints
    warnings
    complexity
    hasAnyPurchasedForm
    hasFeedback
    tagsString
    contentProvider {
      id
      name
      offer
      code
    }
    pictureId
    lecturers {
      id
      skill
      account {
        id
        firstName
        lastName
        pictureId
      }
    }
    productForms(filter: $filter) {
      id
      title
      type
      price
      description
      paymentStatus
      requiredFieldsNumber
      maxOrders
      showRemainingOrders
      showNumberLessons
      ordersCount
      isNonSingle
      suggestedTitle
      subscriptionAccess
      suggestedProductForm {
        ...ProductFormQueryForPaymentFragment
      }
      order {
        id
      }
      product {
        id
        name
        offer
        contentProvider {
          id
          offer
        }
      }
      withMeInSetForms {
        id
        title
        type
        subscriptionAccess
        price
        webinars {
          id
        }
        seminars {
          id
        }
        records {
          id
        }
        materials {
          id
        }
        certificates {
          id
        }
        videoCourses {
          id
        }
      }
      webinars {
        id
        date
        hours
        title
        soonCause
      }
      seminars {
        id
        date
        hours
        title
        soonCause
      }
      records {
        id
        title
        date
      }
      materials {
        id
        title
      }
      certificates {
        id
      }
      videoCourses {
        id
        title
      }
    }
  }
}
    ${ProductFormQueryForPaymentFragmentFragmentDoc}`;

/**
 * __useGetProductDetailDataQuery__
 *
 * To run a query within a React component, call `useGetProductDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contentProviderId: // value for 'contentProviderId'
 *      showPublic: // value for 'showPublic'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductDetailDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductDetailDataQuery, GetProductDetailDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductDetailDataQuery, GetProductDetailDataQueryVariables>(GetProductDetailDataDocument, baseOptions);
      }
export function useGetProductDetailDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductDetailDataQuery, GetProductDetailDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductDetailDataQuery, GetProductDetailDataQueryVariables>(GetProductDetailDataDocument, baseOptions);
        }
export type GetProductDetailDataQueryHookResult = ReturnType<typeof useGetProductDetailDataQuery>;
export type GetProductDetailDataLazyQueryHookResult = ReturnType<typeof useGetProductDetailDataLazyQuery>;
export type GetProductDetailDataQueryResult = ApolloReactCommon.QueryResult<GetProductDetailDataQuery, GetProductDetailDataQueryVariables>;
export const AgreementProductOfferDocument = gql`
    mutation AgreementProductOffer($productFormId: Int!) {
  agreementProductOffer(productFormId: $productFormId) {
    id
    hasProductAgreement
  }
}
    `;

/**
 * __useAgreementProductOfferMutation__
 *
 * To run a mutation, you first call `useAgreementProductOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreementProductOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreementProductOfferMutation, { data, loading, error }] = useAgreementProductOfferMutation({
 *   variables: {
 *      productFormId: // value for 'productFormId'
 *   },
 * });
 */
export function useAgreementProductOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgreementProductOfferMutation, AgreementProductOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<AgreementProductOfferMutation, AgreementProductOfferMutationVariables>(AgreementProductOfferDocument, baseOptions);
      }
export type AgreementProductOfferMutationHookResult = ReturnType<typeof useAgreementProductOfferMutation>;
export type AgreementProductOfferMutationResult = ApolloReactCommon.MutationResult<AgreementProductOfferMutation>;
export const GetPayProductFormDocument = gql`
    query GetPayProductForm($id: Int!) {
  productForm(id: $id) {
    id
    type
    title
    price
    paymentStatus
    requiredFieldsNumber
    hasProductAgreement
    product {
      id
      offer
      name
      tagsString
      contentProvider {
        id
        offer
        name
      }
    }
    order {
      id
    }
    records {
      id
      date
    }
    webinars {
      id
      date
    }
    seminars {
      id
      date
    }
  }
}
    `;

/**
 * __useGetPayProductFormQuery__
 *
 * To run a query within a React component, call `useGetPayProductFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayProductFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayProductFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayProductFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayProductFormQuery, GetPayProductFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayProductFormQuery, GetPayProductFormQueryVariables>(GetPayProductFormDocument, baseOptions);
      }
export function useGetPayProductFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayProductFormQuery, GetPayProductFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayProductFormQuery, GetPayProductFormQueryVariables>(GetPayProductFormDocument, baseOptions);
        }
export type GetPayProductFormQueryHookResult = ReturnType<typeof useGetPayProductFormQuery>;
export type GetPayProductFormLazyQueryHookResult = ReturnType<typeof useGetPayProductFormLazyQuery>;
export type GetPayProductFormQueryResult = ApolloReactCommon.QueryResult<GetPayProductFormQuery, GetPayProductFormQueryVariables>;
export const GetFeedbacksDocument = gql`
    query GetFeedbacks($filter: FeedbackToProductListRequest) {
  feedbackToProducts(filter: $filter) {
    count
    result {
      id
      accountName
      rating
      date
      dateUpdate
      description
      product {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetFeedbacksQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFeedbacksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeedbacksQuery, GetFeedbacksQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(GetFeedbacksDocument, baseOptions);
      }
export function useGetFeedbacksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeedbacksQuery, GetFeedbacksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(GetFeedbacksDocument, baseOptions);
        }
export type GetFeedbacksQueryHookResult = ReturnType<typeof useGetFeedbacksQuery>;
export type GetFeedbacksLazyQueryHookResult = ReturnType<typeof useGetFeedbacksLazyQuery>;
export type GetFeedbacksQueryResult = ApolloReactCommon.QueryResult<GetFeedbacksQuery, GetFeedbacksQueryVariables>;
export const SearchNearestCoursesDocument = gql`
    query SearchNearestCourses($filter: ProductFormListRequest) {
  productForms(filter: $filter) {
    ...SearchResultProductFormFragment
  }
}
    ${SearchResultProductFormFragmentFragmentDoc}`;

/**
 * __useSearchNearestCoursesQuery__
 *
 * To run a query within a React component, call `useSearchNearestCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNearestCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNearestCoursesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchNearestCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNearestCoursesQuery, SearchNearestCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNearestCoursesQuery, SearchNearestCoursesQueryVariables>(SearchNearestCoursesDocument, baseOptions);
      }
export function useSearchNearestCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNearestCoursesQuery, SearchNearestCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNearestCoursesQuery, SearchNearestCoursesQueryVariables>(SearchNearestCoursesDocument, baseOptions);
        }
export type SearchNearestCoursesQueryHookResult = ReturnType<typeof useSearchNearestCoursesQuery>;
export type SearchNearestCoursesLazyQueryHookResult = ReturnType<typeof useSearchNearestCoursesLazyQuery>;
export type SearchNearestCoursesQueryResult = ApolloReactCommon.QueryResult<SearchNearestCoursesQuery, SearchNearestCoursesQueryVariables>;
export const SearchingDocument = gql`
    query Searching($coursesFilter: ProductFormListRequest, $videoCoursesFilter: ProductFormListRequest, $materialsFilter: ProductFormListRequest, $articlesFilter: ArticleListRequest, $lecturersFilter: LecturerListRequest, $lectureProductsFilter: ProductListRequest) {
  courses: productForms(filter: $coursesFilter) {
    ...SearchResultProductFormFragment
  }
  videoCourses: productForms(filter: $videoCoursesFilter) {
    ...SearchResultProductFormFragment
  }
  materials: productForms(filter: $materialsFilter) {
    ...SearchResultProductFormFragment
  }
  articles(filter: $articlesFilter) {
    ...SearchResultArticleFragment
  }
  lecturers(filter: $lecturersFilter) {
    count
    result {
      id
      account {
        id
        firstName
        lastName
        middleName
        pictureId
      }
      products(filter: $lectureProductsFilter) {
        id
        name
      }
    }
  }
}
    ${SearchResultProductFormFragmentFragmentDoc}
${SearchResultArticleFragmentFragmentDoc}`;

/**
 * __useSearchingQuery__
 *
 * To run a query within a React component, call `useSearchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchingQuery({
 *   variables: {
 *      coursesFilter: // value for 'coursesFilter'
 *      videoCoursesFilter: // value for 'videoCoursesFilter'
 *      materialsFilter: // value for 'materialsFilter'
 *      articlesFilter: // value for 'articlesFilter'
 *      lecturersFilter: // value for 'lecturersFilter'
 *      lectureProductsFilter: // value for 'lectureProductsFilter'
 *   },
 * });
 */
export function useSearchingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchingQuery, SearchingQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchingQuery, SearchingQueryVariables>(SearchingDocument, baseOptions);
      }
export function useSearchingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchingQuery, SearchingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchingQuery, SearchingQueryVariables>(SearchingDocument, baseOptions);
        }
export type SearchingQueryHookResult = ReturnType<typeof useSearchingQuery>;
export type SearchingLazyQueryHookResult = ReturnType<typeof useSearchingLazyQuery>;
export type SearchingQueryResult = ApolloReactCommon.QueryResult<SearchingQuery, SearchingQueryVariables>;
export const GetSeoDocument = gql`
    query GetSeo($pathname: String!) {
  seoPageInfoByPathname(pathname: $pathname) {
    id
    pathname
    title
    keywords
    description
    pictureId
  }
}
    `;

/**
 * __useGetSeoQuery__
 *
 * To run a query within a React component, call `useGetSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeoQuery({
 *   variables: {
 *      pathname: // value for 'pathname'
 *   },
 * });
 */
export function useGetSeoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeoQuery, GetSeoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeoQuery, GetSeoQueryVariables>(GetSeoDocument, baseOptions);
      }
export function useGetSeoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeoQuery, GetSeoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeoQuery, GetSeoQueryVariables>(GetSeoDocument, baseOptions);
        }
export type GetSeoQueryHookResult = ReturnType<typeof useGetSeoQuery>;
export type GetSeoLazyQueryHookResult = ReturnType<typeof useGetSeoLazyQuery>;
export type GetSeoQueryResult = ApolloReactCommon.QueryResult<GetSeoQuery, GetSeoQueryVariables>;
export const Start52weeksDocument = gql`
    mutation Start52weeks($date: Date!, $sum: Decimal!, $target: String, $currency: CurrencyEnum!) {
  start52weeks(date: $date, sum: $sum, target: $target, currency: $currency) {
    id
    startDate
    startSum
    target
    currency
    investments {
      id
      sum
      weekNumber
    }
  }
}
    `;

/**
 * __useStart52weeksMutation__
 *
 * To run a mutation, you first call `useStart52weeksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStart52weeksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [start52weeksMutation, { data, loading, error }] = useStart52weeksMutation({
 *   variables: {
 *      date: // value for 'date'
 *      sum: // value for 'sum'
 *      target: // value for 'target'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useStart52weeksMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Start52weeksMutation, Start52weeksMutationVariables>) {
        return ApolloReactHooks.useMutation<Start52weeksMutation, Start52weeksMutationVariables>(Start52weeksDocument, baseOptions);
      }
export type Start52weeksMutationHookResult = ReturnType<typeof useStart52weeksMutation>;
export type Start52weeksMutationResult = ApolloReactCommon.MutationResult<Start52weeksMutation>;
export const Reset52weeksDocument = gql`
    mutation Reset52weeks {
  reset52weeks
}
    `;

/**
 * __useReset52weeksMutation__
 *
 * To run a mutation, you first call `useReset52weeksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReset52weeksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reset52weeksMutation, { data, loading, error }] = useReset52weeksMutation({
 *   variables: {
 *   },
 * });
 */
export function useReset52weeksMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Reset52weeksMutation, Reset52weeksMutationVariables>) {
        return ApolloReactHooks.useMutation<Reset52weeksMutation, Reset52weeksMutationVariables>(Reset52weeksDocument, baseOptions);
      }
export type Reset52weeksMutationHookResult = ReturnType<typeof useReset52weeksMutation>;
export type Reset52weeksMutationResult = ApolloReactCommon.MutationResult<Reset52weeksMutation>;
export const Get52WeeksSuggestedProductsDocument = gql`
    query Get52WeeksSuggestedProducts($id1: Int!, $id2: Int!) {
  suggestedProduct1: product(id: $id1) {
    id
    pictureId
    name
  }
  suggestedProduct2: product(id: $id2) {
    id
    pictureId
    name
  }
}
    `;

/**
 * __useGet52WeeksSuggestedProductsQuery__
 *
 * To run a query within a React component, call `useGet52WeeksSuggestedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet52WeeksSuggestedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet52WeeksSuggestedProductsQuery({
 *   variables: {
 *      id1: // value for 'id1'
 *      id2: // value for 'id2'
 *   },
 * });
 */
export function useGet52WeeksSuggestedProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get52WeeksSuggestedProductsQuery, Get52WeeksSuggestedProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<Get52WeeksSuggestedProductsQuery, Get52WeeksSuggestedProductsQueryVariables>(Get52WeeksSuggestedProductsDocument, baseOptions);
      }
export function useGet52WeeksSuggestedProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get52WeeksSuggestedProductsQuery, Get52WeeksSuggestedProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get52WeeksSuggestedProductsQuery, Get52WeeksSuggestedProductsQueryVariables>(Get52WeeksSuggestedProductsDocument, baseOptions);
        }
export type Get52WeeksSuggestedProductsQueryHookResult = ReturnType<typeof useGet52WeeksSuggestedProductsQuery>;
export type Get52WeeksSuggestedProductsLazyQueryHookResult = ReturnType<typeof useGet52WeeksSuggestedProductsLazyQuery>;
export type Get52WeeksSuggestedProductsQueryResult = ApolloReactCommon.QueryResult<Get52WeeksSuggestedProductsQuery, Get52WeeksSuggestedProductsQueryVariables>;
export const Get52weeksDocument = gql`
    query Get52weeks {
  get52weeks {
    id
    startDate
    startSum
    target
    currency
    investments {
      id
      sum
      weekNumber
    }
  }
}
    `;

/**
 * __useGet52weeksQuery__
 *
 * To run a query within a React component, call `useGet52weeksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet52weeksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet52weeksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet52weeksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get52weeksQuery, Get52weeksQueryVariables>) {
        return ApolloReactHooks.useQuery<Get52weeksQuery, Get52weeksQueryVariables>(Get52weeksDocument, baseOptions);
      }
export function useGet52weeksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get52weeksQuery, Get52weeksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get52weeksQuery, Get52weeksQueryVariables>(Get52weeksDocument, baseOptions);
        }
export type Get52weeksQueryHookResult = ReturnType<typeof useGet52weeksQuery>;
export type Get52weeksLazyQueryHookResult = ReturnType<typeof useGet52weeksLazyQuery>;
export type Get52weeksQueryResult = ApolloReactCommon.QueryResult<Get52weeksQuery, Get52weeksQueryVariables>;
export const Update52weeksDocument = gql`
    mutation Update52weeks($weekNumber: Int!, $sum: Decimal!) {
  update52weeks(weekNumber: $weekNumber, sum: $sum) {
    id
    startDate
    startSum
    investments {
      id
      sum
      weekNumber
    }
  }
}
    `;

/**
 * __useUpdate52weeksMutation__
 *
 * To run a mutation, you first call `useUpdate52weeksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate52weeksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [update52weeksMutation, { data, loading, error }] = useUpdate52weeksMutation({
 *   variables: {
 *      weekNumber: // value for 'weekNumber'
 *      sum: // value for 'sum'
 *   },
 * });
 */
export function useUpdate52weeksMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update52weeksMutation, Update52weeksMutationVariables>) {
        return ApolloReactHooks.useMutation<Update52weeksMutation, Update52weeksMutationVariables>(Update52weeksDocument, baseOptions);
      }
export type Update52weeksMutationHookResult = ReturnType<typeof useUpdate52weeksMutation>;
export type Update52weeksMutationResult = ApolloReactCommon.MutationResult<Update52weeksMutation>;
export const GetServiceMarketMapDataDocument = gql`
    query GetServiceMarketMapData {
  highLowData {
    issueCapitalization
    secId
    name
    moscowExchangesIndex
    moscowExchangeBlueChipIndex
    index
    eTF
    d1
    w1
    m1
    y1
    yTD
    currentDate
  }
}
    `;

/**
 * __useGetServiceMarketMapDataQuery__
 *
 * To run a query within a React component, call `useGetServiceMarketMapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceMarketMapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceMarketMapDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceMarketMapDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServiceMarketMapDataQuery, GetServiceMarketMapDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetServiceMarketMapDataQuery, GetServiceMarketMapDataQueryVariables>(GetServiceMarketMapDataDocument, baseOptions);
      }
export function useGetServiceMarketMapDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServiceMarketMapDataQuery, GetServiceMarketMapDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetServiceMarketMapDataQuery, GetServiceMarketMapDataQueryVariables>(GetServiceMarketMapDataDocument, baseOptions);
        }
export type GetServiceMarketMapDataQueryHookResult = ReturnType<typeof useGetServiceMarketMapDataQuery>;
export type GetServiceMarketMapDataLazyQueryHookResult = ReturnType<typeof useGetServiceMarketMapDataLazyQuery>;
export type GetServiceMarketMapDataQueryResult = ApolloReactCommon.QueryResult<GetServiceMarketMapDataQuery, GetServiceMarketMapDataQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($returnUrl: String!, $type: SubscriptionPublicTypeEnum!) {
  createSubscription(returnUrl: $returnUrl, type: $type) {
    id
    confirmationUrl
  }
}
    `;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, baseOptions);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSubscriptionMutation>;