import { IThemeOptions, ITheme, IThemeColors } from 'core/style/interfaces';
import createBreakpoints, { createRuntimeBreakpoints } from './createBreakpoints';

const createThemeColors = (colors: Partial<IThemeColors>): IThemeColors => ({
    black: '#333',
    primary: '#3F4647',
    secondary: '#FF3333',
    accent: '#CC0000',
    accentLight: '#FF6666',
    accentDark: '#990000',
    accentMenu: '#FF5252',
    lightBg: '#FFF',
    contrastBg: '#1E252F',
    contrastBgText: '#FFF',
    contrastBgMedium: '#CCC',
    footerBg: '#3f4647',
    footerText: 'rgba(254, 254, 254, 0.60)',
    footerTextHover: 'rgba(254, 254, 254, 1)',
    link: '#597DA3',
    linkAlt: '#CC3333',
    linkOld: '#597DA3',
    linkOldHover: '#476482',
    textLight: '#999',
    textBase: '#333',
    textMedium: '#666',
    textDarkBlue: '#191D1D',
    success: '#4CAF50',
    error: '#e53935',
    lightGray: '#EEE',
    turquoise: '#00BCD4',
    divider: '#dfdfdf',
    green: '#05CC01',
    ...colors,
});

const createTheme = ({ breakpoints = {}, componentStyles = {}, colors }: IThemeOptions = {}): ITheme => {
    return {
        colors: createThemeColors(colors),
        breakpoints: createBreakpoints(breakpoints),
        runtimeBreakpoints: createRuntimeBreakpoints(breakpoints.values),
        componentStyles,
    };
};

export default createTheme;
