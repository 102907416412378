import { useLinks } from 'core/components/linksContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '../../../core/svg/arrow_left.svg';
import { loginButtonStyles, signupButtonStyles } from './const';
import useHeaderAuth from './hooks/useHeaderAuth';

type IAuthMenuMobileMainStateProps = {
    closeDetails(): void;
    goToSecondaryState(): void;
} & WithStyles<typeof styles>;

const AuthMenuMobileMainState: React.FC<IAuthMenuMobileMainStateProps> = ({
    classes,
    closeDetails,
    goToSecondaryState,
}) => {
    const [isLoggedIn, login, , signup] = useHeaderAuth();
    const links = useLinks();

    const MenuLink = ({ title, url }) => (
        <div className={classes.link}>
            <Link to={url} title={title} onClick={closeDetails}>
                {title}
            </Link>
        </div>
    );

    return (
        <div>
            <MenuLink url={links.courseList.url()} title="Курсы" />
            <MenuLink url={links.articles.url()} title="Статьи" />
            <MenuLink url={links.servicePage.url()} title="Сервисы инвестора" />
            <MenuLink url={links.contacts.url()} title="Контакты" />

            {isLoggedIn ? (
                <button className={classes.accountButton} onClick={goToSecondaryState}>
                    Личный кабинет
                    <ArrowLeftIcon className={classes.accountButtonIcon} />
                </button>
            ) : (
                <>
                    <button className={classes.signupButton} onClick={signup}>
                        Зарегистрироваться
                    </button>
                    <button className={classes.loginButton} onClick={login}>
                        Войти
                    </button>
                </>
            )}
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        link: {
            marginTop: 24,

            '&:first-child': {
                marginTop: 0,
            },

            '& a': {
                textDecoration: 'none',
                color: theme.colors.textBase,
            },
        },
        loginButton: {
            ...loginButtonStyles(theme),
            marginRight: 0,
            marginLeft: 0,
            marginTop: 24,
            width: 100 + '%',
        },
        signupButton: {
            ...signupButtonStyles(theme),
            marginTop: 24,
            width: 100 + '%',
        },
        accountButton: {
            ...signupButtonStyles(theme),
            marginTop: 24,
            width: 100 + '%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        accountButtonIcon: {
            width: 20,
            height: 20,
            marginLeft: 10,
            fill: '#fff',
            transform: 'rotate(180deg)',
        },
    });

export default injectSheet(styles)(AuthMenuMobileMainState);
