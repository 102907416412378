export * from './date';
export * from './fp';
export * from './html';
export * from './query';
export * from './string';

interface IPrevent {
    preventDefault: () => void;
}

export function preventDefault<TEvent extends IPrevent>(fn: (e: TEvent, ...params) => any) {
    return (e: TEvent, ...params) => {
        e.preventDefault();
        return fn(e, ...params);
    };
}

/**
 * Переменная состояния SSR
 * https://ru.reactjs.org/docs/reactdom.html#hydrate
 *
 * Почему не использовать `ifBrowser()`?
 * Пример, когда `ifBrowser` не работает:
 *
 * `return ifBrowser() ? <div className={classes.red}>123</div> : <div className={classes.green}>456</div>;`
 *
 * На клиенте останется класс green, т.к. при гидратации react не сверяет аттрибуты.
 *
 * Что использовать вместо?
 * `const { isSSR } = useDeviceInfo();`
 *
 * Так же работает для порталов, которые не поддерживаются при SSR:
 * https://github.com/facebook/react/issues/13097#issuecomment405658104
 */
export const ifBrowser = (func: (window) => any = () => true) => typeof window !== 'undefined' && func(window);

// https://goshakkk.name/different-mobile-desktop-tablet-layouts-react/
export const isMobile = (mobileWidth = 500) => ifBrowser() && window.innerWidth <= mobileWidth;

// [{ id: 1, name: ... }, { id: 2, name: ... }] -> { 1: { name: ... }, 2: { name: ... } }
export const arrayToObject = (array: any[]): object => {
    return array.reduce((result, obj) => {
        if (!obj.id) return result;

        result[obj.id] = obj;
        return result;
    }, {});
};

export const deprecate = (fn, message: string) => (...params) => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(message);
    }
    return fn(...params);
};

export const detectIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    return false;
};

// https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
export const arrayOnlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

/**
 * Приводит число к денежному формату. Разделяет тысячи пробелом и обрезает дробную часть до 2 символов.
 * Пример: 1200453,4544 -> 1 200 453,45
 */
export const formatNumberToDecimal = (value: number) => numberSeparateThousands(value.toFixed(2));

/**
 * Разделяем тысячи пробелом, например 1200453 -> 1 200 453
 * https://answers.acrobatusers.com/How-to-separate-thousands-with-space-and-adding-2-decimal-places-q282162.aspx
 */
export const numberSeparateThousands = (value: number | string) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const numberConnectThousands = value => {
    const arrDef = value.split(' ');
    let arrRes = '';
    arrDef.forEach(item => {
        arrRes += item;
    });
    return arrRes;
};

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const omit = <T, K extends keyof T>(obj: T, key: K) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { [key]: omitField, ...rest } = obj;
    return rest;
};

export const findKey = (obj: object, predicate: (value: any) => boolean) =>
    isObject(obj) ? Object.keys(obj).find(key => predicate(obj[key])) : undefined;

export function debounce(f: Function, ms: number) {
    let timer = null;

    return function(...args) {
        const onComplete = () => {
            f.apply(this, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };
}
