export const isFilledArray = (arr: any[]) => Array.isArray(arr) && arr.length > 0;

type IMap = <T, R>(mapper: (obj: T, index?: number) => R) => (arr: T[]) => R[];
export const map: IMap = mapper => arr => arr.map(mapper);

type IFilter = <T>(predicate: (obj: T) => boolean) => (arr: T[]) => T[];
export const filter: IFilter = predicate => arr => arr.filter(predicate);

// prettier-ignore
interface IFlow {
    <A1, R1, R2>(f1: (a1: A1) => R1, f2: (a: R1) => R2): (a1: A1) => R2;
    <A1, R1, R2, R3>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3): (a1: A1) => R3;
    <A1, R1, R2, R3, R4>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3, f4: (a: R3) => R4): (a1: A1) => R4;
    <A1, R1, R2, R3, R4, R5>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3, f4: (a: R3) => R4, f5: (a: R4) => R5): (a1: A1) => R5;
    <A1, R1, R2, R3, R4, R5, R6>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3, f4: (a: R3) => R4, f5: (a: R4) => R5, f6: (a: R5) => R6): (a1: A1) => R6;
    <A1, R1, R2, R3, R4, R5, R6, R7>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3, f4: (a: R3) => R4, f5: (a: R4) => R5, f6: (a: R5) => R6, f7: (a: R6) => R7): (a1: A1) => R7;
}
export const flow: IFlow = (...fns) =>
    fns.reduce(
        (prevFn, nextFn) => value => nextFn(prevFn(value)),
        value => value,
    );

type IOrderBy = <T>(
    by: (obj: T) => string | number | Date | undefined | boolean,
    direction: 'asc' | 'desc',
) => (arr: T[]) => T[];
export const orderBy: IOrderBy = (by, direction) => arr =>
    arr.slice().sort((a, b) => {
        const byA = by(a);
        const byB = by(b);

        // equal items sort equally
        if (byA === byB) {
            return 0;
        }
        // nulls sort after anything else
        else if (byA === null) {
            return 1;
        } else if (byB === null) {
            return -1;
        }

        if (typeof byA === 'string') {
            let res;

            if (direction === 'asc') {
                if (byA > byB) {
                    res = 1;
                }
                if (byA < byB) {
                    res = -1;
                }
                if (byA === byB) {
                    res = 0;
                }
                return res;
            }

            if (byA < byB) {
                res = 1;
            }
            if (byA > byB) {
                res = -1;
            }
            if (byA === byB) {
                res = 0;
            }
            return res;
        }

        if (typeof byA === 'number' || typeof byA === 'boolean') {
            if (direction === 'asc') {
                return +byA - +byB;
            }
            return +byB - +byA;
        }

        if (byA instanceof Date && byB instanceof Date) {
            return direction === 'asc' ? byA.getTime() - byB.getTime() : byB.getTime() - byA.getTime();
        }

        return 0;
    });

type IHead = <T>(arr: T[]) => T;
export const head: IHead = arr => arr[0];

type ILast = <T>(ar: T[]) => T;
export const last: ILast = arr => arr[arr.length - 1];

const realmemoize = require('fast-memoize');
type IMemoize = <T>(fn: T) => T;
export const memoize: IMemoize = realmemoize;

type IFlatten = <T>(arr: T[][]) => T[];
export const flatten: IFlatten = arr => arr.reduce((acc, val) => acc.concat(val), []);

export const take = (value: number) => <T>(array: T[]) => array.slice(0, value);
export const skip = (value: number) => <T>(array: T[]) => array.slice(value);
