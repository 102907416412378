import React from 'react';
import AuthForm from 'site/components/auth/auth-form/AuthForm';
import { useAuth } from 'site/components/auth/AuthProvider';
import PaymentFormContainer from 'site/components/payments/PaymentFormContainer';
import { gql } from 'apollo-boost';
import { useGetPayProductFormQuery, ProductFormPaymentStatusEnum } from 'site/graphql';
import CenterLoader from 'core/components/common/CenterLoader';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import checkAccountRequiredFields from '../profile-fields/checkAccountRequiredFields';
import ProfileFieldsForm from '../profile-fields/ProfileFieldsForm';

gql`
    query GetPayProductForm($id: Int!) {
        productForm(id: $id) {
            id
            type
            title
            price
            paymentStatus
            requiredFieldsNumber
            hasProductAgreement
            product {
                id
                offer
                name
                tagsString
                contentProvider {
                    id
                    offer
                    name
                }
            }
            order {
                id
            }
            records {
                id
                date
            }
            webinars {
                id
                date
            }
            seminars {
                id
                date
            }
        }
    }
`;

/**
 * Задача компонента - отловить отсутствие авторизации и
 * заполненность всех полей, необходимых для регистрации
 */
const RegPayForm: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const { isLoggedIn, payProductFormId, myAccount } = useAuth();

    // Запрос данных о форме, необходимых для оплаты
    const { data, loading, error } = useGetPayProductFormQuery({
        fetchPolicy: 'network-only',
        skip: payProductFormId === null,
        variables: { id: payProductFormId },
    });
    const productForm = data && data.productForm;

    if (loading) {
        return (
            <div className={classes.root}>
                <CenterLoader />
            </div>
        );
    }

    if (error || !productForm) {
        return (
            <div className={classes.root}>
                Ошибка! Не удалось получить данные о курсе. Обновите страницу и попробуйте снова.
            </div>
        );
    }

    if (!isLoggedIn || productForm.paymentStatus === ProductFormPaymentStatusEnum.NotAuthorized) {
        return <AuthForm showRememberMe showSocialLogin />;
    }

    const { requiredFieldsNumber, hasProductAgreement, product } = productForm;

    // Не все поля, необходимые для регистрации, заполнены
    if (!checkAccountRequiredFields(myAccount, requiredFieldsNumber, hasProductAgreement, product.offer)) {
        return <ProfileFieldsForm productForm={productForm} />;
    }

    return <PaymentFormContainer productForm={productForm} />;
};

const styles = (theme: ITheme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            padding: 1 + 'rem',
            width: 450,
            minHeight: 'min-content',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)', // весь экран - 2*padding
                height: 'calc(100vh - 2rem)', // весь экран - 2*padding
                textAlign: 'center',
            },
        },
    });

export default injectSheet(styles)(RegPayForm);
