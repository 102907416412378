import { useAuth } from 'site/components/auth/AuthProvider';

const useHeaderAuth = () => {
    const { isLoggedIn, setState, openAuthModal, logout } = useAuth();

    const login = () => openAuthModal('login');
    const signup = () => openAuthModal('signup');

    const onLogout = () => {
        setState('login');
        logout();
    };

    return [isLoggedIn, login, onLogout, signup] as const;
};

export default useHeaderAuth;
