/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import md5 from 'md5';
import React, { useCallback, useRef, useState } from 'react';
import { GetTextBlockQuery, useGetTextBlockQuery } from 'site/graphql';

const TopBar: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const contentHash = useRef<string>(null);
    const [showBar, setShowBar] = useState(false);
    const [topBarPage, setTopBarPage] = useState<GetTextBlockQuery['pageBySlug']>(null);

    useGetTextBlockQuery({
        variables: { slug: 'top-bar', withTextBlocks: true },
        errorPolicy: 'ignore',
        onCompleted: data => {
            if (!data || !data.pageBySlug) {
                return;
            }

            contentHash.current = md5(data.pageBySlug.content);
            const storedContentHash = window.localStorage.getItem('topBarClosedContentHash');

            if (contentHash.current !== storedContentHash) {
                setShowBar(true);
                setTopBarPage(data.pageBySlug);
            }
        },
    });

    const handleClose = useCallback(() => {
        window.localStorage.setItem('topBarClosedContentHash', contentHash.current);
        setShowBar(false);
    }, [setShowBar]);

    return (
        <div>
            {showBar && topBarPage && topBarPage.content && (
                <div className={classes.topBarContainer}>
                    <div className={classes.topBarContent} dangerouslySetInnerHTML={{ __html: topBarPage.content }} />
                    <div className={classes.topBarCloser} role="button" onClick={handleClose}>
                        <span>&#x2715;</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = createStyles({
    topBarContainer: {
        background: '#F4C150',
        color: '#29303B',
        padding: '10px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
    },
    topBarContent: {
        textAlign: 'center',
        flexBasis: '100%',
    },
    topBarCloser: {
        alignSelf: 'baseline',
        marginLeft: 6 + 'px',
        cursor: 'pointer',
    },
});

export default injectSheet(styles)(TopBar);
